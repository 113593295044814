import React from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import {
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Stack,
  Card,
  Typography,
  FormHelperText,
} from "@mui/material"
import { Controller, useForm, useWatch } from "react-hook-form"
import * as Yup from "yup"

import { PutNoticeRequest, PutNoticeRequestStatusEnum } from "src/api/models"
import { putNotice, uploadNoticeFile } from "src/api/notices"
import { DatePicker } from "src/components/organisms/DatePicker"
import { FileDropzone } from "src/components/organisms/FileDropzone"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useSubmitting } from "src/hooks/useSubmitting"
import { getDaysAgo, getToday } from "src/utils"

type FormData = Omit<PutNoticeRequest, "id" | "uid"> & {
  file: File
}

export const NoticeNew: React.FC = () => {
  return (
    <MainContentLayout
      title="お知らせの追加"
      renderContent={() => <NoticeNewInner />}
    />
  )
}

const NoticeNewInner: React.FC = () => {
  const validationSchema = Yup.object({
    title: Yup.string().required("必須です"),
    status: Yup.string<PutNoticeRequestStatusEnum>().required("必須です"),
    openAt: Yup.string().when("status", {
      is: PutNoticeRequestStatusEnum.Public,
      then: (schema) => schema.required("必須です"),
      otherwise: (schema) => schema.notRequired(),
    }),
    file: Yup.mixed<File>().required("必須です"),
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      title: "",
      status: PutNoticeRequestStatusEnum.Private,
      openAt: getDaysAgo(-1, getToday()),
    },
    resolver: yupResolver<FormData>(validationSchema),
  })

  const { submitPromises } = useSubmitting()
  const onSubmit = async (data: FormData) => {
    let uid = ""
    const result = await submitPromises([
      {
        subject: "メーターリード結果の更新",
        showSuccessMessage: true,
        promise: async () => {
          const res = await uploadNoticeFile({
            file: data.file,
            filename: data.file.name,
          })
          uid = res.data.uid
        },
      },
    ])

    if (!result.success) throw result.error

    await submitPromises([
      {
        subject: "メーターリード結果の更新",
        showSuccessMessage: true,
        promise: async () => {
          await putNotice({
            title: data.title,
            status: data.status,
            openAt:
              data.status === PutNoticeRequestStatusEnum.Public
                ? data.openAt
                : undefined,
            uid: uid,
          })
        },
      },
    ])
  }

  const file = useWatch({ control, name: "file" })
  const status = useWatch({ control, name: "status" })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack sx={{ gap: 2 }}>
        <Card sx={{ display: "flex", flexDirection: "column", gap: 3, p: 2 }}>
          <TextField
            label="タイトル"
            fullWidth
            {...register("title")}
            error={!!errors.title}
            helperText={errors.title ? errors.title.message : ""}
          />

          <Stack>
            <Controller
              render={({ field }) => (
                <FileDropzone
                  onFiles={(files) => field.onChange(files[0])}
                  fileTypes={["pdf"]}
                />
              )}
              name="file"
              control={control}
            />
            {file && (
              <Typography>アップロードされたファイル: {file.name}</Typography>
            )}
            {errors.file?.message && (
              <FormHelperText error>{errors.file.message}</FormHelperText>
            )}
          </Stack>

          <Controller
            render={({ field }) => (
              <FormControl component="fieldset">
                <FormLabel component="legend">公開設定</FormLabel>
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value={PutNoticeRequestStatusEnum.Public}
                    control={
                      <Radio
                        checked={
                          field.value === PutNoticeRequestStatusEnum.Public
                        }
                      />
                    }
                    label="公開"
                  />
                  <FormControlLabel
                    value={PutNoticeRequestStatusEnum.Private}
                    control={
                      <Radio
                        checked={
                          field.value === PutNoticeRequestStatusEnum.Private
                        }
                      />
                    }
                    label="非公開"
                  />
                </RadioGroup>
              </FormControl>
            )}
            name="status"
            control={control}
          />
          {status === PutNoticeRequestStatusEnum.Public && (
            <Stack>
              <Controller
                render={({ field }) => (
                  <DatePicker label="公開日" hideTodayButton {...field} />
                )}
                name="openAt"
                control={control}
              />
              {errors.openAt?.message && (
                <FormHelperText error>{errors.openAt.message}</FormHelperText>
              )}
            </Stack>
          )}
        </Card>
        <Button variant="contained" color="primary" type="submit">
          保存する
        </Button>
      </Stack>
    </form>
  )
}
