import { useState } from "react"

import { Box, Button, Typography } from "@mui/material"
import { useDropzone } from "react-dropzone"

import FileUploadIcon from "src/assets/file_upload.svg"
import { ErrorDialog } from "src/components/organisms/ErrorDialog"

export type FileDropzoneProps = {
  onFiles: (files: File[]) => void
  multiple?: boolean
  fileTypes?: string[]
}

export const FileDropzone: React.FC<FileDropzoneProps> = ({
  onFiles,
  multiple,
  fileTypes = ["csv"],
}) => {
  // ダイアログを閉じる瞬間にメッセージが空で表示されるのを防ぐために open と message を分けて管理
  const [errorMessage, setErrorMessage] = useState("")
  const [errorDialogOpen, setErrorDialogOpen] = useState(false)

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple,
    accept: fileTypes.reduce(
      (acc, type) => {
        acc[`text/${type}`] = [`.${type}`]
        return acc
      },
      {} as Record<string, string[]>,
    ),
    onDropAccepted: onFiles,
    onDropRejected(fileRejections) {
      const error = fileRejections[0]?.errors[0]
      if (error) {
        if (error.code === "file-invalid-type") {
          setErrorMessage(
            `${fileTypes.join(", ").toUpperCase()}ファイルのみアップロード可能です。ファイル形式を確認して再度ファイルを選択してください。`,
          )
        } else if (error.code === "too-many-files") {
          setErrorMessage(
            "1ファイルのみアップロード可能です。再度ファイルを選択してください。",
          )
        } else {
          setErrorMessage(
            "ファイルのアップロードに失敗しました。ファイル形式を確認して再度ファイルを選択してください。",
          )
        }
        setErrorDialogOpen(true)
      }
    },
  })

  return (
    <>
      <Box
        {...getRootProps()}
        sx={[
          {
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 1,
            borderColor: "primary.main",
            borderRadius: 1,
            borderWidth: 1,
            borderStyle: "dashed",
            backgroundColor: "primary.light",
            cursor: "pointer",
            // ドロップ領域にホバーしたときにボタンをホバー扱いにする
            "&:hover .MuiButton-root": {
              backgroundColor: "rgba(28, 97, 172, 0.04)",
              border: "1px solid #1C61AC",
            },
          },
          isDragActive && {
            borderWidth: 2,
          },
        ]}
      >
        <input {...getInputProps()} />
        <img
          style={{ width: 48, aspectRatio: 1 }}
          src={FileUploadIcon}
          alt="ファイルアップロード"
        />
        <Typography variant="body2" color="textSecondary">
          ここにファイルをドロップ、または
        </Typography>
        <Button variant="outlined" fullWidth>
          ファイルを選択
        </Button>
      </Box>
      <ErrorDialog
        open={errorDialogOpen}
        message={errorMessage}
        onClose={() => setErrorDialogOpen(false)}
        onExited={() => setErrorMessage("")}
      />
    </>
  )
}
