import { useMemo } from "react"

import { getPrizeDeliveries } from "src/api/prize-deliveries"
import { getPrizeFloorMap } from "src/api/prize-floor-map"
import { getPrizeDailyPlans } from "src/api/prize-plans"
import { getPrizeBoothSales } from "src/api/prize-sales"
import {
  convertPrizePlanFloorMapPoint,
  PrizeDailyPlanFloorMapType,
} from "src/domains/prizes/floorMapRepository"
import {
  calcPrizeDailySales,
  PrizeDailySale,
} from "src/domains/prizes/prizeSalesRepository"
import { useResource } from "src/hooks/useResource"

export function usePrizeDailyPlanFloorMapPoints({
  arcadeCd,
  type,
  date,
}: {
  arcadeCd: string | undefined
  type: PrizeDailyPlanFloorMapType
  date: string
}) {
  const floorMapPoints = useResource({
    subject: "フロアマップの取得",
    fetch: arcadeCd ? () => getPrizeFloorMap(arcadeCd) : undefined,
    recoilKey: `getPrizeFloorMap:${arcadeCd}`,
  }).resource?.data.floorMapPoints

  const prizeDailyPlans = useResource({
    subject: "デイリー入替計画の取得",
    fetch: arcadeCd
      ? () => getPrizeDailyPlans(arcadeCd, { from: date, to: date })
      : undefined,
    recoilKey: `getPrizeDailyPlans:${arcadeCd}:${date}:${date}`,
  }).resource?.data.plans

  const prizeDeliveries = useResource({
    subject: "着荷予定景品一覧の取得",
    fetch: arcadeCd
      ? () => getPrizeDeliveries(arcadeCd, { from: date, to: date })
      : undefined,
    recoilKey: `getPrizeDeliveries:${arcadeCd}:${date}:${date}`,
  }).resource?.data.deliveries

  const prizeBoothSales = useResource({
    subject: "ブース別売上結果の取得",
    fetch: arcadeCd
      ? () => getPrizeBoothSales(arcadeCd, { from: date, to: date })
      : undefined,
    skip: type !== "sales",
    recoilKey: `getPrizeBoothSales:${arcadeCd}:${date}:${date}`,
  }).resource?.data.sales

  const prizeDailySales = useMemo(() => {
    if (type !== "sales") return null

    const sortFn = (a: PrizeDailySale, b: PrizeDailySale) => {
      return (a.sales || 0) - (b.sales || 0)
    }

    return calcPrizeDailySales(
      prizeBoothSales || [],
      prizeDailyPlans || [],
      prizeDeliveries || [],
      () => true,
      sortFn,
    ).items
  }, [type, prizeBoothSales, prizeDailyPlans, prizeDeliveries])

  const prizePlanFloorMapPoints = useMemo(() => {
    if (
      !floorMapPoints ||
      prizeDailyPlans === undefined ||
      prizeDeliveries === undefined
    ) {
      return null
    }

    return floorMapPoints.map((point) =>
      convertPrizePlanFloorMapPoint({
        point,
        prizeDailyPlans,
        prizeDeliveries,
        ...(prizeDailySales && { prizeDailySales }),
      }),
    )
  }, [floorMapPoints, prizeDailyPlans, prizeDeliveries, prizeDailySales])

  return { prizePlanFloorMapPoints }
}
