import { useCallback } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { LoadingButton } from "@mui/lab"
import { DialogContent, Stack } from "@mui/material"
import { SubmitHandler, useForm } from "react-hook-form"
import * as Yup from "yup"

import { BackButton } from "src/components/atoms/BackButton"
import { TextFieldWithCount } from "src/components/atoms/TextFieldWithCount"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { CustomDialogTitle } from "src/components/molecules/CustomDialogTitle"

const MAX_NAME_LENGTH = 20

const validationSchema = Yup.object({
  name: Yup.string()
    .required("必須です")
    .max(MAX_NAME_LENGTH, "${max}文字以内で入力してください")
    .test("unique", "既に存在している名称です", (value, context) => {
      const printSettingNames = context.options.context?.printSettingNames
      return !(
        Array.isArray(printSettingNames) && printSettingNames.includes(value)
      )
    }),
})

const resolver = yupResolver(validationSchema)

type FormValues = Yup.InferType<typeof validationSchema>

export type OnSubmitFloorMapAddPrintSetting = SubmitHandler<FormValues>

type Props = {
  open: boolean
  printSettingNames?: string[]
  onClose?: () => void
  onSubmit?: OnSubmitFloorMapAddPrintSetting
}

export const FloorMapAddPrintSettingModal: React.FC<Props> = ({
  open,
  printSettingNames: printSettingNames,
  onClose = () => {
    /* noop */
  },
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm({
    resolver,
    defaultValues: { name: "" },
    context: { printSettingNames },
  })

  const _onSubmit: OnSubmitFloorMapAddPrintSetting = useCallback(
    async (data) => {
      await onSubmit?.(data)
      onClose?.()
    },
    [onSubmit, onClose],
  )

  return (
    <CustomDialog
      PaperProps={{ component: "form" }}
      open={open}
      onSubmit={handleSubmit(_onSubmit)}
      onClose={onClose}
    >
      <CustomDialogTitle onClose={onClose}>印刷範囲の保存</CustomDialogTitle>
      <DialogContent>
        <Stack py={1}>
          <TextFieldWithCount
            {...register("name")}
            fullWidth
            autoFocus
            autoComplete="off"
            placeholder="保存する印刷範囲の名称を入力"
            maxCount={MAX_NAME_LENGTH}
            error={errors.name != null}
            helperText={errors.name?.message}
          />
        </Stack>
      </DialogContent>
      <CustomDialogActions>
        <BackButton onClick={onClose}>保存せずに戻る</BackButton>
        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          loading={isSubmitting}
          disabled={!isDirty || errors.name != null}
        >
          保存する
        </LoadingButton>
      </CustomDialogActions>
    </CustomDialog>
  )
}
