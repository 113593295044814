import { useEffect, useMemo, useState } from "react"

import {
  Button,
  TableHead,
  TableRow,
  Checkbox,
  FormControlLabel,
  Stack,
  Card,
} from "@mui/material"
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"

import { PrizeArrivalElement } from "src/api/models"
import { getPrizeArrivals } from "src/api/prize-arrivals"
import finishedIcon from "src/assets/icon_finished.png"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  CardItemNameBox,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import { DefaultDateRangePicker } from "src/components/organisms/DefaultDateRangePicker"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { InventoryPrizeAcceptModal } from "src/components/organisms/prizes/InventoryPrizeAcceptModal"
import { InventoryPrizeAcceptMultiArrivalsModal } from "src/components/organisms/prizes/InventoryPrizeAcceptMultiArrivalsModal"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { getPrizeArrivalCount } from "src/domains/prizes/arrivalRepository"
import { useResource } from "src/hooks/useResource"
import {
  defaultDateRangePickerDateLabelStateSelector,
  snackbarSuccessMessageState,
} from "src/recoil"
import { showOnlyNotAcceptedPrizesState } from "src/recoil/inventory"
import { theme } from "src/theme"
import { formatApiDate, getJpDateLabel } from "src/utils"

export const InventoryPrizeAccept = () => {
  const [showOnlyNotAcceptedPrizes, setShowOnlyNotAcceptedPrizes] =
    useRecoilState(showOnlyNotAcceptedPrizesState)

  return (
    <MainContentLayout
      title="入庫検収"
      subtitle="着荷予定リスト"
      renderFilter={() => (
        <Stack gap={2}>
          <Card sx={{ p: 3 }}>
            <DefaultDateRangePicker />
          </Card>
          <FormControlLabel
            checked={showOnlyNotAcceptedPrizes}
            onChange={() =>
              setShowOnlyNotAcceptedPrizes(!showOnlyNotAcceptedPrizes)
            }
            control={<Checkbox />}
            disableTypography
            label="未入荷景品のみを表示する"
          />
        </Stack>
      )}
      renderContent={() => <InventoryPrizeAcceptMenu />}
    />
  )
}

const InventoryPrizeAcceptMenu = () => {
  const { arcadeCd } = useParams()

  const showOnlyNotAcceptedPrizes = useRecoilValue(
    showOnlyNotAcceptedPrizesState,
  )
  const dateRangePickerDateLabel = useRecoilValue(
    defaultDateRangePickerDateLabelStateSelector,
  )
  const { resource, refetch } = useResource({
    subject: "着荷予定リストの取得",
    fetch: arcadeCd
      ? () =>
          getPrizeArrivals(arcadeCd, {
            from: dateRangePickerDateLabel.start,
            to: dateRangePickerDateLabel.end,
            accepted: showOnlyNotAcceptedPrizes ? false : undefined,
          })
      : undefined,
    recoilKey: `getArrivals:${arcadeCd}:${dateRangePickerDateLabel.start}:${dateRangePickerDateLabel.end}:${showOnlyNotAcceptedPrizes}`,
  })
  const arrivals = useMemo(() => resource?.data.arrivals || [], [resource])
  const [selectedArrivalIds, setSelectedArrivalIds] = useState<number[]>([])
  const selectedArrivals =
    arrivals.filter((a) => selectedArrivalIds.includes(a.arrival.id)) ?? []

  const [showModal, setShowModal] = useState(false)
  const setSuccessMessage = useSetRecoilState(snackbarSuccessMessageState)

  useEffect(() => {
    if (arrivals?.length === 0) {
      setSuccessMessage("当日の着荷予定が存在しませんでした")
    }
  }, [arrivals, setSuccessMessage])

  useEffect(() => {
    // NOTE: 日付変更時には選択中の着荷予定をリセット
    setSelectedArrivalIds([])
  }, [dateRangePickerDateLabel])

  return (
    <>
      <Stack gap={2}>
        {arrivals && (
          <Stack>
            <ArrivalTable
              arrivals={arrivals}
              selectedArrivals={selectedArrivals}
              setSelectedArrivalIds={setSelectedArrivalIds}
            />
          </Stack>
        )}

        <Stack>
          <Button
            variant="contained"
            fullWidth
            disabled={selectedArrivals.length === 0}
            onClick={() => setShowModal(true)}
          >
            {selectedArrivals.length > 1
              ? "選択した景品をまとめて検収する"
              : selectedArrivals.length === 1 &&
                  selectedArrivals[0]?.arrival.accepted
                ? "検収内容を変更する"
                : "選択した景品を検収する"}
          </Button>
        </Stack>

        <Stack>
          <Button
            variant="outlined"
            sx={{ background: "white" }}
            fullWidth
            component={RouterLink}
            to={`/arcades/${arcadeCd}/prizes/register/accept/search`}
          >
            検索して登録する
          </Button>
        </Stack>
      </Stack>

      {selectedArrivals.length === 1 && selectedArrivals[0] && (
        <InventoryPrizeAcceptModal
          showModal={showModal}
          arrival={selectedArrivals[0]}
          prize={selectedArrivals[0].prize}
          onClose={() => setShowModal(false)}
          onFinish={() => {
            refetch()
            setSelectedArrivalIds([])
          }}
        />
      )}

      {selectedArrivals.length > 1 && (
        <InventoryPrizeAcceptMultiArrivalsModal
          showModal={showModal}
          arrivals={selectedArrivals}
          onClose={() => setShowModal(false)}
          onFinish={() => {
            refetch()
            setSelectedArrivalIds([])
          }}
        />
      )}
    </>
  )
}

interface ArrivalTableProps {
  arrivals: PrizeArrivalElement[]
  selectedArrivals: PrizeArrivalElement[]
  setSelectedArrivalIds: React.Dispatch<React.SetStateAction<number[]>>
}

const ArrivalTable: React.FC<ArrivalTableProps> = ({
  arrivals,
  selectedArrivals,
  setSelectedArrivalIds,
}: ArrivalTableProps) => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()
  const dateRangePickerDateLabel = useRecoilValue(
    defaultDateRangePickerDateLabelStateSelector,
  )

  const columnNames = ["選択", "景品名", "着荷数", "着荷予定日", "検収済"]

  const onClickCheckbox = (e: PrizeArrivalElement) =>
    selectedArrivals.some(
      (selectedArrival) => selectedArrival.arrival.id === e.arrival.id,
    )
      ? setSelectedArrivalIds((state) => {
          const newState = [...state]
          newState.splice(
            state.findIndex((id) => id === e.arrival.id),
            1,
          )
          return newState
        })
      : setSelectedArrivalIds((state) => [...state, e.arrival.id])

  const onClickArrival = (e: PrizeArrivalElement) => {
    const arrivalDate = formatApiDate(e.arrival.willArriveAt || "")
    navigate(
      `/arcades/${arcadeCd}/prizes/register/accept/details/${arrivalDate}/${encodeURIComponent(
        e.prize.prizeCd,
      )}`,
    )
  }

  return (
    <PaginatedTable
      noMargin
      items={arrivals}
      stateKey={`arrivalTable-${dateRangePickerDateLabel}`}
      header={
        <TableHead>
          <TableRow>
            {columnNames.map((columnName) => (
              <ExtTableCell
                sx={{ px: 1, whiteSpace: "nowrap" }}
                key={columnName}
              >
                {columnName}
              </ExtTableCell>
            ))}
          </TableRow>
        </TableHead>
      }
      renderRow={(e) => {
        return (
          <TableBorderedRow
            key={e.arrival.id}
            sx={{
              td: { p: 1 },
            }}
          >
            <ExtTableCell>
              <Checkbox
                sx={{ p: 0 }}
                onClick={() => onClickCheckbox(e)}
                checked={selectedArrivals.some(
                  (selectedArrival) =>
                    selectedArrival.arrival.id === e.arrival.id,
                )}
              />
            </ExtTableCell>
            <ExtTableCell
              sx={{
                color: theme.palette.primary.main,
                "&:hover": {
                  cursor: "pointer",
                  background: theme.palette.neutral[200],
                },
              }}
              onClick={() => onClickArrival(e)}
            >
              <CardItemNameBox>{e.prize.prizeName}</CardItemNameBox>
            </ExtTableCell>
            <ExtTableCell>{getPrizeArrivalCount(e)}</ExtTableCell>
            <ExtTableCell>
              {getJpDateLabel(e.arrival.willArriveAt || "")}
            </ExtTableCell>
            <ExtTableCell>
              {e.arrival.accepted && (
                <img
                  src={finishedIcon}
                  alt="済"
                  style={{ width: 32, height: 32 }}
                />
              )}
            </ExtTableCell>
          </TableBorderedRow>
        )
      }}
    />
  )
}
