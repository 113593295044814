import { useCallback, useState } from "react"

import { getLocalStorageItem, setLocalStorageItem } from "src/storages"

const printPageSizeKey = "printPageSize"

export function usePageSize() {
  const [pageSize, setPageSize] = useState<"a4" | "a3">(() =>
    getLocalStorageItem(printPageSizeKey) === "a3" ? "a3" : "a4",
  )
  const savePageSize = useCallback(() => {
    setLocalStorageItem(printPageSizeKey, pageSize)
  }, [pageSize])

  return { pageSize, setPageSize, savePageSize }
}
