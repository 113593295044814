import { SetStateAction } from "react"

import { MenuItem, Select, TextField } from "@mui/material"
import { useRecoilState } from "recoil"

import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import {
  PrizeDailySortBy,
  prizeDailySortByLabels,
} from "src/domains/prizes/dailyRepository"
import { filterAccordionSearchState } from "src/recoil"

export type PrizeDailyFilterSearchParams = {
  boothName?: string
  boothCategory?: string
  prizeCd?: string
  prizeName?: string
  sortBy?: PrizeDailySortBy
}

export const defaultSearchParams: PrizeDailyFilterSearchParams = {}

export const PrizeDailyFilter: React.FC = () => {
  const [recoilSearchParams, setRecoilSearchParams] = useRecoilState(
    filterAccordionSearchState,
  )
  const searchParams: PrizeDailyFilterSearchParams =
    recoilSearchParams["prizeDailySearchParams"] ?? defaultSearchParams
  const setSearchParams = (
    params: SetStateAction<PrizeDailyFilterSearchParams>,
  ) =>
    setRecoilSearchParams((prev) => ({
      ...prev,
      prizeDailySearchParams: params,
    }))

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      formInputs={[
        {
          name: "boothName",
          label: "プライズ機種名（ブース名）",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "boothCategory",
          label: "ブース区分",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "prizeCd",
          label: "景品CD",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "prizeName",
          label: "景品名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "sortBy",
          label: "並び替え",
          render: ({ field }) => (
            <Select {...field} value={field.value ?? ""}>
              <MenuItem value="" sx={{ height: 36 }} />
              {Object.entries(prizeDailySortByLabels).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          ),
        },
      ]}
    />
  )
}
