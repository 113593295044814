import { Button, Stack } from "@mui/material"
import { Link as RouterLink, useParams } from "react-router-dom"

import { InventoryPrizeExecuteBoothFloorMap } from "src/components/organisms/prizes/InventoryPrizeExecuteBoothFloorMap"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { PlacementType } from "src/domains/prizes/placementStatusRepository"

export const InventoryPrizeExecuteInBoothFloorMap: React.FC = () => {
  const { arcadeCd } = useParams()

  return (
    <MainContentLayout
      title="在庫カウント(プライズ機[内])"
      renderContent={() => (
        <Stack gap={2}>
          <InventoryPrizeExecuteBoothFloorMap
            placementType={PlacementType.InBooth}
          />
          <Button
            variant="outlined"
            fullWidth
            component={RouterLink}
            to={`/arcades/${arcadeCd}/inventory/prizes/execute/inBooth/add`}
          >
            景品を検索してブース内に登録
          </Button>
        </Stack>
      )}
    />
  )
}
