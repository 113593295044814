/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */

/**
 * メーター入力方法
 */
export type RequestMeterReadInitMeterCategoryEnum =
  (typeof RequestMeterReadInitMeterCategoryEnum)[keyof typeof RequestMeterReadInitMeterCategoryEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RequestMeterReadInitMeterCategoryEnum = {
  Analog: "analog",
  Soft: "soft",
} as const
