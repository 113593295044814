import { forwardRef, useMemo } from "react"

import { Autocomplete, Stack, TextField, Typography } from "@mui/material"

import { WordBreakAllList } from "src/components/atoms/WordBreakAllList"

const limitItems = [25, 50, 100] as const
export type TableLimit = (typeof limitItems)[number]

interface PaginationTableLabelProps {
  from: number
  to: number
  count: number
  offset: number
  limit: TableLimit
  handlePageChange: (page: number) => void
  setLimit: (limit: TableLimit) => void
}

export const PaginationTableLabel: React.FC<PaginationTableLabelProps> = ({
  from,
  to,
  count,
  offset,
  limit,
  handlePageChange,
  setLimit,
}: PaginationTableLabelProps) => {
  const limitItems: TableLimit[] = [25, 50, 100]
  const offsetItems = useMemo(
    () => [...new Array(Math.ceil(count / limit))].map((_, i) => i),
    [count, limit],
  )
  return (
    <Stack
      sx={{
        justifyContent: "space-between",
        flexDirection: "row",
        flexGrow: 1,
        flexShrink: 0,
      }}
    >
      <Stack sx={{ flexDirection: "row", flexGrow: 1, flexShrink: 0 }}>
        <Typography sx={{ fontSize: "0.85rem" }}>
          {from}-{to} / {count} 件中
        </Typography>
      </Stack>
      <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
        <Stack
          sx={{ flexDirection: "row", alignItems: "center" }}
          data-testid="paginate-limit"
        >
          <Typography variant="caption">表示件数</Typography>
          <PaginateAutocomplete
            value={{
              label: limit.toString(),
              value: limit,
            }}
            items={limitItems.map((item) => ({
              label: item.toString(),
              value: item,
            }))}
            // ページ切り替えでAPI呼び出しが走る場合に選択肢が消えない場合があるため
            // 遅延させることでautomatic batchingさせず、API呼び出しを待たずに選択肢を消すようにする
            onChange={(value) =>
              setTimeout(() => {
                setLimit(value as TableLimit)
              })
            }
          />
        </Stack>
        <Stack
          sx={{ flexDirection: "row", alignItems: "center" }}
          data-testid="paginate-offset"
        >
          <PaginateAutocomplete
            value={{
              label: offset.toString(),
              value: offset - 1,
            }}
            items={offsetItems.map((item) => ({
              label: (item + 1).toString(),
              value: item,
            }))}
            // ページ切り替えでAPI呼び出しが走る場合に選択肢が消えない場合があるため
            // 遅延させることでautomatic batchingさせず、API呼び出しを待たずに選択肢を消すようにする
            onChange={(value) => setTimeout(() => handlePageChange(value), 0)}
          />
          <Typography variant="caption">
            / {Math.ceil(count / limit)} ページ
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

type PaginateAutocompleteItem = {
  label: string
  value: number
}
type PaginateAutocompleteProps = {
  onChange: (value: number) => void
  value: { label: string; value: number }
  items: { label: string; value: number }[]
}

const PaginateAutocomplete = forwardRef<HTMLElement, PaginateAutocompleteProps>(
  ({ onChange, value, items }, ref) => {
    return (
      <Autocomplete<PaginateAutocompleteItem>
        ref={ref}
        value={value}
        options={items}
        renderOption={(props, option) => (
          <WordBreakAllList {...props} key={option.value}>
            {option.label}
          </WordBreakAllList>
        )}
        onChange={(_, option) => option && onChange(option.value)}
        renderInput={(params) => <TextField {...params} />}
        slotProps={{
          clearIndicator: {
            sx: { display: "none" },
          },
        }}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": { border: "none" },
          "& .MuiFormControl-root": { minWidth: 80 },
          "& .MuiInputBase-root": {
            minWidth: 80,
            height: 20,
            px: "0!important",
          },
          "& .MuiButtonBase-root": {
            width: 20,
            height: 20,
            color: "gray.80",
          },
          "& input": {
            pr: "28px!important",
            minWidth: 80,
            height: 20,
            fontSize: 12,
            textAlign: "right",
          },
        }}
      />
    )
  },
)

PaginateAutocomplete.displayName = "PaginateAutocomplete"
