import { useCallback } from "react"

import { Construction, NotInterested, Sync } from "@mui/icons-material"
import { Stack, Typography } from "@mui/material"
import { alpha } from "@mui/material/styles"

import {
  FloorMapBox,
  FloorMapPointBox,
  FloorMapPointBoxProps,
  FloorMapPointNameBox,
} from "src/components/organisms/FloorMapBox"
import { PrizeMeterReadFloorMapPoint } from "src/domains/prizes/floorMapRepository"
import { getMeterReadHasInput } from "src/domains/prizes/meterRead"

type Props = {
  points: PrizeMeterReadFloorMapPoint[]
  isDisableEditByDate?: boolean
  onClickPoint?: (point: PrizeMeterReadFloorMapPoint) => void
}

export const PrizeMeterReadFloorMapBox: React.FC<Props> = ({
  points,
  isDisableEditByDate,
  onClickPoint,
}) => {
  const getFloorMapPointBox = useCallback(
    ({ point }: FloorMapPointBoxProps) => {
      const { booth, meters: [meter] = [] } =
        point as PrizeMeterReadFloorMapPoint

      const isBroken = Boolean(meter?.isBroken)
      const isNotAvailable = !booth?.isAvailable
      const isInSync = Boolean(meter?.thincaTerminalNumber)

      const warning =
        !isBroken && !isNotAvailable && !getMeterReadHasInput(meter)
      const disabled = isDisableEditByDate || isNotAvailable

      return (
        <FloorMapPointBox
          point={point}
          onClickPoint={onClickPoint && (() => onClickPoint(point))}
          sx={[
            { display: "flex", flexDirection: "column" },
            warning && { bgcolor: "warning.light" },
            disabled &&
              ((theme) => ({
                bgcolor: alpha(theme.palette.gray[90], 0.12),
                color: alpha(theme.palette.gray[90], 0.26),
                pointerEvents: "none",
              })),
          ]}
        >
          <FloorMapPointNameBox point={point} sx={{ flex: 1, fontSize: 14 }} />
          <Stack
            direction="row"
            justifyContent="space-between"
            flexShrink={0}
            mx={0.5}
            mb={0.5}
            px={0.5}
            alignSelf="center"
            width="100%"
            maxWidth={90}
          >
            <Construction
              fontSize="small"
              sx={
                isBroken
                  ? { color: "gray.60" }
                  : { color: "gray.90", opacity: 0.12 }
              }
            />

            <NotInterested
              fontSize="small"
              sx={
                isNotAvailable
                  ? { color: "gray.60" }
                  : { color: "gray.90", opacity: 0.12 }
              }
            />
            <Sync
              fontSize="small"
              sx={
                isInSync
                  ? { color: "gray.60" }
                  : { color: "gray.90", opacity: 0.12 }
              }
            />
          </Stack>
        </FloorMapPointBox>
      )
    },
    [isDisableEditByDate, onClickPoint],
  )

  const renderDescription = useCallback(
    () => (
      <Stack direction="row" alignItems="center" spacing={2}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Construction fontSize="small" sx={{ color: "gray.60" }} />
          <Typography component="span" variant="caption">
            故障
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Sync fontSize="small" sx={{ color: "gray.60" }} />
          <Typography component="span" variant="caption">
            シンカ連携済み
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <NotInterested fontSize="small" sx={{ color: "gray.60" }} />
          <Typography component="span" variant="caption">
            撤去済み
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Typography
            component="span"
            variant="subtitle2"
            sx={{
              backgroundColor: "warning.light",
              borderRadius: 0.5,
              px: 1,
              py: 0.5,
            }}
          >
            背景がオレンジ
          </Typography>
          <Typography component="span" variant="caption">
            未入力
          </Typography>
        </Stack>
      </Stack>
    ),
    [],
  )
  return (
    <FloorMapBox
      floorMapPoints={points}
      getFloorMapPointBox={getFloorMapPointBox}
      renderDescription={renderDescription}
    />
  )
}
