import {
  useMemo,
  useCallback,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
} from "react"

import { Download, Edit } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Typography,
  TableHead,
  TableRow,
  Card,
  Tabs,
  Tab,
  Button,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material"
import JSZip from "jszip"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { getInventoryCsvExport } from "src/api/inventory-csv"
import { getMaterialInventoryDifferences } from "src/api/material-inventory-differences"
import {
  Csv,
  CsvRow,
  ExecutionPeriod,
  FeatureExecutionPeriod,
  GetMaterialInventoryDifferencesResponse,
  Material,
  MaterialInventoryDifference,
  ExecutionPeriodStatusEnum,
  FeatureExecutionPeriodStatusEnum,
  MaterialInventoryDifferenceGroupEnum,
} from "src/api/models"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { AlertCaptionCard } from "src/components/molecules/AlertCaptionCard"
import {
  CardItemNameBox,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import { InventoryExecutionPeriodWarning } from "src/components/organisms/InventoryExecutionPeriodWarning"
import { InventoryMaterialApplyDifferenceModal } from "src/components/organisms/materials/InventoryMaterialApplyDifferenceModal"
import { InventoryMaterialApproveDifferenceModal } from "src/components/organisms/materials/InventoryMaterialApproveDifferenceModal"
import {
  filterDifferences,
  InventoryMaterialDifferenceFilter,
} from "src/components/organisms/materials/InventoryMaterialDifferenceFilter"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  executionPeriodTentativeEndAt,
  findLastFeatureExecutionPeriod,
  findPreviousFeatureExecutionPeriod,
} from "src/domains/inventoryExecutionPeriodRepository"
import {
  differenceGroupNames,
  differenceGroups,
  materialDifferenceTableHeaders,
  getMaterialDifferenceLabel,
  materialDifferenceCsvKeys,
  inventoryDifferenceFilterSortByLabels,
} from "src/domains/materials/materialInventoryHistoriesRepository"
import { useDownloadCsv } from "src/hooks/useDownloadCsv"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import {
  currentArcadeState,
  currentInventoryPeriodState,
  inventoryPeriodsState,
} from "src/recoil"
import { inventoryMaterialDifferenceSearchParamsState } from "src/recoil/inventoryMaterials"
import { downloadBlobFile } from "src/utils"

export type InventoryMaterialDifferenceSearchParams = Partial<
  Pick<MaterialInventoryDifference, "materialCd" | "materialName" | "ipName">
> & {
  materialNameKana?: string
  makerName?: Material["makerName"]
  group: MaterialInventoryDifference["group"]
  materialCdInSeams?: string
  sortBy?: "" | keyof typeof inventoryDifferenceFilterSortByLabels
}

export const InventoryMaterialExecuteDifferences = () => {
  const { isAdmin } = useUserRole()
  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)

  const executionPeriods = useRecoilValue(inventoryPeriodsState)
  const [selectedExecutionPeriod, setSelectedExecutionPeriod] = useState<
    ExecutionPeriod | undefined
  >()
  useEffect(() => {
    setSelectedExecutionPeriod(currentInventoryPeriod)
  }, [setSelectedExecutionPeriod, currentInventoryPeriod])

  const { materialExecutionPeriod } = findLastFeatureExecutionPeriod(
    selectedExecutionPeriod,
  )
  const previousExecutionPeriod = findPreviousFeatureExecutionPeriod(
    executionPeriods || [],
    "material",
    selectedExecutionPeriod,
  )

  const ref = useRef<CsvDownloadHandler>(null)

  if (selectedExecutionPeriod && materialExecutionPeriod) {
    return (
      <MainContentLayout
        title="材料差分確認"
        caption="※荷捌きの棚卸実施内容は含まれません"
        renderAction={() => (
          <LoadingButton
            variant="contained"
            onClick={() => ref.current?.download()}
            loading={false}
            startIcon={<Download />}
          >
            差分確認のCSV出力
          </LoadingButton>
        )}
        renderFilter={() => (
          <Stack gap={2}>
            {isAdmin && (
              <Stack
                sx={(theme) => ({
                  backgroundColor: theme.palette.background.paper,
                })}
              >
                <FormControl fullWidth>
                  <InputLabel id="period">期間</InputLabel>
                  <Select
                    fullWidth
                    label="期間"
                    labelId="period"
                    value={selectedExecutionPeriod.id}
                    onChange={(e) => {
                      setSelectedExecutionPeriod(
                        executionPeriods?.find(
                          (period) =>
                            period.id ===
                            (e.target.value as ExecutionPeriod["id"]),
                        ),
                      )
                    }}
                  >
                    {executionPeriods?.map(({ id, startAt, endAt }) => (
                      <MenuItem key={`${startAt}〜${endAt}`} value={id}>
                        {startAt}〜
                        {endAt !== executionPeriodTentativeEndAt ? endAt : ""}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            )}
            <Stack>
              <InventoryMaterialDifferenceFilter />
            </Stack>
          </Stack>
        )}
        renderContent={() => (
          <InventoryMaterialExecuteDifferencesMenu
            ref={ref}
            selectedExecutionPeriod={selectedExecutionPeriod}
            materialPeriod={materialExecutionPeriod}
            preMaterialPeriod={previousExecutionPeriod}
          />
        )}
      />
    )
  }

  return (
    <InventoryExecutionPeriodWarning
      title="差分一覧"
      period={selectedExecutionPeriod}
      featurePeriod={materialExecutionPeriod}
    />
  )
}

interface CsvDownloadHandler {
  download: () => void
}

type InventoryMaterialExecuteDifferencesMenuProps = {
  selectedExecutionPeriod: ExecutionPeriod
  materialPeriod: FeatureExecutionPeriod
  preMaterialPeriod?: FeatureExecutionPeriod
}

export const InventoryMaterialExecuteDifferencesMenu = forwardRef<
  CsvDownloadHandler,
  InventoryMaterialExecuteDifferencesMenuProps
>(({ selectedExecutionPeriod, materialPeriod, preMaterialPeriod }, ref) => {
  const { arcadeCd } = useParams()
  const [searchParams, setSearchParams] = useRecoilState(
    inventoryMaterialDifferenceSearchParamsState,
  )

  const { resource } = useResource({
    subject: "取り込み済の循環棚卸オーダーの取得",
    hideErrorMessage: true,
    fetch:
      arcadeCd && selectedExecutionPeriod
        ? () => getInventoryCsvExport(arcadeCd, selectedExecutionPeriod.id)
        : undefined,
    recoilKey: `getInventoryCsvExport:${arcadeCd}:${JSON.stringify(selectedExecutionPeriod)}`,
  })
  const exportedCsv = resource?.data.csv

  const differencesReturn = useResource({
    subject: "差分一覧の取得",
    fetch:
      arcadeCd && materialPeriod
        ? () =>
            getMaterialInventoryDifferences(arcadeCd, materialPeriod.id, {
              materialCd: searchParams.materialCd,
              materialCdInSeams: searchParams.materialCdInSeams,
              materialName: searchParams.materialName,
              materialNameKana: searchParams.materialNameKana,
              ipName: searchParams.ipName,
              makerName: searchParams.makerName,
            })
        : undefined,
    recoilKey: `getMaterialInventoryDifferences:${arcadeCd}:${materialPeriod.id}:${JSON.stringify(
      searchParams,
    )}`,
  })
  const differencesRes = differencesReturn?.resource?.data

  const previousDifferencesReturn = useResource({
    subject: "差分一覧の取得",
    fetch:
      arcadeCd && preMaterialPeriod
        ? () => getMaterialInventoryDifferences(arcadeCd, preMaterialPeriod.id)
        : undefined,
    recoilKey: `getMaterialInventoryDifferences:${arcadeCd}:${JSON.stringify(
      preMaterialPeriod,
    )}`,
    skip: !preMaterialPeriod,
  })
  const previousDifferencesRes = previousDifferencesReturn?.resource?.data

  const differences = useMemo(
    () => differencesRes?.differences || [],
    [differencesRes],
  )
  const filteredDifferences = useMemo(
    () => (differences ? filterDifferences(differences, searchParams) : {}),
    [differences, searchParams],
  )
  const groupedDifferences = useMemo(() => {
    if (!filteredDifferences) return []
    return filteredDifferences[searchParams.group] || []
  }, [filteredDifferences, searchParams])

  const isShowAlert = useMemo(() => {
    if (!filteredDifferences) return false
    const differences =
      filteredDifferences[MaterialInventoryDifferenceGroupEnum.DiffPriceOver]
    if (!differences) return false
    const notApprovedDifferences = differences.filter((p) => !p.isApproved)
    if (notApprovedDifferences.length === 0) return false
    return true
  }, [filteredDifferences])

  const arcade = useRecoilValue(currentArcadeState)
  const zipFileName = useMemo(
    () =>
      `差分確認CSV-${arcade?.name}-${materialPeriod.startAt}_${materialPeriod.endAt}`,
    [arcade, materialPeriod],
  )
  const { getCsvFileData } = useDownloadCsv()
  const downloadZippedCsvs = useCallback(
    async (
      csvs: {
        csv: Csv
        fileName: string
      }[],
    ) => {
      const zip = new JSZip()
      csvs.forEach(({ fileName, csv }) => {
        const csvFileData = getCsvFileData(csv)
        if (!csvFileData) return
        zip.file(fileName, csvFileData)
      })
      const zipFileData = await zip.generateAsync({ type: "blob" })
      downloadBlobFile({
        blob: zipFileData,
        fileName: `${zipFileName}.zip`,
      })
    },
    [getCsvFileData, zipFileName],
  )

  const stackRef = useRef<HTMLDivElement>(null)
  useImperativeHandle(
    ref,
    () => ({
      download() {
        stackRef.current?.click()
      },
    }),
    [],
  )

  const onSubmit = useCallback(async () => {
    const headerRow = {
      columns: materialDifferenceCsvKeys.map((key) =>
        materialDifferenceTableHeaders[key].label.join(""),
      ),
    }

    const csvs = differenceGroups.map((group, i) => {
      const rows: CsvRow[] = (filteredDifferences[group] || []).map(
        (difference) => {
          const tableItems = getMaterialDifferenceLabel(difference)
          return {
            columns: materialDifferenceCsvKeys.map((key) => {
              const tableItem = tableItems[key]
              if (key === "materialCdsInSeams" && Array.isArray(tableItem)) {
                return tableItem.join("/")
              }
              if (typeof tableItem === "string") {
                return tableItem.replace(/[,\n]/g, "")
              }
              return tableItem.toString()
            }),
          }
        },
      )

      return {
        csv: { headerRow, rows },
        fileName: `差分確認CSV_${i + 1}_${differenceGroupNames[group]}.csv`,
      }
    })
    await downloadZippedCsvs(csvs)
  }, [filteredDifferences, downloadZippedCsvs])

  const isShowExecutionAlert =
    selectedExecutionPeriod?.status === ExecutionPeriodStatusEnum.Active &&
    materialPeriod.status !== FeatureExecutionPeriodStatusEnum.Active

  return (
    <>
      <Stack gap={2}>
        {isShowAlert && (
          <AlertCaptionCard
            label="「戻しが10万円を超えるもの」に承認が必要な項目があります。必ず理由を記載し、エリアマネージャーへ承認を依頼してください。"
            variant="error"
          />
        )}
        {isShowExecutionAlert && (
          <AlertCaptionCard
            label="この項目は確定済です。次の棚卸は、結果出力を行った後に実施出来ます。"
            variant="error"
          />
        )}
        {!exportedCsv && (
          <AlertCaptionCard
            label="循環棚卸オーダーが取り込まれていません。循環棚卸オーダーの取込みを行ってください。"
            variant="error"
          />
        )}

        {differencesRes && (
          <TotalPricesCard
            differencesRes={differencesRes}
            previousDifferencesRes={previousDifferencesRes}
          />
        )}

        <Tabs
          value={searchParams.group}
          onChange={(_, value) =>
            setSearchParams((params) => ({ ...params, group: value }))
          }
          scrollButtons={false}
        >
          {differenceGroups.map((group) => (
            <Tab
              key={group}
              value={group}
              label={differenceGroupNames[group]}
            />
          ))}
        </Tabs>
        <InventoryMaterialExecuteDifferencesTable
          materialExecutionPeriod={materialPeriod}
          differences={groupedDifferences}
          group={searchParams.group}
          fetchDifferences={() => differencesReturn?.refetch()}
        />
      </Stack>
      <Stack onClick={onSubmit} ref={stackRef} hidden />
    </>
  )
})
InventoryMaterialExecuteDifferencesMenu.displayName =
  "InventoryMaterialExecuteDifferencesMenu"

interface TotalPricesCardProps {
  differencesRes: GetMaterialInventoryDifferencesResponse
  previousDifferencesRes: GetMaterialInventoryDifferencesResponse | undefined
}

const TotalPricesCard: React.FC<TotalPricesCardProps> = ({
  differencesRes,
  previousDifferencesRes,
}: TotalPricesCardProps) => {
  const { totalInventoryPrices, totalPayoutPrices } = differencesRes
  const {
    totalInventoryPrices: previousTotalInventoryPrices,
    totalPayoutPrices: previousTotalPayoutPrices,
  } = previousDifferencesRes || {}

  return (
    <Stack
      sx={{
        justifyContent: "space-between",
        flexDirection: "row",
        gap: 2,
      }}
    >
      <Card sx={{ p: 2, flexGrow: 1 }}>
        <Stack
          sx={{
            justifyContent: "space-between",
            flexDirection: "row",
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography variant="subtitle1">前回の棚卸合計金額</Typography>
          <Typography variant="subtitle1">
            {previousTotalInventoryPrices?.toLocaleString() ?? "0"}円
          </Typography>
        </Stack>
        <Stack
          sx={{
            justifyContent: "space-between",
            flexDirection: "row",
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography variant="subtitle1">今回の棚卸合計金額</Typography>
          <Typography variant="subtitle1">
            {totalInventoryPrices.toLocaleString()}円
          </Typography>
        </Stack>
      </Card>

      <Card sx={{ p: 2, flexGrow: 1 }}>
        <Stack
          sx={{
            justifyContent: "space-between",
            flexDirection: "row",
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography variant="subtitle1">前回の合計消費景品代</Typography>
          <Typography variant="subtitle1">
            {previousTotalPayoutPrices?.toLocaleString() ?? "0"}円
          </Typography>
        </Stack>
        <Stack
          sx={{
            justifyContent: "space-between",
            flexDirection: "row",
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography variant="subtitle1">今回の合計消費景品代</Typography>
          <Typography variant="subtitle1">
            {totalPayoutPrices.toLocaleString()}円
          </Typography>
        </Stack>
      </Card>
    </Stack>
  )
}

const materialNameGradation =
  "-webkit-gradient(linear, 95% 0%, 98% 0%, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))"

interface InventoryExecuteDifferencesTableProps {
  materialExecutionPeriod: FeatureExecutionPeriod
  differences: MaterialInventoryDifference[]
  group: MaterialInventoryDifferenceGroupEnum
  fetchDifferences: () => void
}

const InventoryMaterialExecuteDifferencesTable: React.FC<
  InventoryExecuteDifferencesTableProps
> = ({ materialExecutionPeriod, differences, group, fetchDifferences }) => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()
  const { isAvailableApproveInventoryDifference } = useUserRole()

  const getLines = (itemName: string[]) =>
    itemName.map((line, i) => <p key={`${i}-${line}`}>{line}</p>)

  const handleClickRow = useCallback(
    (materialCd: string) => {
      if (!materialCd) return
      navigate(
        `/arcades/${arcadeCd}/inventory/materials/differences/${materialCd}`,
      )
    },
    [arcadeCd, navigate],
  )

  const { DiffPriceOver } = MaterialInventoryDifferenceGroupEnum

  return (
    <PaginatedTable
      scrollableX
      noMargin
      items={differences}
      stateKey="inventoryMaterialExecuteDifferenceTable"
      header={
        <TableHead>
          <TableRow
            sx={{ th: { px: 1, whiteSpace: "nowrap", textAlign: "center" } }}
          >
            <ExtTableCell sx={{ width: 320, borderRight: "1px solid" }}>
              {getLines(materialDifferenceTableHeaders.materialName.label)}
            </ExtTableCell>
            {group == DiffPriceOver && (
              <>
                <ExtTableCell>
                  {getLines(materialDifferenceTableHeaders.isApproved.label)}
                </ExtTableCell>
                {isAvailableApproveInventoryDifference && (
                  <ExtTableCell>
                    {getLines(materialDifferenceTableHeaders.approve.label)}
                  </ExtTableCell>
                )}
                <ExtTableCell sx={{ width: 160 }}>
                  {getLines(materialDifferenceTableHeaders.application.label)}
                </ExtTableCell>
              </>
            )}
            <ExtTableCell>
              {getLines(materialDifferenceTableHeaders.materialCd.label)}
            </ExtTableCell>
            <ExtTableCell>
              {getLines(
                materialDifferenceTableHeaders.materialCdsInSeams.label,
              )}
            </ExtTableCell>
            <ExtTableCell>
              {getLines(materialDifferenceTableHeaders.importedStock.label)}
            </ExtTableCell>
            <ExtTableCell>
              {getLines(materialDifferenceTableHeaders.stock.label)}
            </ExtTableCell>
            <ExtTableCell>
              {getLines(materialDifferenceTableHeaders.payoutStock.label)}
            </ExtTableCell>
            <ExtTableCell>
              {getLines(materialDifferenceTableHeaders.consumedPrice.label)}
            </ExtTableCell>
            <ExtTableCell>
              {getLines(materialDifferenceTableHeaders.isInSeams.label)}
            </ExtTableCell>
            <ExtTableCell>
              {getLines(materialDifferenceTableHeaders.isInGigoNavi.label)}
            </ExtTableCell>
            <ExtTableCell>
              {getLines(materialDifferenceTableHeaders.differentPrice.label)}
            </ExtTableCell>
          </TableRow>
        </TableHead>
      }
      renderRow={(difference) => {
        const display = getMaterialDifferenceLabel(difference)
        return (
          <TableBorderedRow
            sx={{
              td: {
                p: 1,
                textAlign: "center",
              },
              "&:hover td": {
                ...(difference.materialCd && {
                  cursor: "pointer",
                  background: (theme) => theme.palette.neutral[200],
                  WebkitMaskImage: "none",
                }),
              },
            }}
            onClick={() => handleClickRow(difference.materialCd)}
            key={`${difference.materialCd}-${difference.materialCdsInSeams.join(
              "/",
            )}`}
            data-testid={`${difference.materialCd}-${difference.materialCdsInSeams}`}
          >
            <ExtTableCell
              sx={{
                textAlign: "start !important",
                whiteSpace: "nowrap",
                minWidth: 320,
                maxWidth: 320,
                WebkitMaskImage: materialNameGradation,
                borderRight: "1px solid",
              }}
            >
              <CardItemNameBox>
                <Typography variant="subtitle1" color="primary.main">
                  {display.materialName}
                </Typography>
              </CardItemNameBox>
            </ExtTableCell>
            {difference.group == DiffPriceOver && (
              <>
                <ExtTableCell
                  sx={{
                    ...(!difference.isApproved && {
                      color: "error.main",
                    }),
                  }}
                >
                  {display.isApproved}
                </ExtTableCell>
                {isAvailableApproveInventoryDifference && (
                  <DifferencesTableApproveDifferenceCell
                    materialExecutionPeriod={materialExecutionPeriod}
                    difference={difference}
                    fetchDifferences={fetchDifferences}
                  />
                )}
                <DifferencesTableApplyDifferenceCell
                  materialExecutionPeriod={materialExecutionPeriod}
                  difference={difference}
                  fetchDifferences={fetchDifferences}
                />
              </>
            )}
            <ExtTableCell>{display.materialCd}</ExtTableCell>
            <ExtTableCell>{display.materialCdsInSeams}</ExtTableCell>
            <ExtTableCell>{display.importedStock}</ExtTableCell>
            <ExtTableCell>{display.stock}</ExtTableCell>
            <ExtTableCell>{display.payoutStock}</ExtTableCell>
            <ExtTableCell>{display.consumedPrice}</ExtTableCell>
            <ExtTableCell
              sx={{
                ...(!difference.isInSeams && {
                  fontWeight: "bold",
                  color: "error.main",
                }),
              }}
            >
              {display.isInSeams}
            </ExtTableCell>
            <ExtTableCell
              sx={{
                ...(!difference.isInGigoNavi && {
                  fontWeight: "bold",
                  color: "error.main",
                }),
              }}
            >
              {display.isInGigoNavi}
            </ExtTableCell>
            <ExtTableCell
              sx={{
                ...(difference.group === DiffPriceOver && {
                  fontWeight: "bold",
                  color: "error.main",
                }),
              }}
            >
              {display.differentPrice}
            </ExtTableCell>
          </TableBorderedRow>
        )
      }}
    />
  )
}

interface DifferencesTableApplyDifferenceCellProps {
  materialExecutionPeriod: FeatureExecutionPeriod
  difference: MaterialInventoryDifference
  fetchDifferences: () => void
}

const DifferencesTableApplyDifferenceCell: React.FC<
  DifferencesTableApplyDifferenceCellProps
> = ({ materialExecutionPeriod, difference, fetchDifferences }) => {
  const { isAvailableApplyInventoryDifference } = useUserRole()
  const isEnableApply =
    !difference?.isApproved && isAvailableApplyInventoryDifference

  const [showApplyModal, setShowApplyModal] = useState<boolean>(false)
  const onClickApplyApproval = () => {
    if (isEnableApply) setShowApplyModal(true)
  }

  const applyStopPropagation = (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
  ) => {
    if (
      difference.group === MaterialInventoryDifferenceGroupEnum.DiffPriceOver &&
      isEnableApply
    ) {
      e.stopPropagation()
    }
  }

  const { application } = getMaterialDifferenceLabel(difference)
  return (
    <ExtTableCell
      onClick={(e) => applyStopPropagation(e)}
      sx={{
        textAlign: "start !important",
        whiteSpace: "nowrap",
        minWidth: 160,
        maxWidth: 160,
        ...(application === "未入力" && {
          color: "error.main",
        }),
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <CardItemNameBox>
          <Typography variant="body2">{application}</Typography>
        </CardItemNameBox>
        {isEnableApply && (
          <Edit
            sx={(theme) => ({
              color: theme.palette.icon.blue,
              mx: 1,
            })}
            fontSize="small"
            onClick={() => onClickApplyApproval()}
          />
        )}
      </Stack>

      <InventoryMaterialApplyDifferenceModal
        showModal={showApplyModal}
        difference={difference}
        materialExecutionPeriodId={materialExecutionPeriod.id}
        onClose={() => setShowApplyModal(false)}
        onFinish={() => fetchDifferences()}
      />
    </ExtTableCell>
  )
}

interface DifferencesTableApproveDifferenceCellProps {
  materialExecutionPeriod: FeatureExecutionPeriod
  difference: MaterialInventoryDifference
  fetchDifferences: () => void
}

const DifferencesTableApproveDifferenceCell: React.FC<
  DifferencesTableApproveDifferenceCellProps
> = ({ materialExecutionPeriod, difference, fetchDifferences }) => {
  const isEnableApprove =
    !difference?.isApproved && (difference?.applicationId || 0) > 0
  const [showApproveModal, setShowApproveModal] = useState<boolean>(false)
  const onClickApprove = () => {
    if (isEnableApprove) setShowApproveModal(true)
  }

  const applyStopPropagation = (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
  ) => {
    if (
      difference.group === MaterialInventoryDifferenceGroupEnum.DiffPriceOver
    ) {
      e.stopPropagation()
    }
  }

  const { application } = getMaterialDifferenceLabel(difference)
  return (
    <ExtTableCell
      onClick={(e) => applyStopPropagation(e)}
      sx={{
        textAlign: "center",
        whiteSpace: "nowrap",
        ...(application === "未入力" && {
          color: "error.main",
        }),
      }}
    >
      <Button
        variant="contained"
        disabled={!isEnableApprove}
        onClick={() => onClickApprove()}
      >
        承認する
      </Button>

      <InventoryMaterialApproveDifferenceModal
        showModal={showApproveModal}
        difference={difference}
        materialExecutionPeriodId={materialExecutionPeriod.id}
        onClose={() => setShowApproveModal(false)}
        onFinish={() => fetchDifferences()}
      />
    </ExtTableCell>
  )
}
