import {
  Fragment,
  startTransition,
  useCallback,
  useMemo,
  useState,
} from "react"

import { Edit } from "@mui/icons-material"
import { Typography, Stack, Button, Card, Chip } from "@mui/material"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useNavigate, useParams, useLocation } from "react-router-dom"

import { PrizeDailyPlansElement } from "src/api/models"
import { getPrizeDailyPlans } from "src/api/prize-plans"
import {
  DateRangePicker,
  DateRangePickerDateLabel,
} from "src/components/atoms/DateRangePicker"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { SelectablePaginatedTable } from "src/components/organisms/SelectablePaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { getJpDateLabel, getToday, getDaysAgo } from "src/utils"

export const PrizeDailyBooth: React.FC = () => {
  const { boothName } = useParams()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const from = query.get("from")
  const to = query.get("to")
  const [searchParams, setSearchParams] =
    useState<PrizeDailyBoothFilterSearchParams>({
      dateRangeLabel: {
        start: from ?? getDaysAgo(7),
        end: to ?? getToday(),
      },
    })

  return (
    <MainContentLayout
      title="ブース別投入景品 ブース詳細"
      subtitle={boothName}
      renderFilter={() => (
        <PrizeDailyBoothFilter
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      )}
      renderContent={() => <PrizeDailyBoothInner searchParams={searchParams} />}
    />
  )
}

type PrizeDailyBoothFilterSearchParams = {
  dateRangeLabel: DateRangePickerDateLabel
}

type PrizeDailyBoothFilterProps = {
  searchParams: PrizeDailyBoothFilterSearchParams
  setSearchParams: React.Dispatch<
    React.SetStateAction<PrizeDailyBoothFilterSearchParams>
  >
}
const PrizeDailyBoothFilter: React.FC<PrizeDailyBoothFilterProps> = ({
  searchParams,
  setSearchParams,
}) => {
  const { handleSubmit, control } = useForm<PrizeDailyBoothFilterSearchParams>()

  const onSubmit: SubmitHandler<PrizeDailyBoothFilterSearchParams> =
    useCallback(
      (data) => {
        startTransition(() => setSearchParams(data))
      },
      [setSearchParams],
    )

  return (
    <Card sx={{ py: 1.5, px: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2} sx={{ py: 2, px: 3 }}>
          <Stack
            justifyContent="space-between"
            sx={(theme) => ({
              flexDirection: "row",
              gap: 9,
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                gap: 2,
              },
            })}
          >
            <Stack direction="row" sx={{ flex: 1 }}>
              <Controller
                name="dateRangeLabel"
                control={control}
                defaultValue={searchParams.dateRangeLabel}
                render={({ field }) => (
                  <DateRangePicker
                    dateRangeLabel={field.value}
                    setDateRangeLabel={field.onChange}
                  />
                )}
              />
            </Stack>
          </Stack>
          <Stack pt={1}>
            <Button variant="contained" type="submit">
              検索する
            </Button>
          </Stack>
        </Stack>
      </form>
    </Card>
  )
}

type PrizeDailyBoothInnerProps = {
  searchParams: PrizeDailyBoothFilterSearchParams
}
const PrizeDailyBoothInner: React.FC<PrizeDailyBoothInnerProps> = ({
  searchParams,
}) => {
  const { arcadeCd, boothName } = useParams()
  const navigate = useNavigate()

  const [selectedDailyPlans, setSelectedDailyPlans] = useState<
    PrizeDailyPlansElement[]
  >([])

  const {
    dateRangeLabel: { start, end },
  } = searchParams

  const prizeDailyPlansReturn = useResource({
    subject: "デイリー入替計画の取得",
    fetch: arcadeCd
      ? () => getPrizeDailyPlans(arcadeCd, { from: start, to: end, boothName })
      : undefined,
    recoilKey: `getPrizeDailyPlans:${arcadeCd}:${start}:${end}:${boothName}`,
  }).resource
  const prizeDailyPlans = useMemo(
    () =>
      (prizeDailyPlansReturn?.data.plans || [])
        .filter((e) => e.booth.boothName.startsWith(boothName || ""))
        .sort((a: PrizeDailyPlansElement, b: PrizeDailyPlansElement) => {
          return a.plan.recordedAt > b.plan.recordedAt ? 1 : -1
        }),
    [prizeDailyPlansReturn, boothName],
  )

  return (
    <>
      <Stack
        sx={{
          pb: 2,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          variant="outlined"
          sx={{ mr: 2, whiteSpace: "nowrap" }}
          startIcon={<Edit />}
          disabled={selectedDailyPlans.length === 0}
          onClick={() =>
            navigate(
              `/arcades/${arcadeCd}/prizes/plans/daily/edit?from=${
                searchParams.dateRangeLabel.start
              }&to=${
                searchParams.dateRangeLabel.end
              }&prizeDailyPlanIds=${selectedDailyPlans
                .map(({ plan }) => plan.id)
                .join(",")}`,
            )
          }
        >
          複数ブース編集
        </Button>
      </Stack>

      <PrizeDailyBoothTable
        tableData={prizeDailyPlans ?? []}
        selectedDailyPlans={selectedDailyPlans}
        setSelectedDailyPlans={setSelectedDailyPlans}
      />
    </>
  )
}

interface PrizeDailyBoothTableProps {
  tableData: PrizeDailyPlansElement[]
  selectedDailyPlans: PrizeDailyPlansElement[]
  setSelectedDailyPlans: React.Dispatch<
    React.SetStateAction<PrizeDailyPlansElement[]>
  >
}

const PrizeDailyBoothTable: React.FC<PrizeDailyBoothTableProps> = ({
  tableData,
  selectedDailyPlans,
  setSelectedDailyPlans,
}) => {
  const stateKey = "prizeDailyBoothTable"

  return (
    <Stack
      sx={{
        maxHeight: "calc(100dvh - 392px)",
      }}
    >
      <SelectablePaginatedTable
        scrollableY
        stickyHeader
        noMargin
        items={tableData}
        stateKey={stateKey}
        renderHeaderCells={() => (
          <>
            <ExtTableCell>日付</ExtTableCell>
            <ExtTableCell>ブース区分</ExtTableCell>
            <ExtTableCell>景品CD</ExtTableCell>
            <ExtTableCell>設定</ExtTableCell>
            <ExtTableCell>景品名</ExtTableCell>
          </>
        )}
        renderRowCells={({ plan }) => {
          return (
            <Fragment key={plan.id}>
              <ExtTableCell>{getJpDateLabel(plan.recordedAt)}</ExtTableCell>
              <ExtTableCell>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {plan.isBoothCategoryChanged && (
                    <Chip label="当日更新" color="info" size="small" />
                  )}
                  <Typography variant="body2">{plan?.boothCategory}</Typography>
                </Stack>
              </ExtTableCell>
              <ExtTableCell>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {plan.isPrizePlanChanged && (
                    <Chip label="当日更新" color="info" size="small" />
                  )}
                  <Typography variant="body2">{plan?.prize.prizeCd}</Typography>
                </Stack>
              </ExtTableCell>
              <ExtTableCell>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {plan.isSettingChanged && (
                    <Chip label="当日更新" color="info" size="small" />
                  )}
                  <Typography variant="body2">{plan.setting}</Typography>
                </Stack>
              </ExtTableCell>
              <ExtTableCell>{plan?.prize.prizeName || ""}</ExtTableCell>
            </Fragment>
          )
        }}
        checkIsSameItem={(a, b) => a.plan.id === b.plan.id}
        selectedItems={selectedDailyPlans}
        onChangeSelectedItems={(selectedItems) =>
          setSelectedDailyPlans(selectedItems)
        }
      />
    </Stack>
  )
}
