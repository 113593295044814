import { SetStateAction, useMemo, useState } from "react"

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import {
  Typography,
  Stack,
  MenuItem,
  Select,
  TableRow,
  Link,
  TableHead,
  Card,
  TextField,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { atom, useRecoilState, useRecoilValue } from "recoil"

import { Prize, PrizeDelivery } from "src/api/models"
import { getPrizeDeliveries } from "src/api/prize-deliveries"
import { getPrizeBoothSales } from "src/api/prize-sales"
import { AdminExtTableCell } from "src/components/atoms/AdminExtTableHeaderCell"
import {
  DateRangePicker,
  DateRangePickerDateLabel,
} from "src/components/atoms/DateRangePicker"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { PrizeDetailModal } from "src/components/organisms/prizes/PrizeDetailModal"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { sortFnPrizeDeliveries } from "src/domains/prizes/deliveryRepository"
import {
  PrizePlanSale,
  PrizePlanSales,
  PrizeRank,
  calcPrizePlanSales,
} from "src/domains/prizes/prizeSalesRepository"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import { filterAccordionSearchState } from "src/recoil"
import {
  formatApiDate,
  getDaysAgo,
  getJpDateLabel,
  getMonthEnd,
  getMonthStart,
  getRatioLabel,
  getToday,
} from "src/utils"

type PrizePlansSalesDateRangeLabelState = DateRangePickerDateLabel

const defaultDateRangeLabel = {
  start: getDaysAgo(7),
  end: getToday(),
}

const prizePlansSalesDateRangeLabelState =
  atom<PrizePlansSalesDateRangeLabelState>({
    key: "prizePlansSalesDateRangeLabelState",
    default: defaultDateRangeLabel,
  })

export const PrizePlansSales: React.FC = () => {
  const [dateRangeLabel, setDateRangeLabel] = useRecoilState(
    prizePlansSalesDateRangeLabelState,
  )
  const [searchParams, setRecoilSearchParams] = useRecoilState(
    filterAccordionSearchState,
  )
  const setSearchParams = (params: PrizePlansSalesFilterSearchParams) =>
    setRecoilSearchParams((prev) => ({
      ...prev,
      prizePlansSalesSearchParams: params,
    }))

  return (
    <MainContentLayout
      title="投入景品別の実績一覧"
      renderFilter={() => (
        <Stack gap={2}>
          <Card sx={{ p: 3 }}>
            <DateRangePicker
              dateRangeLabel={dateRangeLabel}
              setDateRangeLabel={(v) => {
                setDateRangeLabel(v)
                setSearchParams({
                  ...(searchParams as PrizePlansSalesFilterSearchParams),
                  arriveAtDateRange: {
                    start: formatApiDate(getMonthStart(v.start)),
                    end: formatApiDate(getMonthEnd(v.end)),
                  },
                })
              }}
            />
          </Card>
          <Stack>
            <PrizePlansSalesFilter />
          </Stack>
        </Stack>
      )}
      renderContent={() => <PrizePlansSalesInner />}
    />
  )
}

const sortByLabels = {
  salesAsc: "売上実績(昇順)",
  salesDesc: "売上実績(降順)",
  PDPBAsc: "景品PDPB(昇順)",
  PDPBDesc: "景品PDPB(降順)",
  arriveAtOrderAsc: "着荷日順",
  machineInputDateOrderAsc: "投入可能日順",
} as const

type PrizePlansSalesFilterSearchParams = {
  arriveAtDateRange: DateRangePickerDateLabel
  prizeCd?: string
  prizeName?: string
  prizeNameKana?: string
  makerName?: string
  ipName?: string
  prizeRank?: PrizeRank
  sortBy: "" | keyof typeof sortByLabels
}

const defaultSearchParams = {
  arriveAtDateRange: {
    start: formatApiDate(getMonthStart()),
    end: formatApiDate(getMonthEnd()),
  },
  sortBy: "",
}

const PrizePlansSalesFilter: React.FC = () => {
  const [recoilSearchParams, setRecoilSearchParams] = useRecoilState(
    filterAccordionSearchState,
  )
  const searchParams: PrizePlansSalesFilterSearchParams =
    recoilSearchParams["prizePlansSalesSearchParams"] ?? defaultSearchParams
  const setSearchParams = (
    params: SetStateAction<PrizePlansSalesFilterSearchParams>,
  ) =>
    setRecoilSearchParams((prev) => ({
      ...prev,
      prizePlansSalesSearchParams: params,
    }))

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み・並び替え"
      formInputs={[
        {
          name: "arriveAtDateRange",
          fullWidth: true,
          label: "着荷日",
          render: ({ field }) => (
            <DateRangePicker
              startInputLabel="開始日"
              endInputLabel="終了日"
              dateRangeLabel={field.value as DateRangePickerDateLabel}
              setDateRangeLabel={field.onChange}
            />
          ),
        },
        {
          name: "prizeCd",
          label: "景品CD",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "prizeName",
          label: "景品名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "prizeNameKana",
          label: "景品名カナ",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              fullWidth
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "makerName",
          label: "メーカー名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "ipName",
          label: "IP名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              fullWidth
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "prizeRank",
          label: "ランク",
          render: ({ field, fieldState: { error } }) => (
            <Select {...field} error={!!error} fullWidth>
              <MenuItem value={undefined}>すべて</MenuItem>
              <MenuItem value="S">S</MenuItem>
              <MenuItem value="A">A</MenuItem>
              <MenuItem value="B">B</MenuItem>
              <MenuItem value="C">C</MenuItem>
            </Select>
          ),
        },
        {
          name: "sortBy",
          label: "並び替え",
          render: ({ field }) => (
            <Select {...field} fullWidth>
              <MenuItem key="" value="" sx={{ height: 36 }} />
              {Object.entries(sortByLabels).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          ),
        },
      ]}
    />
  )
}

const PrizePlansSalesInner: React.FC = () => {
  const { arcadeCd } = useParams()
  const dateRangeLabel = useRecoilValue(prizePlansSalesDateRangeLabelState)
  const searchParams: PrizePlansSalesFilterSearchParams =
    useRecoilValue(filterAccordionSearchState)["prizePlansSalesSearchParams"] ??
    defaultSearchParams

  const { start, end } = dateRangeLabel
  const {
    arriveAtDateRange,
    prizeCd,
    prizeName,
    prizeNameKana,
    ipName,
    makerName,
    prizeRank,
    sortBy,
  } = searchParams

  const prizeDeliveriesReturn = useResource({
    subject: "着荷予定景品一覧の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeDeliveries(arcadeCd, {
            from: arriveAtDateRange.start,
            to: arriveAtDateRange.end,
            prizeCd: prizeCd,
            prizeName: prizeName,
            prizeNameKana: prizeNameKana,
            ipName: ipName,
            makerName: makerName,
          })
      : undefined,
    recoilKey: `getPrizeDeliveries:${arcadeCd}:${arriveAtDateRange.start}:${arriveAtDateRange.end}:${JSON.stringify(searchParams)}`,
  }).resource
  const prizeDeliveries = useMemo(
    () => prizeDeliveriesReturn?.data.deliveries || [],
    [prizeDeliveriesReturn],
  )

  const prizeBoothSalesReturn = useResource({
    subject: "ブース別売上結果の取得",
    fetch: arcadeCd
      ? () => getPrizeBoothSales(arcadeCd, { from: start, to: end })
      : undefined,
    recoilKey: `getPrizeBoothSales:${arcadeCd}:${start}:${end}`,
  }).resource
  const prizeBoothSales = useMemo(
    () => prizeBoothSalesReturn?.data.sales || [],
    [prizeBoothSalesReturn],
  )

  const searchedPrizeBoothSalesReturn = useResource({
    subject: "絞込み済みのブース別売上実績の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeBoothSales(arcadeCd, {
            from: start,
            to: end,
            prizeCd: prizeCd,
            prizeName: prizeName,
            prizeNameKana: prizeNameKana,
            ipName: ipName,
            makerName: makerName,
          })
      : undefined,
    recoilKey: `getPrizeBoothSales:${arcadeCd}:${start}:${end}:${JSON.stringify(searchParams)}`,
    skip: !!prizeCd && !!prizeName, // 絞込みが行われていない場合はスキップ
  }).resource
  const searchedPrizeBoothSales = useMemo(
    () => searchedPrizeBoothSalesReturn?.data.sales || [],
    [searchedPrizeBoothSalesReturn],
  )

  const sales = useMemo(() => {
    const filterFn = (sale: PrizePlanSale) => {
      if (prizeRank && sale.prizeRank !== prizeRank) {
        return false
      }
      if (sale.prize.isKidsMarket) {
        return false
      }
      const prizeCds = searchedPrizeBoothSales.map((s) => s.sale.prize?.prizeCd)
      return prizeCds.length > 0 ? prizeCds.includes(sale.prize.prizeCd) : true
    }

    const sortFn = (a: PrizePlanSale, b: PrizePlanSale) => {
      if (sortBy === "salesAsc") {
        return (a.sales || 0) - (b.sales || 0)
      } else if (sortBy === "salesDesc") {
        return (b.sales || 0) - (a.sales || 0)
      } else if (sortBy === "PDPBAsc") {
        return (a.pdpb || 0) - (b.pdpb || 0)
      } else if (sortBy === "PDPBDesc") {
        return (b.pdpb || 0) - (a.pdpb || 0)
      } else if (
        sortBy === "arriveAtOrderAsc" ||
        sortBy === "machineInputDateOrderAsc"
      ) {
        if (a.deliveries[0] && b.deliveries[0]) {
          return sortFnPrizeDeliveries(sortBy)(a.deliveries[0], b.deliveries[0])
        } else if (b.deliveries[0]) {
          return 1
        } else if (a.deliveries[0]) {
          return -1
        }
      }

      return a.prize.prizeName.localeCompare(b.prize.prizeName)
    }

    return calcPrizePlanSales(
      prizeDeliveries,
      prizeBoothSales,
      filterFn,
      sortFn,
    )
  }, [
    prizeDeliveries,
    prizeBoothSales,
    searchedPrizeBoothSales,
    prizeRank,
    sortBy,
  ])

  return <PrizePlansSalesTable sales={sales} />
}

interface PrizePlansSalesTableProps {
  sales: PrizePlanSales
}

const PrizePlansSalesTable: React.FC<PrizePlansSalesTableProps> = ({
  sales,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedPrize, setSelectedPrize] = useState<Prize>()
  const [selectedPrizeDelivery, setSelectedPrizeDelivery] =
    useState<PrizeDelivery>()
  const { isAdmin } = useUserRole()

  return (
    <Stack
      sx={{
        maxHeight: "calc(100dvh - 300px)",
      }}
    >
      <PaginatedTable
        scrollableY
        scrollableX
        stickyHeader
        noMargin
        items={sales.items}
        stateKey={"prizePlansSalesTable"}
        header={
          <TableHead sx={{ th: { whiteSpace: "nowrap" } }}>
            <TableRow>
              <ExtTableCell border sticky zIndex={100} fixedWidth={320}>
                景品名
              </ExtTableCell>
              <ExtTableCell fixedWidth={120}>売上実績</ExtTableCell>
              <ExtTableCell fixedWidth={120}>ブース数</ExtTableCell>
              <ExtTableCell>景品PDPB</ExtTableCell>
              <ExtTableCell fixedWidth={120}>P/O</ExtTableCell>
              <ExtTableCell fixedWidth={120}>景品代</ExtTableCell>
              <ExtTableCell fixedWidth={120}>対ブース平均</ExtTableCell>
              <ExtTableCell fixedWidth={120}>消化率</ExtTableCell>
              <ExtTableCell fixedWidth={120}>理論残在庫</ExtTableCell>
              <ExtTableCell fixedWidth={80}>ランク</ExtTableCell>
              <ExtTableCell fixedWidth={127}>着荷日</ExtTableCell>
              <ExtTableCell fixedWidth={127}>投入可能日</ExtTableCell>

              {isAdmin && (
                <>
                  <AdminExtTableCell header word="入庫数" rowSpan={2} />
                  <AdminExtTableCell header word="払出個数" rowSpan={2} />
                  <AdminExtTableCell header word="景品単価" rowSpan={2} />
                  <AdminExtTableCell header word="店舗全体PDPB" rowSpan={2} />
                </>
              )}
            </TableRow>
          </TableHead>
        }
        renderRow={(item, index) => {
          let alertMessage = null
          if (item.payoutRate && item.payoutRate <= 0.1) {
            alertMessage = "P/Oが低すぎます"
          } else if (item.payoutRate && item.payoutRate >= 0.4) {
            alertMessage = "P/Oが高すぎます"
          }

          return (
            <TableRow
              key={index}
              sx={{
                td: {
                  px: 1,
                },
              }}
            >
              <ExtTableCell border sticky zIndex={99}>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {alertMessage && (
                    <ErrorOutlineIcon
                      sx={(theme) => ({
                        color: theme.palette.error.main,
                        mr: 1,
                      })}
                    />
                  )}
                  <Link
                    underline="none"
                    sx={{ color: "primary.main", cursor: "pointer" }}
                    onClick={() => {
                      setSelectedPrize(item.prize)
                      if (item.deliveries[0])
                        setSelectedPrizeDelivery(item.deliveries[0].delivery)
                      setIsModalOpen(true)
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      color={alertMessage ? "error.main" : undefined}
                    >
                      {item.prize?.prizeName ?? ""}
                    </Typography>
                  </Link>
                </Stack>
                {alertMessage && (
                  <Typography variant="body2" color="error.main" pt={1}>
                    {alertMessage}
                  </Typography>
                )}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {item.sales?.toLocaleString() ?? "-"}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {item.count ?? "-"}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {item.pdpb?.toLocaleString() ?? "-"}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {getRatioLabel(item.payoutRate)}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {item.payoutPrice?.toLocaleString() ?? "-"}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {getRatioLabel(item.pdpbRatio)}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {getRatioLabel(item.consumptionRate)}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {item.logicalStock ?? "-"}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "center" }}>
                {item.prizeRank ?? "-"}
              </ExtTableCell>
              <ExtTableCell>
                {item.deliveries.map((d) => (
                  <Typography key={d.delivery.id} variant="body2">
                    {getJpDateLabel(d.delivery.arriveAt) ?? "-"}
                  </Typography>
                ))}
              </ExtTableCell>
              <ExtTableCell>
                {item.deliveries.map((d) => (
                  <Typography key={d.delivery.id} variant="body2">
                    {getJpDateLabel(d.delivery.machineInputDate) ?? "-"}
                  </Typography>
                ))}
              </ExtTableCell>

              {isAdmin && (
                <>
                  <AdminExtTableCell
                    sx={{ textAlign: "end" }}
                    word={item.stock?.toLocaleString() ?? "-"}
                  />
                  <AdminExtTableCell
                    sx={{ textAlign: "end" }}
                    word={item.payout?.toLocaleString() ?? "-"}
                  />
                  <AdminExtTableCell
                    sx={{ textAlign: "end" }}
                    word={item.prize?.unitPriceJpy?.toLocaleString() ?? "-"}
                  />
                  <AdminExtTableCell
                    sx={{ textAlign: "end" }}
                    word={sales.total.pdpb?.toLocaleString() ?? "-"}
                  />
                </>
              )}
            </TableRow>
          )
        }}
      />
      {selectedPrize && (
        <PrizeDetailModal
          showModal={isModalOpen}
          onClose={() => {
            setIsModalOpen(false)
            setSelectedPrizeDelivery(undefined)
            setSelectedPrize(undefined)
          }}
          prize={selectedPrize}
          delivery={selectedPrizeDelivery}
        />
      )}
    </Stack>
  )
}
