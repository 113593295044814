import { useCallback, useState } from "react"

import { Print } from "@mui/icons-material"
import {
  Button,
  DialogContent,
  Divider,
  Link,
  Stack,
  Typography,
} from "@mui/material"
import dayjs from "dayjs"
import { useParams, useNavigate, Link as RouterLink } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { BackButton } from "src/components/atoms/BackButton"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { DatePicker } from "src/components/organisms/DatePicker"
import { PrizeDailyPlanFloorMapBox } from "src/components/organisms/prizes/PrizeDailyPlanFloorMapBox"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { PrizePlanFloorMapPoint } from "src/domains/prizes/floorMapRepository"
import { aggregateSales } from "src/domains/prizes/prizeSalesRepository"
import { usePrizeDailyPlanFloorMapPoints } from "src/hooks/usePrizeDailyPlanFloorMapPoints"
import { prizeDailyFloorMapDateLabelState } from "src/recoil"
import { getRatioLabel, roundNearest100 } from "src/utils"

export const PrizeDailySalesFloorMap: React.FC = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  const [datePickerDateLabel, setDatePickerDateLabel] = useRecoilState(
    prizeDailyFloorMapDateLabelState,
  )

  return (
    <MainContentLayout
      title="フロアマップ"
      renderFilter={() => (
        <Stack
          sx={(theme) => ({
            gap: 2,
            flexDirection: "row",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          })}
        >
          <Stack
            sx={(theme) => ({
              flex: 1,
              backgroundColor: theme.palette.background.paper,
            })}
          >
            <DatePicker
              value={datePickerDateLabel}
              onChange={setDatePickerDateLabel}
              hideTodayButton
            />
          </Stack>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<Print />}
            sx={{ whiteSpace: "nowrap" }}
            onClick={() =>
              navigate(
                `/arcades/${arcadeCd}/prizes/sales/daily/floorMap/printSettings`,
              )
            }
          >
            印刷設定
          </Button>
        </Stack>
      )}
      renderContent={() => <PrizeDailySalesFloorMapInner />}
    />
  )
}

const PrizeDailySalesFloorMapInner: React.FC = () => {
  const { arcadeCd } = useParams()
  const date = useRecoilValue(prizeDailyFloorMapDateLabelState)

  const { prizePlanFloorMapPoints } = usePrizeDailyPlanFloorMapPoints({
    arcadeCd,
    type: "sales",
    date,
  })

  const [selectedPoint, setSelectedPoint] = useState<PrizePlanFloorMapPoint>()

  const onClickPoint = useCallback((point: PrizePlanFloorMapPoint) => {
    if (point.plans.length === 0) return
    setSelectedPoint(point)
  }, [])

  return (
    <>
      {prizePlanFloorMapPoints && (
        <PrizeDailyPlanFloorMapBox
          prizePlanFloorMapPoints={prizePlanFloorMapPoints}
          onClickPoint={onClickPoint}
        />
      )}
      {selectedPoint && (
        <PrizeDailySalesFloorMapModal
          showModal={!!selectedPoint}
          selectedPoint={selectedPoint}
          onClose={() => setSelectedPoint(undefined)}
        />
      )}
    </>
  )
}

type PrizeDailySalesFloorMapModalProps = {
  showModal: boolean
  selectedPoint: PrizePlanFloorMapPoint
  onClose: () => void
}

export const PrizeDailySalesFloorMapModal: React.FC<
  PrizeDailySalesFloorMapModalProps
> = ({ showModal, selectedPoint, onClose }) => {
  const { arcadeCd } = useParams()
  const { prizeDailySales } = selectedPoint
  const { sales, payoutRate } = aggregateSales(prizeDailySales)

  return (
    <CustomDialog fullWidth maxWidth="sm" open={showModal} onClose={onClose}>
      <DialogContent>
        {/* TODO: このコンポーネントで定義されたスタイルとデザインが違う */}
        <DialogTitleWithClose onClose={onClose} sx={{ p: 0 }} />

        <Stack gap={3}>
          <Typography variant="h1">
            {prizeDailySales[0]?.booth.boothName}
          </Typography>

          {prizeDailySales.map((sale, i) => {
            const { plan } = sale
            return (
              <Stack key={plan?.id} gap={2}>
                <Stack direction="row">
                  <Stack flex={1}>
                    <Typography
                      variant="body2"
                      sx={(theme) => ({ color: theme.palette.gray[50] })}
                    >
                      景品名
                    </Typography>
                  </Stack>
                  <Stack flex={2}>
                    <Typography>{plan?.prize.prizeName ?? "-"}</Typography>
                  </Stack>
                </Stack>
                <Stack direction="row">
                  <Stack flex={1}>
                    <Typography
                      variant="body2"
                      sx={(theme) => ({ color: theme.palette.gray[50] })}
                    >
                      景品CD
                    </Typography>
                  </Stack>
                  <Stack flex={2}>
                    <Link
                      to={`/arcades/${arcadeCd}/prizes/${plan?.prize.prizeCd}?delivery=${sale?.delivery?.id}`}
                      component={RouterLink}
                      underline="none"
                      sx={(theme) => ({ color: theme.palette.primary.main })}
                    >
                      <Typography variant="subtitle1">
                        {plan?.prize.prizeCd ?? "-"}
                      </Typography>
                    </Link>
                  </Stack>
                </Stack>
                <Stack direction="row">
                  <Stack flex={1}>
                    <Typography
                      variant="body2"
                      sx={(theme) => ({ color: theme.palette.gray[50] })}
                    >
                      設定
                    </Typography>
                  </Stack>
                  <Stack flex={2}>
                    <Typography>
                      {plan && plan.setting !== "" ? plan.setting : "-"}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row">
                  <Stack flex={1}>
                    <Typography
                      variant="body2"
                      sx={(theme) => ({ color: theme.palette.gray[50] })}
                    >
                      単価
                    </Typography>
                  </Stack>
                  <Stack flex={2}>
                    <Typography>{plan?.prize.unitPriceJpy ?? "-"}</Typography>
                  </Stack>
                </Stack>
                <Stack direction="row">
                  <Stack flex={1}>
                    <Typography
                      variant="body2"
                      sx={(theme) => ({ color: theme.palette.gray[50] })}
                    >
                      1個獲得金額
                    </Typography>
                  </Stack>
                  <Stack flex={2}>
                    <Typography>
                      {sale?.monthlyPlan?.expectedCapturePrice
                        ? roundNearest100(
                            sale?.monthlyPlan?.expectedCapturePrice,
                          ).toLocaleString()
                        : "-"}
                      円
                    </Typography>
                  </Stack>
                </Stack>
                {prizeDailySales.length - 1 !== i && <Divider />}
              </Stack>
            )
          })}

          <Divider />

          <Stack direction="row">
            <Stack flex={1}>
              <Typography variant="subtitle1">
                {dayjs(prizeDailySales[0]?.recordedAt).format("D日")}
              </Typography>
            </Stack>

            <Stack flex={2} gap={3}>
              <Stack direction="row">
                <Stack flex={1}>
                  <Typography
                    variant="body2"
                    sx={(theme) => ({ color: theme.palette.gray[50] })}
                  >
                    売上
                  </Typography>
                </Stack>
                <Stack flex={1}>
                  <Typography>
                    {sales !== undefined ? `${sales.toLocaleString()}円` : "-"}
                  </Typography>
                </Stack>
              </Stack>

              <Stack direction="row">
                <Stack flex={1}>
                  <Typography
                    variant="body2"
                    sx={(theme) => ({ color: theme.palette.gray[50] })}
                  >
                    P/O
                  </Typography>
                </Stack>
                <Stack flex={1}>
                  <Typography>
                    {payoutRate ? `${getRatioLabel(payoutRate)}` : "-"}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <CustomDialogActions>
        <BackButton onClick={() => onClose()}>戻る</BackButton>
      </CustomDialogActions>
    </CustomDialog>
  )
}
