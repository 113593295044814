import { CssBaseline } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import * as Sentry from "@sentry/react"
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  useLocation,
} from "react-router-dom"
import { RecoilRoot, useRecoilValue } from "recoil"

import { CustomScrollRestoration } from "src/components/organisms/CustomScrollRestoration"
import { AppErrorBoundary } from "src/components/templates/AppErrorBoundary"
import { useUserRole } from "src/hooks/useUserRole"
import { Layout } from "src/Layout"
import { Maintenance } from "src/Maintenance"
import { AmMachineDetails } from "src/pages/AmMachineDetails"
import { AmMachines } from "src/pages/AmMachines"
import { Arcade } from "src/pages/Arcade"
import { ArcadeSearch } from "src/pages/ArcadeSearch"
import { HealthCheckSet, HealthCheckGet } from "src/pages/HealthCheck"
import { InventoryDifferenceApprovals } from "src/pages/inventory/history/InventoryDifferenceApprovals"
import { InventoryMaterialExecuteHistoriesCsv } from "src/pages/inventory/history/InventoryMaterialExecuteHistoriesCsv"
import { InventoryMaterialPlacementStatusHistoriesCsv } from "src/pages/inventory/history/InventoryMaterialPlacementStatusHistoriesCsv"
import { InventoryOutputHistoriesCsv } from "src/pages/inventory/history/InventoryOutputHistoriesCsv"
import { InventoryPrizeExecuteHistoriesCsv } from "src/pages/inventory/history/InventoryPrizeExecuteHistoriesCsv"
import { InventoryPlacementStatusHistoriesCsv } from "src/pages/inventory/history/InventoryPrizePlacementStatusHistoriesCsv"
import { InventoryTop } from "src/pages/inventory/InventoryTop"
import { InventoryMaterialExecuteDifferenceDetails } from "src/pages/inventory/materials/differences/InventoryMaterialExecuteDifferenceDetails"
import { InventoryMaterialExecuteDifferences } from "src/pages/inventory/materials/differences/InventoryMaterialExecuteDifferences"
import { InventoryMaterialExecuteInMachine } from "src/pages/inventory/materials/execute/InventoryMaterialExecuteInMachine"
import { InventoryMaterialExecuteInMachineAmMachineDetails } from "src/pages/inventory/materials/execute/InventoryMaterialExecuteInMachineAmMachineDetails"
import { InventoryMaterialExecuteInMachineAmMachines } from "src/pages/inventory/materials/execute/InventoryMaterialExecuteInMachineAmMachines"
import { InventoryMaterialExecuteInMachineMaterialMachineDetails } from "src/pages/inventory/materials/execute/InventoryMaterialExecuteInMachineMaterialMachineDetails"
import { InventoryMaterialExecuteStorageDetails } from "src/pages/inventory/materials/execute/InventoryMaterialExecuteStorageDetails"
import { InventoryMaterialExecuteStorages } from "src/pages/inventory/materials/execute/InventoryMaterialExecuteStorages"
import { InventoryMaterialGroupInMachine } from "src/pages/inventory/materials/groups/InventoryMaterialGroupInMachine"
import { InventoryMaterialGroupStorage } from "src/pages/inventory/materials/groups/InventoryMaterialGroupStorage"
import { InventoryMaterialConfirmation } from "src/pages/inventory/materials/InventoryMaterialConfirmation"
import { InventoryPrizeExecuteDifferenceDetails } from "src/pages/inventory/prizes/differences/InventoryPrizeExecuteDifferenceDetails"
import { InventoryPrizeExecuteDifferences } from "src/pages/inventory/prizes/differences/InventoryPrizeExecuteDifferences"
import { InventoryPrizeAddSearch } from "src/pages/inventory/prizes/execute/InventoryPrizeAddSearch"
import { InventoryPrizeExecuteInBooth } from "src/pages/inventory/prizes/execute/InventoryPrizeExecuteInBooth"
import { InventoryPrizeExecuteInBoothDetails } from "src/pages/inventory/prizes/execute/InventoryPrizeExecuteInBoothDetails"
import { InventoryPrizeExecuteInBoothFloorMap } from "src/pages/inventory/prizes/execute/InventoryPrizeExecuteInBoothFloorMap"
import { InventoryPrizeExecuteInBoothList } from "src/pages/inventory/prizes/execute/InventoryPrizeExecuteInBoothList"
import { InventoryPrizeExecuteOnBooth } from "src/pages/inventory/prizes/execute/InventoryPrizeExecuteOnBooth"
import { InventoryPrizeExecuteOnBoothDetails } from "src/pages/inventory/prizes/execute/InventoryPrizeExecuteOnBoothDetails"
import { InventoryPrizeExecuteOnBoothFloorMap } from "src/pages/inventory/prizes/execute/InventoryPrizeExecuteOnBoothFloorMap"
import { InventoryPrizeExecuteOnBoothList } from "src/pages/inventory/prizes/execute/InventoryPrizeExecuteOnBoothList"
import { InventoryPrizeExecuteStorageDetails } from "src/pages/inventory/prizes/execute/InventoryPrizeExecuteStorageDetails"
import { InventoryPrizeExecuteStorages } from "src/pages/inventory/prizes/execute/InventoryPrizeExecuteStorages"
import { InventoryPrizeGroupInBooth } from "src/pages/inventory/prizes/groups/InventoryPrizeGroupInBooth"
import { InventoryPrizeGroupOnBooth } from "src/pages/inventory/prizes/groups/InventoryPrizeGroupOnBooth"
import { InventoryPrizeGroupStorage } from "src/pages/inventory/prizes/groups/InventoryPrizeGroupStorage"
import { InventoryPrizeConfirmation } from "src/pages/inventory/prizes/InventoryPrizeConfirmation"
import { InventoryPrizeEstimationsAdjust } from "src/pages/inventory/prizes/InventoryPrizeEstimationsAdjust"
import { InventoryPrizeEstimationsVerify } from "src/pages/inventory/prizes/InventoryPrizeEstimationsVerify"
import { InventoryCsvOutput } from "src/pages/inventory/seams/InventoryCsvOutput"
import { InventoryPrizeCsvImport } from "src/pages/inventory/seams/InventoryPrizeCsvImport"
import { LastExecutionPeriods } from "src/pages/LastExecutionPeriods"
import { Login } from "src/pages/Login"
import { InventoryMaterialStockLedger } from "src/pages/materials/ledger/InventoryMaterialStockLedger"
import { InventoryMaterialStockLedgerMaterials } from "src/pages/materials/ledger/InventoryMaterialStockLedgerMaterials"
import { InventoryMaterialStockLedgerPlacements } from "src/pages/materials/ledger/InventoryMaterialStockLedgerPlacements"
import { MaterialTop } from "src/pages/materials/MaterialTop"
import { InventoryMaterialMoveFromMachine } from "src/pages/materials/move/InventoryMaterialMoveFromMachine"
import { InventoryMaterialTemporaryStorages } from "src/pages/materials/move/InventoryMaterialTemporaryStorages"
import { InventoryMaterialVerifyStockDetails } from "src/pages/materials/move/InventoryMaterialVerifyStockDetails"
import { InventoryMaterialVerifyStocks } from "src/pages/materials/move/InventoryMaterialVerifyStocks"
import { InventoryMaterialVerifyTemporaryDetails } from "src/pages/materials/move/InventoryMaterialVerifyTemporaryDetails"
import { InventoryMaterialPlacementInMachine } from "src/pages/materials/placements/InventoryMaterialPlacementInMachine"
import { InventoryMaterialPlacementInMachineMaterials } from "src/pages/materials/placements/InventoryMaterialPlacementInMachineMaterials"
import { InventoryMaterialPlacementMachineMaterialStock } from "src/pages/materials/placements/InventoryMaterialPlacementMachineMaterialStock"
import { InventoryMaterialPlacementMoveFromMultipleMaterials } from "src/pages/materials/placements/InventoryMaterialPlacementMoveFromMultipleMaterials"
import { InventoryMaterialPlacementMoveFromMultipleMaterialsInput } from "src/pages/materials/placements/InventoryMaterialPlacementMoveFromMultipleMaterialsInput"
import { InventoryMaterialPlacementMoveFromMultipleMaterialsShelves } from "src/pages/materials/placements/InventoryMaterialPlacementMoveFromMultipleMaterialsShelves"
import { InventoryMaterialPlacements } from "src/pages/materials/placements/InventoryMaterialPlacements"
import { InventoryMaterialPlacementStorage } from "src/pages/materials/placements/InventoryMaterialPlacementStorage"
import { InventoryMaterialPlacementStorageMaterials } from "src/pages/materials/placements/InventoryMaterialPlacementStorageMaterials"
import { InventoryMaterialPlacementStorageMaterialStock } from "src/pages/materials/placements/InventoryMaterialPlacementStorageMaterialStock"
import { InventoryMaterialAccept } from "src/pages/materials/register/InventoryMaterialAccept"
import { InventoryMaterialDetails } from "src/pages/materials/register/InventoryMaterialDetails"
import { InventoryMaterialOut } from "src/pages/materials/register/InventoryMaterialOut"
import { InventoryMaterialOutDetails } from "src/pages/materials/register/InventoryMaterialOutDetails"
import { InventoryMaterialSeamsIn } from "src/pages/materials/register/InventoryMaterialSeamsIn"
import { InventoryMaterialSeamsOut } from "src/pages/materials/register/InventoryMaterialSeamsOut"
import { InventoryMaterialSearch } from "src/pages/materials/register/InventoryMaterialSearch"
import { InventoryMaterialAddShelf } from "src/pages/materials/settings/InventoryMaterialAddShelf"
import { InventoryMaterialAmMachineDetails } from "src/pages/materials/settings/InventoryMaterialAmMachineDetails"
import { InventoryMaterialAmMachines } from "src/pages/materials/settings/InventoryMaterialAmMachines"
import { InventoryMaterialEditShelf } from "src/pages/materials/settings/InventoryMaterialEditShelf"
import { InventoryMaterialMachineDetails } from "src/pages/materials/settings/InventoryMaterialMachineDetails"
import { InventoryMaterialMachines } from "src/pages/materials/settings/InventoryMaterialMachines"
import { InventoryMaterialShelves } from "src/pages/materials/settings/InventoryMaterialShelves"
import { InventoryMaterialStorages } from "src/pages/materials/settings/InventoryMaterialStorages"
import { MyPage } from "src/pages/MyPage"
import { NewPassword } from "src/pages/NewPassword"
import { NotFound } from "src/pages/NotFound"
import { NoticeEdit } from "src/pages/NoticeEdit"
import { NoticeList } from "src/pages/NoticeList"
import { NoticeNew } from "src/pages/NoticeNew"
import { InventoryPrizeBatchCsvOutput } from "src/pages/prizes/batch/InventoryPrizeBatchCsvOutput"
import { InventoryPrizeBatchCsvUpdate } from "src/pages/prizes/batch/InventoryPrizeBatchCsvUpdate"
import { PrizePayoutCsvDownload } from "src/pages/prizes/batch/PrizePayoutCsvDownload"
import { PrizeMeterReadCsv } from "src/pages/prizes/meterRead/PrizeMeterReadCsv"
import { PrizeMeterReadEdit } from "src/pages/prizes/meterRead/PrizeMeterReadEdit"
import { PrizeMeterReadInit } from "src/pages/prizes/meterRead/PrizeMeterReadInit"
import { PrizeMeterReads } from "src/pages/prizes/meterRead/PrizeMeterReads"
import { PrizeMeterReadShow } from "src/pages/prizes/meterRead/PrizeMeterReadShow"
import { InventoryPrizeMoveFromBooth } from "src/pages/prizes/move/InventoryPrizeMoveFromBooth"
import { InventoryPrizeTemporaryStorages } from "src/pages/prizes/move/InventoryPrizeTemporaryStorages"
import { InventoryPrizeVerifyStockDetails } from "src/pages/prizes/move/InventoryPrizeVerifyStockDetails"
import { InventoryPrizeVerifyStocks } from "src/pages/prizes/move/InventoryPrizeVerifyStocks"
import { InventoryPrizeVerifyTemporaryDetails } from "src/pages/prizes/move/InventoryPrizeVerifyTemporaryDetails"
import { PrizeDailyChanges } from "src/pages/prizes/move/PrizeDailyChanges"
import { InventoryPrizePlacementBoothPrizeStock } from "src/pages/prizes/placements/InventoryPrizePlacementBoothPrizeStock"
import { InventoryPrizePlacementInBooth } from "src/pages/prizes/placements/InventoryPrizePlacementInBooth"
import { InventoryPrizePlacementInBoothPrizes } from "src/pages/prizes/placements/InventoryPrizePlacementInBoothPrizes"
import { InventoryPrizePlacementOnBooth } from "src/pages/prizes/placements/InventoryPrizePlacementOnBooth"
import { InventoryPrizePlacementOnBoothPrizes } from "src/pages/prizes/placements/InventoryPrizePlacementOnBoothPrizes"
import { InventoryPrizePlacements } from "src/pages/prizes/placements/InventoryPrizePlacements"
import { InventoryPrizePlacementStorage } from "src/pages/prizes/placements/InventoryPrizePlacementStorage"
import { InventoryPrizePlacementStoragePrizes } from "src/pages/prizes/placements/InventoryPrizePlacementStoragePrizes"
import { InventoryPrizePlacementStoragePrizeStock } from "src/pages/prizes/placements/InventoryPrizePlacementStoragePrizeStock"
import { PrizeDaily } from "src/pages/prizes/plans/PrizeDaily"
import { PrizeDailyBooth } from "src/pages/prizes/plans/PrizeDailyBooth"
import { PrizeDailyBulkInput } from "src/pages/prizes/plans/PrizeDailyBulkInput"
import { PrizeDailyEdit } from "src/pages/prizes/plans/PrizeDailyEdit"
import { PrizeDailyPlansFloorMapPrint } from "src/pages/prizes/plans/PrizeDailyPlansFloorMapPrint"
import { PrizeDailyPlansFloorMapPrintSettings } from "src/pages/prizes/plans/PrizeDailyPlansFloorMapPrintSettings"
import { PrizeDailyPlansFloorMapPrintSettingsAdd } from "src/pages/prizes/plans/PrizeDailyPlansFloorMapPrintSettingsAdd"
import { PrizeMonthly } from "src/pages/prizes/plans/PrizeMonthly"
import { PrizeMonthlyEdit } from "src/pages/prizes/plans/PrizeMonthlyEdit"
import { PrizeMonthlyNew } from "src/pages/prizes/plans/PrizeMonthlyNew"
import { PrizeRecommendation } from "src/pages/prizes/plans/PrizeRecommendation"
import { PrizeRecommendationEdit } from "src/pages/prizes/plans/PrizeRecommendationEdit"
import { PrizeShow } from "src/pages/prizes/PrizeShow"
import { PrizeTop } from "src/pages/prizes/PrizeTop"
import { InventoryPrizeAccept } from "src/pages/prizes/register/InventoryPrizeAccept"
import { InventoryPrizeDetails } from "src/pages/prizes/register/InventoryPrizeDetails"
import { InventoryPrizeOut } from "src/pages/prizes/register/InventoryPrizeOut"
import { InventoryPrizeOutDetails } from "src/pages/prizes/register/InventoryPrizeOutDetails"
import { InventoryPrizeSeamsIn } from "src/pages/prizes/register/InventoryPrizeSeamsIn"
import { InventoryPrizeSeamsOut } from "src/pages/prizes/register/InventoryPrizeSeamsOut"
import { InventoryPrizeSearch } from "src/pages/prizes/register/InventoryPrizeSearch"
import { PrizeDeliveries } from "src/pages/prizes/register/PrizeDeliveries"
import { PrizeNewArrivals } from "src/pages/prizes/register/PrizeNewArrivals"
import { BoothDetails } from "src/pages/prizes/sales/BoothDetails"
import { Booths } from "src/pages/prizes/sales/Booths"
import { FloorMap } from "src/pages/prizes/sales/FloorMap"
import { PrizeDailySales } from "src/pages/prizes/sales/PrizeDailySales"
import { PrizeDailySalesFloorMap } from "src/pages/prizes/sales/PrizeDailySalesFloorMap"
import { PrizeDailySalesFloorMapPrint } from "src/pages/prizes/sales/PrizeDailySalesFloorMapPrint"
import { PrizeDailySalesFloorMapPrintSettings } from "src/pages/prizes/sales/PrizeDailySalesFloorMapPrintSettings"
import { PrizeDailySalesFloorMapPrintSettingsAdd } from "src/pages/prizes/sales/PrizeDailySalesFloorMapPrintSettingsAdd"
import { PrizePlansSales } from "src/pages/prizes/sales/PrizePlansSales"
import { PrizeRankSales } from "src/pages/prizes/sales/PrizeRankSales"
import { PrizeWeeklyArcadeSales } from "src/pages/prizes/sales/PrizeWeeklyArcadeSales"
import { PrizeWeeklyBoothSales } from "src/pages/prizes/sales/PrizeWeeklyBoothSales"
import { PrizeWeeklyBoothSalesDetails } from "src/pages/prizes/sales/PrizeWeeklyBoothSalesDetails"
import { PrizeWeeklyIpSales } from "src/pages/prizes/sales/PrizeWeeklyIpSales"
import { PrizesSearch } from "src/pages/prizes/search/PrizesSearch"
import { StockDetails } from "src/pages/prizes/search/StockDetails"
import { Stocks } from "src/pages/prizes/search/Stocks"
import { TonePrizeChanges } from "src/pages/prizes/search/TonePrizeChanges"
import { InventoryPrizeAddShelf } from "src/pages/prizes/settings/InventoryPrizeAddShelf"
import { InventoryPrizeEditShelf } from "src/pages/prizes/settings/InventoryPrizeEditShelf"
import { InventoryPrizeShelves } from "src/pages/prizes/settings/InventoryPrizeShelves"
import { InventoryPrizeStorages } from "src/pages/prizes/settings/InventoryPrizeStorages"
import { MachineCategories } from "src/pages/prizes/settings/MachineCategories"
import { Machines } from "src/pages/prizes/settings/Machines"
import { PrizeBoothCategories } from "src/pages/prizes/settings/PrizeBoothCategories"
import { PrizeBoothDetails } from "src/pages/prizes/settings/PrizeBoothDetails"
import { PrizeBooths } from "src/pages/prizes/settings/PrizeBooths"
import { PrizeFees } from "src/pages/prizes/settings/PrizeFees"
import { PrizeFieldSettings } from "src/pages/prizes/settings/PrizeFieldSettings"
import { QrCodeReader } from "src/pages/QrCodeReader"
import { UserNoticeList } from "src/pages/UserNoticeList"
import { Users } from "src/pages/users/Users"
import { UsersArcadeDetails } from "src/pages/users/UsersArcadeDetails"
import { isLoginState, meState } from "src/recoil"
import { ArcadeProvider } from "src/recoil/ArcadeProvider"
import { AuthProvider } from "src/recoil/AuthProvider"
import { VersionProvider } from "src/recoil/VersionProvider"
import { theme } from "src/theme"
import { dynamicImportWithRetry } from "src/utils/import"

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const Router = () => {
  const isLogin = useRecoilValue(isLoginState)
  const me = useRecoilValue(meState)
  const {
    isViewableManagementMenu,
    isViewableArcadeSearch,
    isViewableLastExecutionPeriods,
    isAdmin,
  } = useUserRole()

  const paths = (
    <>
      <Route
        path="/"
        element={
          isLogin ? (
            <Navigate to="/my-page" replace />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/new_password" element={<NewPassword />} />
      <Route path="/health/set" element={<HealthCheckSet />} />
      <Route path="/health/get" element={<HealthCheckGet />} />

      {/* NOTE: ログイン後のみ閲覧可能なページ */}
      <Route element={isLogin ? <Layout /> : <RedirectToLogin />}>
        <Route element={<VersionProvider />}>
          <Route path="/my-page" element={<MyPage />} />
          <Route path="/qr" element={<QrCodeReader />} />
          <Route path="/notices">
            <Route index element={<NoticeList />} />
            <Route
              path="new"
              element={
                isAdmin ? <NoticeNew /> : <Navigate to="/my-page" replace />
              }
            />
            <Route
              path=":noticeId/edit"
              element={
                isAdmin ? <NoticeEdit /> : <Navigate to="/my-page" replace />
              }
            />
          </Route>

          <Route path="/arcades">
            <Route path=":arcadeCd" element={<ArcadeProvider />}>
              <Route index element={<Arcade />} />
              <Route path="notices">
                <Route index element={<UserNoticeList />} />
              </Route>

              <Route path="users" element={<UsersArcadeDetails />} />

              <Route path="amMachines">
                <Route index element={<AmMachines />} />
                <Route path=":amMachineNumber" element={<AmMachineDetails />} />
              </Route>

              <Route path="prizes">
                <Route index element={<PrizeTop />} />
                <Route path=":prizeCd" element={<PrizeShow />} />

                <Route path="register">
                  <Route path="toneArrivals" element={<PrizeNewArrivals />} />
                  <Route path="deliveries" element={<PrizeDeliveries />} />
                  <Route path="accept">
                    <Route index element={<InventoryPrizeAccept />} />
                    <Route
                      path="details/:date/:prizeCd"
                      element={<InventoryPrizeDetails />}
                    />
                    <Route path="search" element={<InventoryPrizeSearch />} />
                  </Route>
                  <Route path="out">
                    <Route index element={<InventoryPrizeOut />} />
                    <Route
                      path="details/:prizeCd"
                      element={<InventoryPrizeOutDetails />}
                    />
                  </Route>
                  <Route path="histories">
                    <Route path="in" element={<InventoryPrizeSeamsIn />} />
                    <Route path="out" element={<InventoryPrizeSeamsOut />} />
                  </Route>
                </Route>

                <Route path="search">
                  <Route index element={<PrizesSearch />} />
                  <Route path="toneStocks">
                    <Route index element={<Stocks />} />
                    <Route path=":prizeCd" element={<StockDetails />} />
                  </Route>

                  <Route path="toneChanges">
                    <Route index element={<TonePrizeChanges />} />
                  </Route>
                </Route>

                <Route path="move">
                  <Route path="prizeChanges">
                    <Route index element={<PrizeDailyChanges />} />
                  </Route>
                  <Route path="temporary">
                    <Route
                      index
                      element={<InventoryPrizeTemporaryStorages />}
                    />
                    <Route
                      path="prizes/:prizeCd"
                      element={<InventoryPrizeVerifyTemporaryDetails />}
                    />
                  </Route>
                  <Route path="stocks">
                    <Route index element={<InventoryPrizeVerifyStocks />} />
                    <Route
                      path="prizes/:prizeCd"
                      element={<InventoryPrizeVerifyStockDetails />}
                    />
                  </Route>
                  <Route
                    path="booths"
                    element={<InventoryPrizeMoveFromBooth />}
                  />
                </Route>

                <Route path="meterRead">
                  <Route index element={<PrizeMeterReads />} />
                  <Route
                    path="show/:boothNameWithoutSeatNumber"
                    element={<PrizeMeterReadShow />}
                  />
                  <Route
                    path="edit/:boothName"
                    element={<PrizeMeterReadEdit />}
                  />
                  <Route path="csv" element={<PrizeMeterReadCsv />} />
                  <Route path="init" element={<PrizeMeterReadInit />} />
                </Route>

                <Route path="sales">
                  <Route path="arcade" element={<PrizeWeeklyArcadeSales />} />
                  <Route path="plans" element={<PrizePlansSales />} />
                  <Route path="booth">
                    <Route index element={<PrizeWeeklyBoothSales />} />
                    <Route
                      path=":boothName"
                      element={<PrizeWeeklyBoothSalesDetails />}
                    />
                  </Route>
                  <Route path="ip" element={<PrizeWeeklyIpSales />} />
                  <Route path="rank" element={<PrizeRankSales />} />
                  <Route path="daily">
                    <Route index element={<PrizeDailySales />} />
                    <Route path="floorMap">
                      <Route index element={<PrizeDailySalesFloorMap />} />
                      <Route
                        path="print"
                        element={<PrizeDailySalesFloorMapPrint />}
                      />
                      <Route path="printSettings">
                        <Route
                          index
                          element={<PrizeDailySalesFloorMapPrintSettings />}
                        />
                        <Route
                          path="add"
                          element={<PrizeDailySalesFloorMapPrintSettingsAdd />}
                        />
                      </Route>
                    </Route>
                  </Route>
                  <Route path="toneFloorMap">
                    <Route index element={<FloorMap />} />
                    <Route path="booths">
                      <Route index element={<Booths />} />
                      <Route path="details" element={<BoothDetails />} />
                    </Route>
                  </Route>
                </Route>

                <Route path="plans">
                  <Route path="monthly">
                    <Route index element={<PrizeMonthly />} />
                    <Route
                      path=":prizeDeliveryId/new"
                      element={<PrizeMonthlyNew />}
                    />
                    <Route
                      path=":prizeDeliveryId/:prizeMonthlyPlanId"
                      element={<PrizeMonthlyEdit />}
                    />
                  </Route>
                  <Route path="daily">
                    <Route index element={<PrizeDaily />} />
                    <Route
                      path="booth/:boothName"
                      element={<PrizeDailyBooth />}
                    />
                    <Route path="edit" element={<PrizeDailyEdit />} />
                    <Route path="bulk" element={<PrizeDailyBulkInput />} />
                    <Route path="floorMap">
                      <Route
                        path="print"
                        element={<PrizeDailyPlansFloorMapPrint />}
                      />
                      <Route path="printSettings">
                        <Route
                          index
                          element={<PrizeDailyPlansFloorMapPrintSettings />}
                        />
                        <Route
                          path="add"
                          element={<PrizeDailyPlansFloorMapPrintSettingsAdd />}
                        />
                      </Route>
                    </Route>
                    <Route path="recommendation">
                      <Route index element={<PrizeRecommendation />} />
                      <Route
                        path=":prizeCd/edit/:machineName"
                        element={<PrizeRecommendationEdit />}
                      />
                    </Route>
                  </Route>
                </Route>

                <Route path="settings">
                  <Route path="storages" element={<InventoryPrizeStorages />} />
                  <Route path="storages">
                    <Route path=":storageId/shelves">
                      <Route index element={<InventoryPrizeShelves />} />
                      <Route path="new" element={<InventoryPrizeAddShelf />} />
                      <Route
                        path=":shelfId"
                        element={<InventoryPrizeEditShelf />}
                      />
                    </Route>
                  </Route>

                  <Route path="booths" element={<PrizeBooths />} />
                  <Route
                    path="booths/:boothName"
                    element={<PrizeBoothDetails />}
                  />
                  <Route
                    path="boothCategories"
                    element={<PrizeBoothCategories />}
                  />
                </Route>

                <Route path="placements">
                  <Route index element={<InventoryPrizePlacements />} />
                  <Route path="storage/:storageId/:shelfId">
                    <Route index element={<InventoryPrizePlacementStorage />} />
                    <Route path="prizes">
                      <Route
                        index
                        element={<InventoryPrizePlacementStoragePrizes />}
                      />
                      <Route
                        path=":prizeCd"
                        element={<InventoryPrizePlacementStoragePrizeStock />}
                      />
                    </Route>
                  </Route>
                  <Route path="inBooth/:inventoryFloorMapPointId">
                    <Route index element={<InventoryPrizePlacementInBooth />} />
                    <Route path="prizes">
                      <Route
                        index
                        element={<InventoryPrizePlacementInBoothPrizes />}
                      />
                      <Route
                        path=":prizeCd"
                        element={<InventoryPrizePlacementBoothPrizeStock />}
                      />
                    </Route>
                  </Route>
                  <Route path="onBooth/:inventoryFloorMapPointId">
                    <Route index element={<InventoryPrizePlacementOnBooth />} />
                    <Route path="prizes">
                      <Route
                        index
                        element={<InventoryPrizePlacementOnBoothPrizes />}
                      />
                      <Route
                        path=":prizeCd"
                        element={<InventoryPrizePlacementBoothPrizeStock />}
                      />
                    </Route>
                  </Route>
                </Route>

                <Route path="batch">
                  <Route path="stocks">
                    <Route
                      path="output"
                      element={<InventoryPrizeBatchCsvOutput />}
                    />
                    <Route
                      path="import"
                      element={<InventoryPrizeBatchCsvUpdate />}
                    />
                  </Route>
                  <Route path="payouts">
                    <Route path="output" element={<PrizePayoutCsvDownload />} />
                  </Route>
                </Route>
              </Route>

              <Route path="materials">
                <Route index element={<MaterialTop />} />

                <Route path="register">
                  <Route path="accept">
                    <Route index element={<InventoryMaterialAccept />} />
                    <Route
                      path="details/:date/:materialCd"
                      element={<InventoryMaterialDetails />}
                    />
                    <Route
                      path="search"
                      element={
                        isAdmin ? (
                          <InventoryMaterialSearch />
                        ) : (
                          <Navigate to="/my-page" replace />
                        )
                      }
                    />
                  </Route>
                  <Route path="out">
                    <Route index element={<InventoryMaterialOut />} />
                    <Route
                      path="details/:materialCd"
                      element={<InventoryMaterialOutDetails />}
                    />
                  </Route>
                  <Route path="histories">
                    <Route path="in" element={<InventoryMaterialSeamsIn />} />
                    <Route path="out" element={<InventoryMaterialSeamsOut />} />
                  </Route>
                </Route>
                <Route path="move">
                  <Route path="temporary">
                    <Route
                      index
                      element={<InventoryMaterialTemporaryStorages />}
                    />
                    <Route
                      path="materials/:materialCd"
                      element={<InventoryMaterialVerifyTemporaryDetails />}
                    />
                  </Route>
                  <Route path="stocks">
                    <Route index element={<InventoryMaterialVerifyStocks />} />
                    <Route
                      path="materials/:materialCd"
                      element={<InventoryMaterialVerifyStockDetails />}
                    />
                  </Route>
                  <Route
                    path="machines"
                    element={<InventoryMaterialMoveFromMachine />}
                  />
                </Route>

                <Route path="placements">
                  <Route index element={<InventoryMaterialPlacements />} />
                  <Route path="storage/:storageId/:shelfId">
                    <Route
                      index
                      element={<InventoryMaterialPlacementStorage />}
                    />
                    <Route path="materials">
                      <Route
                        index
                        element={<InventoryMaterialPlacementStorageMaterials />}
                      />
                      <Route path=":materialCd">
                        <Route
                          index
                          element={
                            <InventoryMaterialPlacementStorageMaterialStock />
                          }
                        />
                      </Route>
                    </Route>
                  </Route>
                  <Route path="machine">
                    <Route path="materials/multiple">
                      <Route
                        index
                        element={
                          <InventoryMaterialPlacementMoveFromMultipleMaterials />
                        }
                      />
                      <Route path=":materialCd">
                        <Route
                          index
                          element={
                            <InventoryMaterialPlacementMoveFromMultipleMaterialsShelves />
                          }
                        />
                        <Route
                          path=":storageId/:shelfId"
                          element={
                            <InventoryMaterialPlacementMoveFromMultipleMaterialsInput />
                          }
                        ></Route>
                      </Route>
                    </Route>

                    <Route path=":materialMachineId">
                      <Route
                        index
                        element={<InventoryMaterialPlacementInMachine />}
                      />
                      <Route path="materials">
                        <Route
                          index
                          element={
                            <InventoryMaterialPlacementInMachineMaterials />
                          }
                        />
                        <Route
                          path=":materialCd"
                          element={
                            <InventoryMaterialPlacementMachineMaterialStock />
                          }
                        />
                      </Route>
                    </Route>
                  </Route>
                </Route>

                <Route path="settings">
                  <Route
                    path="storages"
                    element={<InventoryMaterialStorages />}
                  />
                  <Route path="storages">
                    <Route path=":storageId/shelves">
                      <Route index element={<InventoryMaterialShelves />} />
                      <Route
                        path="new"
                        element={<InventoryMaterialAddShelf />}
                      />
                      <Route
                        path=":shelfId"
                        element={<InventoryMaterialEditShelf />}
                      />
                    </Route>
                  </Route>
                  <Route path="amMachines">
                    <Route index element={<InventoryMaterialAmMachines />} />
                    <Route
                      path=":amMachineNumber"
                      element={<InventoryMaterialAmMachineDetails />}
                    />
                  </Route>
                  <Route path="machines">
                    <Route index element={<InventoryMaterialMachines />} />
                    <Route
                      path=":materialMachineId"
                      element={<InventoryMaterialMachineDetails />}
                    />
                  </Route>
                </Route>

                <Route path="ledger">
                  <Route path="materials">
                    <Route
                      index
                      element={<InventoryMaterialStockLedgerMaterials />}
                    />
                    <Route path=":materialCd/placements">
                      <Route
                        index
                        element={<InventoryMaterialStockLedgerPlacements />}
                      />
                      <Route
                        path=":placementId"
                        element={<InventoryMaterialStockLedger />}
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>

              <Route path="inventory">
                <Route index element={<InventoryTop />} />
                <Route path="seams">
                  <Route path="import" element={<InventoryPrizeCsvImport />} />
                  <Route path="output" element={<InventoryCsvOutput />} />
                </Route>
                <Route path="prizes">
                  <Route path="differences">
                    <Route
                      index
                      element={<InventoryPrizeExecuteDifferences />}
                    />
                    <Route
                      path=":prizeCd"
                      element={<InventoryPrizeExecuteDifferenceDetails />}
                    />
                  </Route>
                  <Route
                    path="confirmation"
                    element={<InventoryPrizeConfirmation />}
                  />
                  <Route path="estimations">
                    <Route
                      path="verify"
                      element={<InventoryPrizeEstimationsVerify />}
                    />
                    <Route
                      path="adjust"
                      element={<InventoryPrizeEstimationsAdjust />}
                    />
                  </Route>

                  <Route path="execute">
                    <Route path="storage">
                      <Route
                        index
                        element={<InventoryPrizeExecuteStorages />}
                      />
                      <Route
                        path=":storageId/:shelfId"
                        element={<InventoryPrizeExecuteStorageDetails />}
                      />
                      <Route
                        path="add"
                        element={
                          <InventoryPrizeAddSearch placementType="storage" />
                        }
                      />
                    </Route>
                    <Route path="onBooth">
                      <Route index element={<InventoryPrizeExecuteOnBooth />} />
                      <Route
                        path=":inventoryFloorMapPointId"
                        element={<InventoryPrizeExecuteOnBoothDetails />}
                      />
                      <Route
                        path="floorMap"
                        element={<InventoryPrizeExecuteOnBoothFloorMap />}
                      />
                      <Route
                        path="list"
                        element={<InventoryPrizeExecuteOnBoothList />}
                      />
                      <Route
                        path="add"
                        element={
                          <InventoryPrizeAddSearch placementType="on_booth" />
                        }
                      />
                    </Route>
                    <Route path="inBooth">
                      <Route index element={<InventoryPrizeExecuteInBooth />} />
                      <Route
                        path=":inventoryFloorMapPointId"
                        element={<InventoryPrizeExecuteInBoothDetails />}
                      />
                      <Route
                        path="floorMap"
                        element={<InventoryPrizeExecuteInBoothFloorMap />}
                      />
                      <Route
                        path="list"
                        element={<InventoryPrizeExecuteInBoothList />}
                      />
                      <Route
                        path="add"
                        element={
                          <InventoryPrizeAddSearch placementType="in_booth" />
                        }
                      />
                    </Route>
                  </Route>

                  <Route path="groups">
                    <Route
                      path="storage"
                      element={<InventoryPrizeGroupStorage />}
                    />
                    <Route
                      path="inBooth"
                      element={<InventoryPrizeGroupInBooth />}
                    />
                    <Route
                      path="onBooth"
                      element={<InventoryPrizeGroupOnBooth />}
                    />
                  </Route>
                </Route>

                <Route path="materials">
                  <Route path="differences">
                    <Route
                      index
                      element={<InventoryMaterialExecuteDifferences />}
                    />
                    <Route
                      path=":materialCd"
                      element={<InventoryMaterialExecuteDifferenceDetails />}
                    />
                  </Route>
                  <Route
                    path="confirmation"
                    element={<InventoryMaterialConfirmation />}
                  />

                  <Route path="execute">
                    <Route path="storage">
                      <Route
                        index
                        element={<InventoryMaterialExecuteStorages />}
                      />
                      <Route
                        path=":storageId/:shelfId"
                        element={<InventoryMaterialExecuteStorageDetails />}
                      />
                    </Route>
                    <Route path="machine">
                      <Route
                        index
                        element={<InventoryMaterialExecuteInMachine />}
                      />
                      <Route path="amMachines">
                        <Route
                          index
                          element={
                            <InventoryMaterialExecuteInMachineAmMachines />
                          }
                        />
                        <Route path=":amMachineNumber">
                          <Route
                            index
                            element={
                              <InventoryMaterialExecuteInMachineAmMachineDetails />
                            }
                          />
                          <Route
                            path=":materialMachineId"
                            element={
                              <InventoryMaterialExecuteInMachineMaterialMachineDetails />
                            }
                          />
                        </Route>
                      </Route>
                    </Route>
                  </Route>

                  <Route path="groups">
                    <Route
                      path="storage"
                      element={<InventoryMaterialGroupStorage />}
                    />
                    <Route
                      path="machine"
                      element={<InventoryMaterialGroupInMachine />}
                    />
                  </Route>
                </Route>

                <Route path="history">
                  <Route
                    path="output"
                    element={<InventoryOutputHistoriesCsv />}
                  />
                  <Route
                    path="approvals"
                    element={<InventoryDifferenceApprovals />}
                  />
                  <Route path="prizes">
                    <Route
                      path="placement-statuses"
                      element={<InventoryPlacementStatusHistoriesCsv />}
                    />
                    <Route
                      path="execute"
                      element={<InventoryPrizeExecuteHistoriesCsv />}
                    />
                  </Route>
                  <Route path="materials">
                    <Route
                      path="placement-statuses"
                      element={<InventoryMaterialPlacementStatusHistoriesCsv />}
                    />
                    <Route
                      path="execute"
                      element={<InventoryMaterialExecuteHistoriesCsv />}
                    />
                  </Route>
                </Route>

                {/* QRコード互換性維持 */}
                <Route path="placements">
                  <Route
                    path="storage/:shelfId"
                    element={<InventoryPrizePlacementStorage />}
                  />
                  <Route
                    path="in-booth/:inventoryFloorMapPointId"
                    element={<InventoryPrizePlacementInBooth />}
                  />
                  <Route
                    path="on-booth/:inventoryFloorMapPointId"
                    element={<InventoryPrizePlacementOnBooth />}
                  />
                </Route>
                <Route path="materials/placements">
                  <Route
                    path="storage/:storageId/:shelfId"
                    element={<InventoryMaterialPlacementStorage />}
                  />
                  <Route
                    path="in-machine/:materialMachineId"
                    element={<InventoryMaterialPlacementInMachine />}
                  />
                </Route>
              </Route>

              {/*
              <Route
                path="*"
                element={null}
                lazy={() =>
                  dynamicImportWithRetry(
                    () => import("./routes/inventory/prizes"),
                  )
                }
              />

              <Route path="materials">
                <Route
                  path="*"
                  element={null}
                  lazy={() =>
                    dynamicImportWithRetry(
                      () => import("./routes/inventory/materials"),
                    )
                  }
                />
              </Route>
              */}
            </Route>
          </Route>

          {/* NOTE: 店舗検索表示対象ユーザーのみ閲覧可能なページ */}
          <Route
            element={
              !me || isViewableArcadeSearch ? (
                <Outlet />
              ) : (
                <Navigate to="/my-page" replace />
              )
            }
          >
            <Route path="/arcades/search" element={<ArcadeSearch />} />
          </Route>

          {/* NOTE: 管理メニュー表示対象ユーザーのみ閲覧可能なページ */}
          <Route
            element={
              !me || isViewableManagementMenu ? (
                <Outlet />
              ) : (
                <Navigate to="/my-page" replace />
              )
            }
          >
            <Route path="/users">
              <Route index element={<Users />} />
              <Route
                path="*"
                element={null}
                lazy={() =>
                  dynamicImportWithRetry(() => import("./routes/users"))
                }
              />
            </Route>

            <Route
              element={
                !me || isViewableLastExecutionPeriods ? (
                  <Outlet />
                ) : (
                  <Navigate to="/my-page" replace />
                )
              }
            >
              <Route
                path="/lastExecutionPeriods"
                element={<LastExecutionPeriods />}
              />
            </Route>
          </Route>
          <Route path="/settings">
            <Route path="prizes">
              <Route path="fees" element={<PrizeFees />} />
              <Route path="machines" element={<Machines />} />
              <Route path="machineCategories" element={<MachineCategories />} />
              <Route path="fieldSettings" element={<PrizeFieldSettings />} />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<NotFound />} />
    </>
  )

  const routes = (
    <Route errorElement={<AppErrorBoundary />}>
      <Route element={<CustomScrollRestoration />}>
        <Route element={<AuthProvider />}>{paths}</Route>
      </Route>
    </Route>
  )

  const router = sentryCreateBrowserRouter(createRoutesFromElements(routes))

  return <RouterProvider router={router} />
}

const RedirectToLogin: React.FC = () => {
  const location = useLocation()

  return <Navigate to="/login" state={{ from: location }} replace />
}

export const App: React.FC = () => {
  if (IS_MAINTENANCE)
    return (
      <ThemeProvider theme={theme}>
        <Maintenance />
      </ThemeProvider>
    )

  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router />
      </ThemeProvider>
    </RecoilRoot>
  )
}
