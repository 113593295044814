import { useCallback, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { LoadingButton } from "@mui/lab"
import {
  Button,
  DialogContent,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import * as Yup from "yup"

import { BackButton } from "src/components/atoms/BackButton"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { CustomDialogTitle } from "src/components/molecules/CustomDialogTitle"

const validationSchema = Yup.object({
  name: Yup.string().required("選択してください"),
})

const resolver = yupResolver(validationSchema)

type FormValues = Yup.InferType<typeof validationSchema>

export type OnSubmitFloorMapDeletePrintSetting = SubmitHandler<FormValues>

type Props = {
  open: boolean
  printSettingNames: string[]
  onClose?: () => void
  onSubmit?: OnSubmitFloorMapDeletePrintSetting
}

export const FloorMapDeletePrintSettingModal: React.FC<Props> = ({
  open,
  printSettingNames,
  onClose = () => {
    /* noop */
  },
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting, isDirty },
  } = useForm({
    resolver,
    defaultValues: { name: "" },
  })

  const name = watch("name")

  const [isConfirming, setIsConfirming] = useState(false)

  const _onSubmit: OnSubmitFloorMapDeletePrintSetting = useCallback(
    async (data) => {
      await onSubmit?.(data)
      onClose?.()
    },
    [onSubmit, onClose],
  )

  return (
    <CustomDialog
      PaperProps={{ component: "form" }}
      open={open}
      {...(isConfirming && { maxWidth: "xs" })}
      onSubmit={handleSubmit(_onSubmit)}
      onClose={onClose}
      TransitionProps={{
        onExited: () => {
          // モーダルを閉じたときに状態をリセットする
          setIsConfirming(false)
          reset()
        },
      }}
    >
      {isConfirming ? (
        <>
          <CustomDialogTitle>「{name}」を削除しますか？</CustomDialogTitle>
          <CustomDialogActions>
            <BackButton onClick={() => setIsConfirming(false)}>戻る</BackButton>
            <LoadingButton
              type="submit"
              variant="contained"
              color="error"
              fullWidth
              loading={isSubmitting}
            >
              削除する
            </LoadingButton>
          </CustomDialogActions>
        </>
      ) : (
        <>
          <CustomDialogTitle onClose={onClose}>
            削除したい印刷範囲の名称を選択してください。
          </CustomDialogTitle>
          <DialogContent>
            <Stack py={1}>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextField {...field} select fullWidth>
                    <MenuItem value="" sx={{ height: 36 }} />
                    {printSettingNames.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Stack>
          </DialogContent>
          <CustomDialogActions>
            <BackButton onClick={onClose}>戻る</BackButton>
            <Button
              variant="contained"
              color="error"
              fullWidth
              disabled={!isDirty || errors.name != null}
              onClick={() => setIsConfirming(true)}
            >
              削除する
            </Button>
          </CustomDialogActions>
        </>
      )}
    </CustomDialog>
  )
}
