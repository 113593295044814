import { atom, useRecoilState } from "recoil"

import { DatePicker } from "src/components/organisms/DatePicker"
import { DateLabelString } from "src/types"
import { getToday } from "src/utils"

export const prizeDailyPlansDatePickerState = atom<DateLabelString>({
  key: "prizeDailyFloorMapSearchParams",
  default: getToday(),
})

export const PrizeDailyPlansDatePicker: React.FC = () => {
  const [date, setDate] = useRecoilState(prizeDailyPlansDatePickerState)
  return (
    <DatePicker
      label="日付指定"
      value={date}
      onChange={setDate}
      hideTodayButton
    />
  )
}
