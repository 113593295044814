import { Prize } from "src/api/models"
import { compareByPhoneticOrder } from "src/utils"

export const prizesSearchSortByLabels = {
  phoneticOrderAsc: "あいうえお順",
  phoneticOrderDesc: "あいうえお順（降順）",
} as const

export type PrizesSearchSortBy = keyof typeof prizesSearchSortByLabels

export const sortPrizes = (prizes: Prize[], sortBy: PrizesSearchSortBy) => {
  const getSortCompareBy = () => {
    if (sortBy === "phoneticOrderAsc") {
      return (a: Prize, b: Prize) =>
        compareByPhoneticOrder(a.prizeName, b.prizeName)
    }
    if (sortBy === "phoneticOrderDesc") {
      return (a: Prize, b: Prize) =>
        compareByPhoneticOrder(b.prizeName, a.prizeName)
    }
    return () => 1
  }

  return prizes.sort(getSortCompareBy())
}
