import { useEffect, useMemo } from "react"

import { TableHead, TableRow, Checkbox } from "@mui/material"
import { useLocation } from "react-router"
import { useRecoilState } from "recoil"

import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  PaginatedTableInner,
  TableLimit,
} from "src/components/organisms/PaginatedTable"
import { paginatedTablesState } from "src/recoil"

export const SELECTABLE_PAGINATED_TABLE_CHECKBOX_COL_WIDTH = 42

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface SelectablePaginatedTableProps<T extends Record<string, any>> {
  items: T[]
  // itemが同一かどうかを判定する関数
  checkIsSameItem: (a: T, b: T) => boolean
  selectedItems: T[]
  disabledItems?: T[]
  onChangeSelectedItems: (selectedItems: T[]) => void
  renderRowCells: (item: T, index: number) => React.ReactElement
  endRows?: React.ReactElement
  defaultLimit?: TableLimit
  renderHeaderCells?: () => React.ReactElement
  scrollableX?: boolean
  scrollableY?: boolean
  stickyHeader?: boolean
  stateKey: string
  noMargin?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SelectablePaginatedTable = <T extends Record<string, any>>({
  items,
  checkIsSameItem,
  selectedItems,
  disabledItems,
  onChangeSelectedItems,
  defaultLimit = 50,
  renderRowCells,
  endRows,
  renderHeaderCells,
  scrollableX = false,
  scrollableY = false,
  stickyHeader = false,
  stateKey,
  noMargin = false,
}: SelectablePaginatedTableProps<T>) => {
  const { pathname } = useLocation()

  const [tablesState, setTablesState] = useRecoilState(paginatedTablesState)
  const recoilState = tablesState[pathname]?.[stateKey]
  const page = recoilState?.page

  const limitWithRecoil = recoilState?.limit ?? defaultLimit
  const setRecoilLimit = (limit: TableLimit) => {
    setTablesState({
      ...tablesState,
      [pathname]: {
        ...(tablesState[pathname] || {}),
        [stateKey]: { ...tablesState[pathname]?.[stateKey], page: 0, limit },
      },
    })
  }

  const handleLimitChange = (limit: TableLimit) => {
    setRecoilLimit(limit)
  }

  const safePage = useMemo(() => {
    if (!page) {
      return 0
    }
    if (items.length <= (page - 1) * limitWithRecoil) {
      return Math.floor(items.length / limitWithRecoil)
    }
    return page
  }, [page, items.length, limitWithRecoil])
  const pageSliceStart = limitWithRecoil * (safePage || 0)
  const pageSliceEnd = pageSliceStart + limitWithRecoil

  useEffect(() => {
    onChangeSelectedItems([])

    // page更新時にonChangeSelectedItemsが再計算されてしまうことがあるので依存から外す
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [safePage, limitWithRecoil])

  const slicedItems = useMemo(
    () => items.slice(pageSliceStart, pageSliceEnd),
    [items, pageSliceStart, pageSliceEnd],
  )

  const isAllChecked = useMemo(() => {
    return slicedItems.length === selectedItems.length
  }, [selectedItems.length, slicedItems.length])

  const isAllCheckDisabled = useMemo(() => {
    return slicedItems.every((item) =>
      disabledItems?.some((disabledItem) =>
        checkIsSameItem(disabledItem, item),
      ),
    )
  }, [checkIsSameItem, disabledItems, slicedItems])

  return (
    <PaginatedTableInner
      endRows={endRows}
      scrollableX={scrollableX}
      scrollableY={scrollableY}
      stickyHeader={stickyHeader}
      noMargin={noMargin}
      items={items}
      stateKey={stateKey}
      limit={limitWithRecoil}
      setLimit={handleLimitChange}
      header={
        <TableHead sx={{ whiteSpace: "nowrap" }}>
          <TableRow>
            <ExtTableCell
              padding="checkbox"
              sticky
              fixedWidth={SELECTABLE_PAGINATED_TABLE_CHECKBOX_COL_WIDTH}
              zIndex={4}
            >
              <Checkbox
                checked={isAllChecked}
                onChange={() =>
                  onChangeSelectedItems(isAllChecked ? [] : slicedItems)
                }
                disabled={isAllCheckDisabled}
              />
            </ExtTableCell>
            {renderHeaderCells && renderHeaderCells()}
          </TableRow>
        </TableHead>
      }
      renderRow={(rowItem, i) => {
        return (
          <TableRow key={i}>
            <ExtTableCell
              padding="checkbox"
              sticky
              fixedWidth={SELECTABLE_PAGINATED_TABLE_CHECKBOX_COL_WIDTH}
              zIndex={3}
            >
              <Checkbox
                checked={
                  selectedItems.findIndex((selectedItem) =>
                    checkIsSameItem(selectedItem, rowItem),
                  ) !== -1
                }
                onChange={() =>
                  onChangeSelectedItems(
                    selectedItems.findIndex((selectedItem) =>
                      checkIsSameItem(selectedItem, rowItem),
                    ) !== -1
                      ? selectedItems.filter(
                          (item) => !checkIsSameItem(rowItem, item),
                        )
                      : [...selectedItems, rowItem],
                  )
                }
                disabled={disabledItems?.some((item) =>
                  checkIsSameItem(item, rowItem),
                )}
              />
            </ExtTableCell>
            {renderRowCells(rowItem, i)}
          </TableRow>
        )
      }}
    />
  )
}
