import { useState } from "react"

import { Stack, Button } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import {
  getPrizeFloorMapPrintSettings,
  putPrizeFloorMapPrintSetting,
} from "src/api/prize-floor-map"
import {
  FloorMapAddPrintSettingModal,
  OnSubmitFloorMapAddPrintSetting,
} from "src/components/organisms/FloorMapAddPrintSettingModal"
import { PrizeDailyPlanFloorMapBox } from "src/components/organisms/prizes/PrizeDailyPlanFloorMapBox"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { PrizeDailyPlanFloorMapType } from "src/domains/prizes/floorMapRepository"
import { usePrizeDailyPlanFloorMapPoints } from "src/hooks/usePrizeDailyPlanFloorMapPoints"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { prizeDailyFloorMapDateLabelState } from "src/recoil"

const type: PrizeDailyPlanFloorMapType = "sales"
const listPageUrl = ({ arcadeCd }: { arcadeCd: string | undefined }) =>
  `/arcades/${arcadeCd}/prizes/${type}/daily/floorMap/printSettings`

export const PrizeDailySalesFloorMapPrintSettingsAdd: React.FC = () => {
  const navigate = useNavigate()

  const { arcadeCd } = useParams()
  const date = useRecoilValue(prizeDailyFloorMapDateLabelState)

  const { prizePlanFloorMapPoints } = usePrizeDailyPlanFloorMapPoints({
    arcadeCd,
    type,
    date,
  })

  const [selectedPointIds, setSelectedPointIds] = useState<number[]>([])

  const printSettingsReturn = useResource({
    subject: "印刷範囲一覧の取得",
    fetch: arcadeCd ? () => getPrizeFloorMapPrintSettings(arcadeCd) : undefined,
    recoilKey: `getPrizeFloorMapPrintSettings:${arcadeCd}`,
  })
  const printSettings = printSettingsReturn.resource?.data.settings
  const refetchPrintSettings = printSettingsReturn.refetch

  const { submitPromises } = useSubmitting()

  const onSubmit: OnSubmitFloorMapAddPrintSetting = async ({ name }) => {
    if (!arcadeCd || !prizePlanFloorMapPoints) {
      return
    }

    const boothNames = selectedPointIds
      .map((id) => {
        const point = prizePlanFloorMapPoints.find((p) => p.id === id)
        return point?.name
      })
      .filter((name) => name != null)

    await submitPromises([
      {
        subject: "フロアマップ印刷設定の保存",
        showSuccessMessage: true,
        promise: async () => {
          await putPrizeFloorMapPrintSetting(arcadeCd, { name, boothNames })
          await refetchPrintSettings()
        },
      },
    ])

    navigate(listPageUrl({ arcadeCd }))
  }

  const [addModalOpen, setAddModalOpen] = useState(false)

  return (
    <MainContentLayout
      title="フロアマップの印刷設定 印刷範囲の追加 ※PC推奨"
      renderContent={() => {
        if (!prizePlanFloorMapPoints) return null

        return (
          <>
            <Stack gap={3}>
              <PrizeDailyPlanFloorMapBox
                prizePlanFloorMapPoints={prizePlanFloorMapPoints}
                selectedPointIds={selectedPointIds}
                onSelectPoints={setSelectedPointIds}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={selectedPointIds.length === 0}
                onClick={() => setAddModalOpen(true)}
              >
                印刷範囲を保存する
              </Button>
            </Stack>
            <FloorMapAddPrintSettingModal
              open={addModalOpen}
              printSettingNames={printSettings?.map((setting) => setting.name)}
              onClose={() => setAddModalOpen(false)}
              onSubmit={onSubmit}
            />
          </>
        )
      }}
    />
  )
}
