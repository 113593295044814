import { useMemo, useState } from "react"

import {
  Typography,
  Stack,
  Card,
  Switch,
  FormControl,
  Checkbox,
} from "@mui/material"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import { PrizeMeterReadMeterCategoryEnum } from "src/api/models"
import { getPrizeMeterReads, putPrizeMeterReads } from "src/api/prize-sales"
import {
  PrizeMeterReadAnalogForm,
  OnSubmitPrizeMeterReadAnalogForm,
} from "src/components/organisms/prizes/PrizeMeterReadAnalogForm"
import {
  PrizeMeterReadSoftForm,
  OnSubmitPrizeMeterReadSoftForm,
} from "src/components/organisms/prizes/PrizeMeterReadSoftForm"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  getPrizeMeterReadAnalogRequests,
  getPrizeMeterReadSoftRequests,
} from "src/domains/prizes/meterReadRepository"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { useUserRole } from "src/hooks/useUserRole"
import { formatApiDate, getJpDateLabel } from "src/utils"

export const PrizeMeterReadEdit: React.FC = () => {
  return (
    <MainContentLayout
      title="プライズ売上情報の入力・更新"
      subtitle="プライズ機種編集"
      renderContent={() => <PrizeMeterReadEditInner />}
      backButtonLabel="保存せず戻る"
    />
  )
}

const PrizeMeterReadEditInner: React.FC = () => {
  const { arcadeCd, boothName } = useParams()
  const navigate = useNavigate()
  const { search } = useLocation()
  const { isEditableMeterReadSetting } = useUserRole()
  const query = new URLSearchParams(search)
  const selectedDate = query.get("selected_date")
  const [meterCategory, setMeterCategory] =
    useState<PrizeMeterReadMeterCategoryEnum>(
      PrizeMeterReadMeterCategoryEnum.Analog,
    )
  const [needYen10CoinCount, setNeedYen10CoinCount] = useState<boolean>(false)

  const meterReadBoothElements = useResource({
    subject: "プライズ売上情報の取得",
    fetch:
      arcadeCd && boothName && selectedDate
        ? () =>
            getPrizeMeterReads(arcadeCd, {
              from: selectedDate,
              to: selectedDate,
              boothName,
            })
        : undefined,
    recoilKey: `getPrizeMeterReads:${arcadeCd}:${selectedDate}:${selectedDate}:${boothName}`,
  }).resource?.data.booths

  const items = useMemo(
    () =>
      (meterReadBoothElements || [])
        .filter((e) => e.booth.boothName.startsWith(boothName || ""))
        .map(({ booth, meters }) => {
          const meter = meters.find(
            (meter) => formatApiDate(meter.recordedAt) === selectedDate,
          )
          return meter && { booth, meter }
        })
        .filter((item) => !!item),
    [meterReadBoothElements, selectedDate, boothName],
  )

  const { submitPromises } = useSubmitting()

  const navigateToMeterReadList = ({
    selectedDate,
    brokenBoothNames,
  }: {
    selectedDate: string
    brokenBoothNames: string[]
  }) => {
    const query = new URLSearchParams()
    query.set("edited_date", selectedDate)
    brokenBoothNames.forEach((boothName) =>
      query.append("broken_booths", boothName),
    )
    navigate(`/arcades/${arcadeCd}/prizes/meterRead?${query.toString()}`)
  }

  const onSubmitAnalogForm: OnSubmitPrizeMeterReadAnalogForm = async (data) => {
    if (!selectedDate || !arcadeCd) return

    await submitPromises([
      {
        subject: "メーターリード結果の更新",
        showSuccessMessage: true,
        promise: async () => {
          await putPrizeMeterReads(arcadeCd, {
            meters: getPrizeMeterReadAnalogRequests(
              selectedDate,
              items.map((i) => i.meter),
              data.meters,
            ),
            softMeters: [],
          })
        },
      },
    ])

    navigateToMeterReadList({
      selectedDate,
      brokenBoothNames: data.meters
        .filter((meter) => meter.isBroken)
        .map((meter) => meter.boothName),
    })
  }

  const onSubmitSoftForm: OnSubmitPrizeMeterReadSoftForm = async (data) => {
    if (!selectedDate || !arcadeCd) return

    await submitPromises([
      {
        subject: "メーターリード結果の更新",
        showSuccessMessage: true,
        promise: async () => {
          await putPrizeMeterReads(arcadeCd, {
            meters: [],
            softMeters: getPrizeMeterReadSoftRequests(
              selectedDate,
              items.map((i) => i.meter),
              data.softMeters,
            ),
          })
        },
      },
    ])

    navigateToMeterReadList({
      selectedDate,
      brokenBoothNames: data.softMeters
        .filter((softMeter) => softMeter.isBroken)
        .map((softMeter) => softMeter.boothName),
    })
  }

  const booth = useMemo(() => {
    const { booth, meter } = items[0] || {}
    setMeterCategory(
      meter?.meterCategory || PrizeMeterReadMeterCategoryEnum.Analog,
    )
    return booth
  }, [items])

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <Stack py={2} px={3} gap={2}>
          <Typography variant="h2">{boothName}</Typography>
          <Typography>{getJpDateLabel(selectedDate || "")}</Typography>
          <Stack>
            <Typography
              variant="body2"
              sx={(theme) => ({ color: theme.palette.gray[50] })}
            >
              AM機器番号
            </Typography>
            <Typography>{booth?.amMachineNumber}</Typography>
          </Stack>
          <Stack>
            <Typography variant="body2">メーターの入力方法</Typography>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography
                variant={
                  meterCategory === PrizeMeterReadMeterCategoryEnum.Analog
                    ? "subtitle1"
                    : "body2"
                }
              >
                アナログ
              </Typography>
              <FormControl>
                <Switch
                  defaultChecked={
                    meterCategory == PrizeMeterReadMeterCategoryEnum.Soft
                  }
                  disabled={!isEditableMeterReadSetting}
                  onChange={(_) =>
                    setMeterCategory(
                      meterCategory === PrizeMeterReadMeterCategoryEnum.Analog
                        ? PrizeMeterReadMeterCategoryEnum.Soft
                        : PrizeMeterReadMeterCategoryEnum.Analog,
                    )
                  }
                />
              </FormControl>
              <Typography
                variant={
                  meterCategory === PrizeMeterReadMeterCategoryEnum.Soft
                    ? "subtitle1"
                    : "body2"
                }
              >
                ソフト
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ height: 32 }}>
              <Typography variant="body2">10円コイン枚数を入力する</Typography>
              <Checkbox
                checked={needYen10CoinCount}
                onClick={() => setNeedYen10CoinCount(!needYen10CoinCount)}
              />
            </Stack>
          </Stack>
        </Stack>
      </Card>

      {meterCategory === PrizeMeterReadMeterCategoryEnum.Soft ? (
        <PrizeMeterReadSoftForm
          items={items}
          onSubmit={onSubmitSoftForm}
          hideSetting={!isEditableMeterReadSetting}
          needYen10CoinCount={needYen10CoinCount}
        />
      ) : (
        <PrizeMeterReadAnalogForm
          items={items}
          onSubmit={onSubmitAnalogForm}
          hideSetting={!isEditableMeterReadSetting}
          needYen10CoinCount={needYen10CoinCount}
        />
      )}
    </>
  )
}
