import { useMemo, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { ArrowDownwardRounded, ContentCopy, Search } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Button,
  Card,
  IconButton,
  Divider,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material"
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import * as Yup from "yup"

import {
  PrizeBoothCategory,
  PrizeDailyPlan,
  PrizeDailyPlansElement,
  PrizeFieldSetting,
} from "src/api/models"
import { getPrizeDailyPlans, postPrizeDailyPlans } from "src/api/prize-plans"
import {
  getPrizeSettingBoothCategories,
  getPrizeSettingFields,
} from "src/api/prize-settings"
import { AlertCaptionCard } from "src/components/molecules/AlertCaptionCard"
import { PrizeNameByPrizeCd } from "src/components/molecules/PrizeNameByPrizeCode"
import { SearchAutoComplete } from "src/components/molecules/SearchAutoComplete"
import { PrizeCodeSearchModal } from "src/components/organisms/PrizeCodeSearchModal"
import { PrizeDailyPlanEditResult } from "src/components/organisms/prizes/PrizeDailyPlanEditResult"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { getJpDateLabel } from "src/utils"

type FormValues = {
  plans: {
    planId: number
    boothCategory?: string
    prizeCd: string
    setting?: string
  }[]
}

export const PrizeDailyEdit: React.FC = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const from = query.get("from")
  const to = query.get("to")
  const prizeDailyPlanIds =
    query.get("prizeDailyPlanIds")?.split(",").map(Number) || []
  const boothName = query.get("boothName") || undefined
  const [submitResult, setSubmitResult] = useState<{
    prevPlans?: PrizeDailyPlan[]
  } | null>(null)

  const { resource: prizeDailyPlansReturn, refetchForce } = useResource({
    subject: "日毎の入替計画の取得",
    fetch:
      arcadeCd && from && to
        ? () =>
            getPrizeDailyPlans(arcadeCd, {
              from,
              to,
              boothName,
              prizeDailyPlanIds,
            })
        : undefined,
    recoilKey: `getPrizeDailyPlans:${arcadeCd}:${from}:${to}:${prizeDailyPlanIds}`,
  })

  const prizeDailyPlans = useMemo(
    () =>
      (prizeDailyPlansReturn?.data.plans || []).sort(
        (a: PrizeDailyPlansElement, b: PrizeDailyPlansElement) => {
          if (a.booth.boothName !== b.booth.boothName) {
            return a.booth.boothName > b.booth.boothName ? 1 : -1
          }
          return a.plan.recordedAt > b.plan.recordedAt ? 1 : -1
        },
      ),
    [prizeDailyPlansReturn],
  )

  const { resource: prizeSettingBoothCategoriesReturn } = useResource({
    subject: "ブース区分の一覧の取得",
    fetch: arcadeCd
      ? () => getPrizeSettingBoothCategories(arcadeCd)
      : undefined,
    recoilKey: `getPrizeSettingBoothCategories:${arcadeCd}`,
  })
  const prizeSettingBoothCategories =
    prizeSettingBoothCategoriesReturn?.data.boothCategories

  const { resource: prizeSettingFieldsReturn } = useResource({
    subject: "フィールド設定リストの取得",
    fetch: arcadeCd ? () => getPrizeSettingFields() : undefined,
    recoilKey: `getPrizeSettingFields`,
  })
  const prizeSettingFields = prizeSettingFieldsReturn?.data.fields

  const validationSchema = Yup.object({
    plans: Yup.array()
      .of(
        Yup.object({
          planId: Yup.number().required(),
          boothCategory: Yup.string(),
          prizeCd: Yup.string().required("必須です"),
          setting: Yup.string(),
        }),
      )
      .required(),
  })
  const useFormReturn = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      plans: (prizeDailyPlans || []).map((plan) => ({
        planId: plan.plan.id,
        boothCategory: plan.plan.boothCategory,
        prizeCd: plan.plan.prize.prizeCd,
        setting: plan.plan.setting,
      })),
    },
  })
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useFormReturn

  // 景品名の検索結果が全て有効な場合のみ保存するボタンを有効にする
  const [prizeNames, setPrizeNames] = useState<(string | null)[]>(
    (prizeDailyPlans || []).map(({ plan }) => plan.prize.prizeName || null),
  )
  const isPrizeNamesValid = prizeNames.every((prizeName) => prizeName != null)

  const { submitPromises } = useSubmitting()
  const onSubmit = async (data: FormValues) => {
    // 完了画面の表示用に更新前の景品データをコピーして保持する
    const prevPlans = prizeDailyPlans?.map(({ plan }) => ({
      ...plan,
      prize: { ...plan.prize },
    }))

    const result = await submitPromises([
      {
        subject: "日毎の入替計画の更新",
        showSuccessMessage: true,
        promise: async () => {
          arcadeCd &&
            (await postPrizeDailyPlans(arcadeCd, {
              plans: data.plans.map(
                ({ planId, prizeCd, boothCategory, setting }) => {
                  const plan = prizeDailyPlans?.find(
                    (prizeDailyPlan) => prizeDailyPlan.plan?.id === planId,
                  )
                  if (!plan) throw new Error("never")
                  return {
                    id: plan.plan.id,
                    prizeCd: prizeCd.trim(),
                    boothCategory,
                    setting,
                  }
                },
              ),
            }))
        },
      },
    ])

    if (!result.success) throw result.error
    await refetchForce()
    setSubmitResult({ prevPlans })
  }

  if (submitResult) {
    return (
      <MainContentLayout
        title="ブース別投入景品 ブース編集"
        renderContent={() => (
          <PrizeDailyEditComplete
            arcadeCd={arcadeCd}
            plans={(prizeDailyPlans || []).map(({ plan }) => plan)}
            prevPlans={submitResult.prevPlans}
          />
        )}
        backButtonLabel="ブース別投入景品一覧へ戻る"
        onClickBackButton={() =>
          navigate(`/arcades/${arcadeCd}/prizes/plans/daily`)
        }
      />
    )
  }

  return (
    <MainContentLayout
      title="ブース別投入景品 ブース編集"
      renderContent={() => (
        <form onSubmit={handleSubmit(onSubmit, () => null)}>
          <Stack gap={3}>
            <Stack gap={2}>
              <FormProvider {...useFormReturn}>
                {prizeDailyPlans?.map((prizeDailyPlan, i) => (
                  <PrizeDailyEditCard
                    key={i}
                    index={i}
                    arcadeCd={arcadeCd}
                    prizeDailyPlan={prizeDailyPlan}
                    prizeSettingBoothCategories={
                      prizeSettingBoothCategories ?? []
                    }
                    prizeSettingFields={prizeSettingFields ?? []}
                    onChangePrizeName={(prizeName) => {
                      setPrizeNames((prev) => {
                        // 変更がない場合にも新しい配列を返してしまうと余計に再レンダリングされてしまうため、変更がない場合はそのまま返す
                        return prev[i] !== prizeName
                          ? prev.map((name, j) => (i === j ? prizeName : name))
                          : prev
                      })
                    }}
                  />
                ))}
              </FormProvider>
            </Stack>

            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={isSubmitting}
              disabled={!isPrizeNamesValid}
            >
              保存する
            </LoadingButton>
          </Stack>
        </form>
      )}
      backButtonLabel="保存せず戻る"
    />
  )
}

type PrizeDailyEditCardProps = {
  index: number
  arcadeCd: string | undefined
  prizeDailyPlan: PrizeDailyPlansElement
  prizeSettingBoothCategories: PrizeBoothCategory[]
  prizeSettingFields: PrizeFieldSetting[]
  onChangePrizeName?: (prizeName: string | null) => void
}
const PrizeDailyEditCard: React.FC<PrizeDailyEditCardProps> = ({
  index,
  arcadeCd,
  prizeDailyPlan,
  prizeSettingBoothCategories,
  prizeSettingFields,
  onChangePrizeName,
}) => {
  const { plan, booth } = prizeDailyPlan

  const [open, setOpen] = useState(false)

  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<FormValues>()

  const prizeCd = useWatch({ control, name: `plans.${index}.prizeCd` })

  const planErrors = (errors.plans && errors.plans[index]) || {}

  return (
    <>
      <Card sx={{ px: 3, py: 2 }}>
        <input
          hidden
          {...register(`plans.${index}.planId`, { required: true })}
          value={plan.id}
        />
        <Typography variant="body1" mb={2}>
          {getJpDateLabel(plan.recordedAt)}
        </Typography>

        <Typography variant="h2" mb={2}>
          {booth.boothName}
        </Typography>

        <Divider sx={{ mb: 2 }} />

        <Stack gap={2} mb={1}>
          <Stack gap={1}>
            <Typography variant="body2" color="gray.50">
              景品CD
            </Typography>
            <Typography>{plan.prize.prizeCd || "-"}</Typography>
          </Stack>
          <Stack gap={1}>
            <Typography variant="body2" color="gray.50">
              景品名
            </Typography>
            <Typography>{plan.prize.prizeName || "-"}</Typography>
          </Stack>
          <Divider />
          <Stack alignItems="center">
            <ArrowDownwardRounded fontSize="small" sx={{ color: "gray.40" }} />
          </Stack>
        </Stack>

        <Stack gap={2}>
          <Stack gap={1}>
            <Typography
              variant="body2"
              color="gray.50"
              component="label"
              htmlFor="boothCategory"
            >
              ブース区分
            </Typography>

            <Controller
              name={`plans.${index}.boothCategory`}
              control={control}
              render={({ field, fieldState }) => (
                <SearchAutoComplete
                  items={(prizeSettingBoothCategories || []).map(
                    ({ name }) => ({
                      label: name,
                      value: name,
                    }),
                  )}
                  {...field}
                  error={!!fieldState.error}
                />
              )}
            />
          </Stack>

          <Stack gap={1}>
            <Typography
              variant="body2"
              color="gray.50"
              component="label"
              htmlFor="prizeCd"
            >
              景品CD
            </Typography>
            <Stack direction="row" gap={1} alignItems="center">
              <Stack flex={1}>
                <TextField
                  id="prizeCd"
                  {...register(`plans.${index}.prizeCd`)}
                  error={"prizeCd" in planErrors}
                  helperText={planErrors.prizeCd?.message || ""}
                  sx={{ flexGrow: 1 }}
                />
              </Stack>
              <Stack>
                <IconButton
                  color="primary"
                  disableRipple={false}
                  onClick={() => navigator.clipboard.writeText(prizeCd)}
                >
                  <ContentCopy />
                </IconButton>
              </Stack>
              <Stack>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ whiteSpace: "nowrap", height: 48 }}
                  startIcon={
                    <SvgIcon>
                      <Search />
                    </SvgIcon>
                  }
                  onClick={() => setOpen(true)}
                >
                  検索
                </Button>
              </Stack>
            </Stack>
          </Stack>

          <Stack gap={1}>
            <Typography variant="body2" color="gray.50">
              景品名
            </Typography>
            <PrizeNameByPrizeCd
              arcadeCd={arcadeCd}
              prizeCd={prizeCd}
              onResult={onChangePrizeName}
            />
          </Stack>

          <Stack gap={1}>
            <Typography
              variant="body2"
              color="gray.50"
              component="label"
              htmlFor="setting"
            >
              設定
            </Typography>
            <Controller
              name={`plans.${index}.setting`}
              control={control}
              render={({ field, fieldState }) => (
                <SearchAutoComplete
                  items={(prizeSettingFields || []).map(({ name }) => ({
                    label: name,
                    value: name,
                  }))}
                  {...field}
                  error={!!fieldState.error}
                />
              )}
            />
          </Stack>
        </Stack>
      </Card>
      <PrizeCodeSearchModal
        open={open}
        handleClose={() => setOpen(false)}
        onSelect={(prizeCd: string) => {
          setValue(`plans.${index}.prizeCd`, prizeCd)
          setOpen(false)
        }}
      />
    </>
  )
}

type PrizeDailyEditCompleteProps = {
  arcadeCd: string | undefined
  plans: PrizeDailyPlan[]
  prevPlans?: PrizeDailyPlan[]
}
const PrizeDailyEditComplete: React.FC<PrizeDailyEditCompleteProps> = ({
  arcadeCd,
  plans,
  prevPlans,
}) => {
  return (
    <Stack gap={2}>
      <Stack>
        <AlertCaptionCard
          label="下記の通り保存しました"
          rightLinkText="一覧へ戻る"
          rightLinkTo={`/arcades/${arcadeCd}/prizes/plans/daily`}
        />
      </Stack>
      <Stack gap={2}>
        {plans.map((plan) => {
          const prevPlan = prevPlans?.find(({ id }) => id === plan.id)

          return (
            <Card sx={{ px: 3, py: 2 }} key={plan.id}>
              <Typography variant="body1" mb={2}>
                {getJpDateLabel(plan.recordedAt)}
              </Typography>

              <Typography variant="h3" mb={2}>
                {plan.boothName}
              </Typography>

              <Divider sx={{ mb: 2 }} />

              {prevPlan && (
                <Stack gap={2} mb={1}>
                  <Stack gap={1}>
                    <Typography variant="body2" color="gray.50">
                      景品CD
                    </Typography>
                    <Typography variant="body1">
                      {prevPlan.prize.prizeCd || "-"}
                    </Typography>
                  </Stack>
                  <Stack gap={1}>
                    <Typography variant="body2" color="gray.50">
                      景品名
                    </Typography>
                    <Typography variant="body1">
                      {prevPlan.prize.prizeName || "-"}
                    </Typography>
                  </Stack>
                  <Divider />
                  <Stack alignItems="center">
                    <ArrowDownwardRounded
                      fontSize="small"
                      sx={{ color: "gray.40" }}
                    />
                  </Stack>
                </Stack>
              )}

              <PrizeDailyPlanEditResult
                prizeCd={plan.prize.prizeCd}
                prizeName={plan.prize.prizeName}
                boothCategory={plan.boothCategory}
                setting={plan.setting}
              />
            </Card>
          )
        })}
      </Stack>
    </Stack>
  )
}
