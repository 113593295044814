import { useCallback, useRef } from "react"

import { Deselect, SelectAll } from "@mui/icons-material"
import { Box, Button, Stack, Typography } from "@mui/material"

import { CheckCircle } from "src/components/atoms/CheckCircle"
import { Span } from "src/components/atoms/primitives"
import {
  FloorMapBox,
  FloorMapBoxHandler,
  FloorMapPointBoxProps,
  PrizeDailyPlanFloorMapPointBox,
} from "src/components/organisms/FloorMapBox"
import { PrizeDailyPlanFloorMapPrintInstruction } from "src/components/organisms/prizes/PrizeDailyPlanFloorMapPrintInstruction"
import { PrizePlanFloorMapPoint } from "src/domains/prizes/floorMapRepository"

type Props = {
  prizePlanFloorMapPoints: PrizePlanFloorMapPoint[]
  selectedPointIds?: number[]
  onClickPoint?: (point: PrizePlanFloorMapPoint) => void
  onSelectPoints?: (pointIds: number[]) => void
}

export const PrizeDailyPlanFloorMapBox: React.FC<Props> = ({
  prizePlanFloorMapPoints,
  selectedPointIds = [],
  onClickPoint,
  onSelectPoints,
}) => {
  const mapBoxHandler = useRef<FloorMapBoxHandler>(null)

  const getFloorMapPointBox = useCallback(
    ({ point }: FloorMapPointBoxProps) => {
      const isSelected = Boolean(selectedPointIds.includes(point.id))
      const { topLeftX, topLeftY, bottomRightX, bottomRightY } = point

      return (
        <Box
          key={point.id}
          sx={[
            {
              position: "absolute",
              left: topLeftX,
              top: topLeftY,
              width: bottomRightX - topLeftX,
              height: bottomRightY - topLeftY,
            },
            onClickPoint != null && { cursor: "pointer" },
          ]}
          onClick={
            onClickPoint &&
            (() => onClickPoint(point as PrizePlanFloorMapPoint))
          }
          data-testid={`floor-map-point-${point.id}`}
          data-point-id={point.id}
        >
          <Box
            sx={(theme) => ({
              width: "100%",
              height: "100%",
              overflow: "hidden",
              borderRadius: 2,
              border: `1px solid ${theme.palette.neutral[400]}`,
            })}
          >
            <PrizeDailyPlanFloorMapPointBox point={point} />
          </Box>
          {isSelected && (
            <>
              {/* 選択時に borderWidth を変更すると文字の表示にも影響が出るため上から重ねる  */}
              <Box
                sx={(theme) => ({
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  borderRadius: 2,
                  border: `2px solid ${theme.palette.primary.main}`,
                })}
              />
              <CheckCircle
                size={24}
                sx={{ position: "absolute", top: -4, right: -4 }}
              />
            </>
          )}
        </Box>
      )
    },
    [selectedPointIds, onClickPoint],
  )

  const renderDescription = useCallback(
    () => (
      <Stack direction="row" gap={2}>
        <Typography variant="caption">
          ※前日までの入力情報から当日変更があった場合→
          <Span
            sx={{
              fontWeight: "bold",
              backgroundColor: "sub.cyanLight",
              borderRadius: 0.5,
              px: 1,
              py: 0.5,
              ml: 0.5,
            }}
          >
            背景が水色
          </Span>
        </Typography>
        <Typography variant="caption">
          ※当日着荷景品→
          <Span
            sx={{
              fontWeight: "bold",
              backgroundColor: "sub.cyanLight",
              color: "sub.cyan",
              borderRadius: 0.5,
              px: 1,
              py: 0.5,
              ml: 0.5,
            }}
          >
            背景と文字が水色
          </Span>
        </Typography>
      </Stack>
    ),
    [],
  )

  return (
    <Stack gap={2}>
      {onSelectPoints && (
        <>
          <PrizeDailyPlanFloorMapPrintInstruction />
          <Stack direction="row" alignItems="center" gap={2}>
            <Button
              variant="outlined"
              startIcon={<SelectAll />}
              disabled={
                selectedPointIds.length === prizePlanFloorMapPoints.length
              }
              onClick={() => mapBoxHandler.current?.selectAll()}
            >
              全ブース選択
            </Button>
            <Button
              variant="contained"
              color="error"
              startIcon={<Deselect />}
              disabled={selectedPointIds.length === 0}
              onClick={() => mapBoxHandler.current?.deselectAll()}
            >
              ブース選択解除
            </Button>
            <Typography>{selectedPointIds.length}件選択中</Typography>
          </Stack>
        </>
      )}
      <FloorMapBox
        ref={mapBoxHandler}
        floorMapPoints={prizePlanFloorMapPoints}
        getFloorMapPointBox={getFloorMapPointBox}
        renderDescription={renderDescription}
        onSelectPoints={onSelectPoints}
      />
    </Stack>
  )
}
