import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import {
  Box,
  Card,
  Table,
  TableBody,
  TablePagination,
  Link,
  TableRowProps,
  Stack,
} from "@mui/material"
import deepEqual from "fast-deep-equal"
import { Link as RouterLink, useLocation } from "react-router-dom"
import { useRecoilState } from "recoil"

import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  PaginationTableLabel,
  TableLimit,
} from "src/components/atoms/PaginationTableLabel"
export type { TableLimit } from "src/components/atoms/PaginationTableLabel"
import { TableBorderedRow } from "src/components/molecules/CardTableCells"
import { usePrevious } from "src/hooks/usePrevious"
import { paginatedTablesState } from "src/recoil"
import { theme } from "src/theme"

interface PaginatedTableState {
  page?: number
  limit?: TableLimit
}

export interface PaginatedTablesState {
  [pathname: string]: {
    [stateKey: string]: PaginatedTableState
  }
}

interface PaginatedTableProps<T> {
  items: T[]
  renderRow: (item: T, index: number) => React.ReactNode
  emptyRowProps?: {
    colCount: number
    text: string
  }
  endRows?: React.ReactElement
  defaultLimit?: TableLimit
  header?: React.ReactNode
  scrollableX?: boolean
  scrollableY?: boolean
  stickyHeader?: boolean
  stateKey?: string
  noMargin?: boolean
  tableLayout?: "auto" | "fixed"
}

type PaginatedTableInnerProps<T> = Omit<
  PaginatedTableProps<T>,
  "defaultLimit"
> & {
  limit: TableLimit
  setLimit: (limit: TableLimit) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PaginatedTable = <T extends Record<string, any>>({
  defaultLimit = 50,
  stateKey,
  ...rest
}: PaginatedTableProps<T>) => {
  const { pathname } = useLocation()
  const [localLimit, setLocalLimit] = useState(defaultLimit)
  const [tablesState, setTablesState] = useRecoilState(paginatedTablesState)
  const recoilState = stateKey ? tablesState[pathname]?.[stateKey] : undefined

  const limitWithRecoil = recoilState?.limit ?? localLimit
  const setRecoilLimit = (limit: TableLimit) => {
    if (!stateKey) return
    setTablesState({
      ...tablesState,
      [pathname]: {
        ...(tablesState[pathname] || {}),
        [stateKey]: { ...tablesState[pathname]?.[stateKey], page: 0, limit },
      },
    })
  }

  const handleLimitChange = (limit: TableLimit) => {
    setLocalLimit(limit)
    setRecoilLimit(limit)
  }

  return (
    <PaginatedTableInner
      {...rest}
      stateKey={stateKey}
      limit={limitWithRecoil}
      setLimit={handleLimitChange}
    />
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PaginatedTableInner = <T extends Record<string, any>>({
  items,
  limit,
  setLimit,
  renderRow,
  emptyRowProps,
  endRows,
  header = <></>,
  scrollableX = false,
  scrollableY = false,
  stickyHeader = false,
  stateKey,
  noMargin = false,
  tableLayout = "auto",
}: PaginatedTableInnerProps<T>) => {
  const { pathname } = useLocation()
  // NOTE: mount のタイミングを検出するため、初期値は undefined
  const [page, setPage] = useState<number>()
  const [tablesState, setTablesState] = useRecoilState(paginatedTablesState)

  // NOTE: TablePagination へ渡すために丸めたページ番号
  const safePage = useMemo(() => {
    // recoilによるページ番号の状態管理がある場合は、その値を優先する
    const pageWithRecoil = stateKey
      ? (tablesState[pathname]?.[stateKey]?.page ?? page)
      : page
    if (!pageWithRecoil) {
      return 0
    }
    if (items.length <= (pageWithRecoil - 1) * limit) {
      return Math.floor(items.length / limit)
    }
    return pageWithRecoil
  }, [stateKey, tablesState, pathname, page, items.length, limit])
  const pageSliceStart = limit * (safePage || 0)
  const pageSliceEnd = pageSliceStart + limit
  const tableRef = useRef<HTMLTableElement>(null)

  const setRecoilPage = useCallback(
    (page: number) => {
      if (stateKey) {
        setTablesState({
          ...tablesState,
          [pathname]: {
            ...(tablesState[pathname] || {}),
            [stateKey]: { ...tablesState[pathname]?.[stateKey], page },
          },
        })
      }
    },
    [pathname, setTablesState, tablesState, stateKey],
  )

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView()
    }
    setPage(newPage)
    setRecoilPage(newPage)
  }

  const prevItems = usePrevious(items)
  useEffect(() => {
    if (!prevItems || deepEqual(prevItems, items)) return
    // NOTE: ソートやフィルタでレコードが更新された場合にページ番号をリセットする
    // NOTE: オブジェクトの配列における比較ではポインタ比較のため、文字列にして比較する
    // TODO: オブジェクトと配列が大きくなると、文字列化および文字列比較に時間がかかる可能性があるので、他の方法を検討する
    setPage(0)
    setRecoilPage(0)
  }, [items, prevItems, tablesState, stateKey, limit, pathname, setRecoilPage])

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        m: noMargin ? 0 : 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          ...(scrollableX && { overflowX: "scroll" }),
          ...(scrollableY && { overflowY: "scroll" }),
        }}
      >
        <Table
          ref={tableRef}
          stickyHeader={stickyHeader}
          sx={{
            tableLayout,
          }}
        >
          {header}
          <TableBody
            sx={{
              tr: {
                ":last-child": {
                  td: {
                    borderBottom: "none",
                  },
                },
              },
            }}
          >
            {items.length === 0 && emptyRowProps && (
              <TableBorderedRow>
                <ExtTableCell
                  colSpan={emptyRowProps.colCount}
                  sx={{ textAlign: "center" }}
                >
                  {emptyRowProps.text}
                </ExtTableCell>
              </TableBorderedRow>
            )}
            {items
              .slice(pageSliceStart, pageSliceEnd)
              .map((item, i) => renderRow(item, i))}
            {endRows}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        component="div"
        count={items.length}
        onPageChange={handlePageChange}
        page={safePage}
        rowsPerPage={limit}
        rowsPerPageOptions={[limit]}
        sx={(theme) => ({
          flexShrink: 0,
          borderTop: "1px solid",
          borderColor: theme.palette.divider,
          ".MuiTablePagination-spacer": {
            display: "none",
          },
          ".MuiToolbar-root": {
            flexGrow: 1,
            flexShrink: 0,
            flexDirection: "row",
            py: "10px",
            pl: 2,
            pr: 0,
            justifyContent: "space-between",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
              pr: 2,
              gap: 1,
            },
          },
          ".MuiTablePagination-displayedRows": {
            display: "flex",
            flexGrow: 1,
            flexShrink: 0,
            flexDirection: "row",
            justifyContent: "space-between",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          },
          ".MuiTablePagination-actions": {
            [theme.breakpoints.down("sm")]: {
              alignSelf: "end",
            },
            button: {
              p: 0.5,
            },
          },
        })}
        labelDisplayedRows={({ from, to, count }) => (
          <PaginationTableLabel
            {...{
              from,
              to,
              count,
              offset: safePage + 1,
              limit,
            }}
            handlePageChange={(page) => handlePageChange(null, page)}
            setLimit={setLimit}
          />
        )}
      />
    </Card>
  )
}

interface ListTableRowProps {
  linkPath?: string
  label: string
}

export const ListTableRow: React.FC<ListTableRowProps & TableRowProps> = ({
  linkPath,
  label,
  ...rowProps
}) => {
  return (
    <TableBorderedRow hover {...rowProps}>
      <ExtTableCell sx={{ p: 0 }}>
        {linkPath ? (
          <Link
            to={linkPath}
            component={RouterLink}
            underline="none"
            sx={{ color: theme.palette.primary.main }}
          >
            <Stack sx={{ p: 2 }}>{label}</Stack>
          </Link>
        ) : (
          <Link underline="none" sx={{ color: theme.palette.primary.main }}>
            <Stack sx={{ p: 2 }}>{label}</Stack>
          </Link>
        )}
      </ExtTableCell>
    </TableBorderedRow>
  )
}
