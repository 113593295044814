import React from "react"

import {
  Button,
  Card,
  Typography,
  Stack,
  DialogContent,
  Link,
} from "@mui/material"
import { useNavigate } from "react-router-dom"

import { PutNoticeRequestStatusEnum } from "src/api/models"
import { deleteNotice, getNotice, getNotices } from "src/api/notices"
import { BackButton } from "src/components/atoms/BackButton"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { getJpDateLabel } from "src/utils"

export const NoticeList: React.FC = () => {
  const navigate = useNavigate()
  return (
    <MainContentLayout
      title="お知らせ一覧"
      renderAction={() => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/notices/new")}
        >
          新規作成
        </Button>
      )}
      renderContent={() => <NoticeListInner />}
    />
  )
}

const NoticeListInner: React.FC = () => {
  const navigate = useNavigate()
  const [selectedDeleteNoticeId, setSelectedDeleteNoticeId] = React.useState<
    number | null
  >(null)

  const { resource: noticesResource, refetch } = useResource({
    subject: "お知らせ詳細の取得",
    fetch: () => getNotices(),
    recoilKey: `getNotices`,
  })
  const notices = noticesResource?.data.notices || []

  const { submitPromises } = useSubmitting()
  const onClickNotice = async (id: number) => {
    await submitPromises([
      {
        subject: "お知らせの取得",
        showSuccessMessage: false,
        promise: async () => {
          const res = await getNotice(id)
          const notice = res.data.notice
          if (notice.url) {
            window.open(notice.url, "_blank")
          }
        },
      },
    ])
  }

  const handleDelete = async (id: number) => {
    await submitPromises([
      {
        subject: "お知らせの削除",
        showSuccessMessage: true,
        promise: async () => {
          await deleteNotice(id)
        },
      },
    ])
    refetch()
  }

  return (
    <Stack gap={2}>
      <Card>
        {notices.map((notice) => (
          <Stack key={notice.id} sx={{ p: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Stack direction="row" alignItems="center" gap={2}>
                <Typography variant="h3">
                  {getJpDateLabel(notice.openAt)}
                </Typography>

                <Link
                  component="button"
                  variant="body1"
                  onClick={() => onClickNotice(notice.id)}
                >
                  {notice.title}
                </Link>

                <Typography variant="body1" color="textSecondary">
                  {notice.status === PutNoticeRequestStatusEnum.Public
                    ? "公開"
                    : "非公開"}
                </Typography>
              </Stack>

              <Stack gap={1}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => navigate(`/notices/${notice.id}/edit`)}
                  sx={{ whiteSpace: "nowrap" }}
                >
                  編集
                </Button>

                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => setSelectedDeleteNoticeId(notice.id)}
                  sx={{ whiteSpace: "nowrap" }}
                >
                  削除
                </Button>
              </Stack>
            </Stack>
            <DeleteNoticeModal
              showModal={selectedDeleteNoticeId === notice.id}
              noticeTitle={notice.title}
              onSubmit={() => {
                handleDelete(notice.id)
                setSelectedDeleteNoticeId(null)
              }}
              onClose={() => setSelectedDeleteNoticeId(null)}
            />
          </Stack>
        ))}
      </Card>
    </Stack>
  )
}

type DeleteNoticeModalProps = {
  showModal: boolean
  noticeTitle: string
  onSubmit: () => void
  onClose: () => void
}
export const DeleteNoticeModal: React.FC<DeleteNoticeModalProps> = ({
  showModal,
  noticeTitle,
  onSubmit,
  onClose,
}) => {
  return (
    <CustomDialog
      fullWidth
      maxWidth="sm"
      open={showModal}
      onClose={onClose}
      scroll="paper"
    >
      <DialogTitleWithClose onClose={onClose}>
        <Typography variant="h1" pb={1}>
          この記事を削除しますか？
        </Typography>
      </DialogTitleWithClose>
      <DialogContent>{noticeTitle}</DialogContent>

      <CustomDialogActions>
        <BackButton onClick={() => onClose()}>削除せず戻る</BackButton>
        <Button variant="contained" fullWidth color="error" onClick={onSubmit}>
          削除する
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}
