import {
  PrizeMeterRead,
  PrizeMeterReadPayoutCategoryEnum,
  RequestMeterReads,
  RequestSoftMeterReads,
} from "src/api/models"
import { formatApiDate } from "src/utils"

export type PrizeMeterReadAnalogMeter = Omit<
  RequestMeterReads,
  "thincaTerminalNumber" | "recordedAt"
> & {
  useThincaTerminal: boolean
  thincaTerminalNumber?: string
  isOverridePrize?: boolean
  isMeterOverflow?: boolean
  // 下記の値は validationSchema で参照するために必要 (値の変更はしない)
  prevYen10CoinCount?: PrizeMeterRead["prevYen10CoinCount"]
  prevYen100CoinCount?: PrizeMeterRead["prevYen100CoinCount"]
  prevYen500CoinCount?: PrizeMeterRead["prevYen500CoinCount"]
  prevPayout?: PrizeMeterRead["prevPayout"]
}

export const getPrizeMeterReadAnalogRequests = (
  selectedDate: string,
  initials: PrizeMeterRead[],
  meters: PrizeMeterReadAnalogMeter[],
) => {
  const requests = meters.map((meter) => {
    const initial = initials.find(
      (m) =>
        m.boothName === meter.boothName &&
        formatApiDate(m.recordedAt) === selectedDate,
    )
    let isChanged = false
    if (initial) {
      isChanged =
        meter.isBroken !== initial.isBroken ||
        meter.thincaTerminalNumber !== initial.thincaTerminalNumber ||
        meter.yen10CoinCount !== initial.yen10CoinCount ||
        meter.yen100CoinCount !== initial.yen100CoinCount ||
        meter.yen500CoinCount !== initial.yen500CoinCount ||
        meter.payoutCategory !== initial.payoutCategory ||
        meter.payout !== initial.payout ||
        meter.assumedPayoutRate !==
          (initial.assumedPayoutRate ? initial.assumedPayoutRate * 100 : 30) ||
        meter.defaultYen10CoinCount !== initial.defaultYen10CoinCount ||
        meter.defaultYen100CoinCount !== initial.defaultYen100CoinCount ||
        meter.defaultYen500CoinCount !== initial.defaultYen500CoinCount ||
        meter.defaultPayout !== initial.defaultPayout
    } else {
      isChanged = true
    }

    if (isChanged) {
      const request = {
        ...meter,
        assumedPayoutRate:
          meter.payoutCategory ===
            PrizeMeterReadPayoutCategoryEnum.AssumedPayoutRate &&
          meter.assumedPayoutRate
            ? meter.assumedPayoutRate / 100
            : undefined,
        thincaTerminalNumber: meter.thincaTerminalNumber ?? "",
        recordedAt: selectedDate,
      }
      if (meter.useThincaTerminal && !meter.isOverridePrize) {
        request.payout = undefined
      }
      return request
    } else {
      return undefined
    }
  })
  return requests.filter((r) => r !== undefined)
}

export type PrizeMeterReadSoftMeter = Omit<
  RequestSoftMeterReads,
  "thincaTerminalNumber" | "recordedAt"
> & {
  useThincaTerminal: boolean
  thincaTerminalNumber?: string
  isOverridePrize?: boolean
}

export const getPrizeMeterReadSoftRequests = (
  selectedDate: string,
  initials: PrizeMeterRead[],
  meters: PrizeMeterReadSoftMeter[],
) => {
  const requests = meters.map((softMeter) => {
    const initial = initials.find(
      (m) =>
        m.boothName === softMeter.boothName &&
        formatApiDate(m.recordedAt) === selectedDate,
    )
    let isChanged = false
    if (initial) {
      isChanged =
        softMeter.isBroken !== initial.isBroken ||
        softMeter.thincaTerminalNumber !== initial.thincaTerminalNumber ||
        softMeter.yen10CoinCount !== initial.softYen10CoinCount ||
        softMeter.yen100CoinCount !== initial.softYen100CoinCount ||
        softMeter.yen500CoinCount !== initial.softYen500CoinCount ||
        softMeter.payoutCategory !== initial.payoutCategory ||
        softMeter.payout !== initial.softPayout ||
        softMeter.assumedPayoutRate !==
          (initial.assumedPayoutRate ? initial.assumedPayoutRate * 100 : 30)
    } else {
      isChanged = true
    }

    if (isChanged) {
      const request = {
        ...softMeter,
        assumedPayoutRate:
          softMeter.payoutCategory ===
            PrizeMeterReadPayoutCategoryEnum.AssumedPayoutRate &&
          softMeter.assumedPayoutRate
            ? softMeter.assumedPayoutRate / 100
            : undefined,
        thincaTerminalNumber: softMeter.thincaTerminalNumber ?? "",
        recordedAt: selectedDate,
      }
      return request
    } else {
      return undefined
    }
  })
  return requests.filter((r) => r !== undefined)
}
