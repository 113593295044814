/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetPrizePlanRecommendationsParams,
  GetPrizePlanRecommendationsResponse,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 投入推奨景品一覧の取得
 */
export const getPrizePlanRecommendations = (
  arcadeCd: string,
  params?: GetPrizePlanRecommendationsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizePlanRecommendationsResponse>(
    {
      url: `/arcades/${arcadeCd}/prizes/recommendations/plans/daily`,
      method: "GET",
      params,
    },
    options,
  )
}
export type GetPrizePlanRecommendationsResult = NonNullable<
  Awaited<ReturnType<typeof getPrizePlanRecommendations>>
>
