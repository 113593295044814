import { Fragment, Suspense, useCallback, useMemo, useState } from "react"

import {
  Add,
  Edit,
  ErrorOutline,
  ReportProblemOutlined,
  Sync,
} from "@mui/icons-material"
import HelpIcon from "@mui/icons-material/Help"
import {
  Typography,
  Stack,
  TableHead,
  TableRow,
  Button,
  Link,
  IconButton,
  Tabs,
  Tab,
  DialogContent,
} from "@mui/material"
import dayjs from "dayjs"
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { atom, useRecoilState, useRecoilValue } from "recoil"

import { PrizeBooth, PrizeMeterRead } from "src/api/models"
import { getPrizeFloorMap } from "src/api/prize-floor-map"
import { execPrizePosReads, getPrizeMeterReads } from "src/api/prize-sales"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { CustomAlert } from "src/components/molecules/CustomAlert"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { LoadingBox } from "src/components/molecules/LoadingBox"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import {
  PrizeMeterReadDatePicker,
  prizeMeterReadSelectedDateState,
} from "src/components/organisms/prizes/PrizeMeterReadDatePicker"
import {
  PrizeMeterReadFilter,
  defaultSearchParams,
  PrizeMeterReadFilterSearchParams,
} from "src/components/organisms/prizes/PrizeMeterReadFilter"
import { PrizeMeterReadFloorMapBox } from "src/components/organisms/prizes/PrizeMeterReadFloorMapBox"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  convertMeterReadFloorMapPoint,
  PrizeMeterReadFloorMapPoint,
} from "src/domains/prizes/floorMapRepository"
import { getBoothNameWithoutSeatNumber } from "src/domains/prizes/prizeBoothRepository"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { filterAccordionSearchState } from "src/recoil"
import { DateLabelString } from "src/types"
import {
  getUpdatableMeterReadDayjs,
  getRatioLabel,
  formatApiDate,
} from "src/utils"

const tabs = [
  { key: "list", name: "一覧" },
  { key: "floorMap", name: "マップ" },
] as const
type PrizeMeterReadTab = (typeof tabs)[number]["key"]

const prizeMeterReadTabState = atom<PrizeMeterReadTab>({
  key: "prizeMeterReadTabState",
  default: "list",
})

export const PrizeMeterReads: React.FC = () => {
  const { arcadeCd } = useParams()

  const [hintModalOpen, setHintModalOpen] = useState(false)

  // 画面遷移して戻ってきたときにタブの状態を保持するために recoil を使用
  const [tab, setTab] = useRecoilState(prizeMeterReadTabState)

  const [urlSearchParams] = useSearchParams()
  const editedDate = urlSearchParams.get("edited_date")
  const navigate = useNavigate()

  const selectedDate = useRecoilValue(prizeMeterReadSelectedDateState)
  const recoilSearchParams = useRecoilValue(filterAccordionSearchState)

  const searchParams: PrizeMeterReadFilterSearchParams =
    recoilSearchParams["prizeMeterReadsSearchParams"] ?? defaultSearchParams

  const isDisableEditByDate = dayjs(selectedDate).isBefore(
    getUpdatableMeterReadDayjs(),
  )
  const isDisableEditByDateAlert = useCallback(() => {
    return isDisableEditByDate ? (
      <CustomAlert severity="error">
        編集可能期限を過ぎているため編集できません
      </CustomAlert>
    ) : null
  }, [isDisableEditByDate])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const { submitPromises } = useSubmitting()
  const onClickPosRead = async () => {
    setIsSubmitting(true)
    arcadeCd &&
      (await submitPromises([
        {
          subject: "メーターリード結果の更新",
          showSuccessMessage: true,
          promise: async () => {
            await execPrizePosReads(arcadeCd)
          },
        },
      ]))
    setIsSubmitting(false)
  }

  return (
    <>
      <MainContentLayout
        title="プライズ売上情報の入力・更新"
        renderContent={() => (
          <>
            <Stack sx={{ pb: 2 }}>
              <Tabs
                value={tab}
                onChange={(_, value) => setTab(value)}
                scrollButtons={false}
              >
                {tabs.map((t) => (
                  <Tab key={t.key} value={t.key} label={t.name} />
                ))}
              </Tabs>
            </Stack>

            {tab === "list" && (
              <Stack gap={2}>
                <PrizeMeterReadDatePicker defaultValue={editedDate} />
                <Stack>
                  <PrizeMeterReadFilter />
                </Stack>
                {isDisableEditByDateAlert()}
                {isSubmitting && <LoadingBox />}
                <Stack
                  sx={{ flexDirection: "row", gap: 1, width: "100%", pb: 2 }}
                >
                  <Button
                    variant="contained"
                    sx={{ px: 0, flex: 1 }}
                    onClick={() =>
                      navigate(`/arcades/${arcadeCd}/prizes/meterRead/csv`)
                    }
                    startIcon={<Add />}
                    disabled={isDisableEditByDate}
                  >
                    CSV登録
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ px: 0, flex: 1 }}
                    onClick={onClickPosRead}
                    startIcon={<Sync />}
                    disabled={isSubmitting}
                  >
                    自動連携更新
                  </Button>
                </Stack>

                {selectedDate && (
                  <Suspense fallback={<LoadingBox />}>
                    <PrizeMeterReadList
                      selectedDate={selectedDate}
                      searchParams={searchParams}
                      isDisableEditByDate={isDisableEditByDate}
                    />
                  </Suspense>
                )}
              </Stack>
            )}
            {tab === "floorMap" && (
              <Stack gap={2}>
                <PrizeMeterReadDatePicker defaultValue={editedDate} />
                {isDisableEditByDateAlert()}
                {selectedDate && (
                  <Suspense fallback={<LoadingBox />}>
                    <PrizeMeterReadFloorMap
                      selectedDate={selectedDate}
                      isDisableEditByDate={isDisableEditByDate}
                    />
                  </Suspense>
                )}
              </Stack>
            )}
          </>
        )}
        renderAction={() => (
          <Button
            startIcon={<HelpIcon />}
            sx={{
              px: 1,
              py: 0,
              borderRadius: 0.5,
              "& .MuiButton-startIcon": { mr: 0.5 },
            }}
            onClick={() => setHintModalOpen(true)}
          >
            自動連携について
          </Button>
        )}
      />

      <CustomDialog
        fullWidth
        maxWidth="xs"
        scroll="paper"
        open={hintModalOpen}
        onClose={() => setHintModalOpen(false)}
      >
        <DialogTitleWithClose onClose={() => setHintModalOpen(false)}>
          <Typography variant="h1">自動連携について</Typography>
        </DialogTitleWithClose>

        <DialogContent>
          <Typography variant="body2">
            シンカクラウド連携が設定されている場合、自動連携更新ボタンをクリックすることで直近の7日分の最新のメーター値を反映します。ボタンをクリックしていない
            場合でも、毎日05:00に自動で反映を行っています。
          </Typography>
        </DialogContent>

        <CustomDialogActions>
          <Button
            onClick={() => setHintModalOpen(false)}
            color="primary"
            variant="contained"
            fullWidth
          >
            閉じる
          </Button>
        </CustomDialogActions>
      </CustomDialog>
    </>
  )
}

type PrizeMeterReadListProps = {
  selectedDate: DateLabelString
  searchParams: PrizeMeterReadFilterSearchParams
  isDisableEditByDate?: boolean
}

const PrizeMeterReadList: React.FC<PrizeMeterReadListProps> = ({
  selectedDate,
  searchParams,
  isDisableEditByDate,
}) => {
  const { arcadeCd } = useParams()
  const [urlSearchParams] = useSearchParams()
  const edited_date = urlSearchParams.get("edited_date")
  const broken_booths = urlSearchParams.getAll("broken_booths")

  const prizeMeterReads =
    useResource({
      subject: "メーターリード結果の取得",
      fetch: arcadeCd
        ? () =>
            getPrizeMeterReads(arcadeCd, {
              from: selectedDate,
              to: selectedDate,
              boothName: searchParams.prizeBoothName,
            })
        : undefined,
      recoilKey: `getPrizeMeterReads:${arcadeCd}:${selectedDate}:${searchParams.prizeBoothName}`,
    }).resource?.data.booths || []
  const meterReadBoothElement = prizeMeterReads.filter(({ meters, booth }) => {
    if (searchParams.showOnlySoftEmpty !== "null") {
      if (
        searchParams.showOnlySoftEmpty === "true" &&
        meters.some((meter) => meter.softYen100CoinCount === undefined)
      ) {
        return false
      }
      if (
        searchParams.showOnlySoftEmpty === "false" &&
        meters.some((meter) => meter.softYen100CoinCount !== undefined)
      ) {
        return false
      }
    }
    if (searchParams.isAvailable !== "null") {
      if (searchParams.isAvailable === "true" && !booth.isAvailable)
        return false
      if (searchParams.isAvailable === "false" && booth.isAvailable)
        return false
    }
    if (searchParams.isThincaTerminal !== "null") {
      if (
        searchParams.isThincaTerminal === "true" &&
        !meters[0]?.thincaTerminalNumber
      )
        return false
      if (
        searchParams.isThincaTerminal === "false" &&
        !!meters[0]?.thincaTerminalNumber
      )
        return false
    }
    return true
  })

  if (!meterReadBoothElement) return null

  const tableDataSet = new Map<string, TableRowData>()

  meterReadBoothElement.forEach(({ booth, meters }) => {
    const meter = meters[0]

    if (!meter) {
      return
    }

    // シートごとのデータをブース単位にまとめる
    tableDataSet.set(
      getBoothNameWithoutSeatNumber(booth),
      new Map([
        ...Array.from(
          tableDataSet?.get(
            `${booth.machineName}-${booth.machineNumber
              .toString()
              .padStart(3, "0")}`,
          ) ?? [],
        ),
        [
          booth.seatNumber,
          {
            booth,
            meter,
          },
        ],
      ]),
    )
  })

  return (
    <Stack>
      {edited_date && broken_booths && broken_booths.length > 0 && (
        <Stack
          sx={{
            flexDirection: "row",
            mb: 3,
            p: 1,
            backgroundColor: "error.light",
            borderRadius: 1,
            gap: 1,
          }}
        >
          <ErrorOutline sx={{ color: "error.main" }} />
          <Typography color="error.main" variant="body2">
            プライズ機種名
            {broken_booths
              .map((booth_name) => {
                const mr = prizeMeterReads.find(
                  ({ booth }) => booth.boothName === booth_name,
                )
                if (!mr) return
                return `「${getBoothNameWithoutSeatNumber(mr.booth)}」ブース名「${mr.booth.seatNumber}P」`
              })
              .join("、")}
            を故障中にしました。
            {formatApiDate(dayjs(edited_date).add(1, "day"))}
            のソフト値の計算に必要な初期値を入力してください。
          </Typography>
        </Stack>
      )}
      <PrizeMeterReadListTable
        tableDataSet={tableDataSet}
        selectedDate={selectedDate}
        isDisableEditByDate={isDisableEditByDate}
      />
    </Stack>
  )
}

type TableRowData = Map<
  PrizeBooth["seatNumber"],
  { booth: PrizeBooth; meter: PrizeMeterRead }
>
type PrizeMeterReadListTableProps = {
  tableDataSet: Map<string, TableRowData> // boothNameWithoutSeatNumber, TableRowData
  selectedDate: DateLabelString
  isDisableEditByDate?: boolean
}
const PrizeMeterReadListTable: React.FC<PrizeMeterReadListTableProps> = ({
  tableDataSet,
  selectedDate,
  isDisableEditByDate,
}) => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  return (
    <Stack
      sx={{
        minHeight: "440px",
        maxHeight: "calc(100dvh - 440px)",
      }}
    >
      <PaginatedTable
        scrollableX
        scrollableY
        noMargin
        items={Array.from(tableDataSet)}
        stateKey="PrizeMeterReadListTable"
        stickyHeader
        header={
          <TableHead sx={{ whiteSpace: "nowrap" }}>
            <TableRow sx={{ th: { p: 1 } }}>
              <ExtTableCell border sticky fixedWidth={200} zIndex={100}>
                プライズ機種名
              </ExtTableCell>
              <ExtTableCell border>ブース名</ExtTableCell>
              <ExtTableCell border align="left" sx={{ textAlign: "left" }}>
                シンカクラウド
                <wbr />
                連携
              </ExtTableCell>
              <ExtTableCell border fixedWidth={120}>
                10円
                <wbr />
                (ソフトM日計)
              </ExtTableCell>
              <ExtTableCell border fixedWidth={120}>
                100円
                <wbr />
                (ソフトM日計)
              </ExtTableCell>
              <ExtTableCell border fixedWidth={120}>
                500円
                <wbr />
                (ソフトM日計)
              </ExtTableCell>
              <ExtTableCell border fixedWidth={120}>
                プライズ
                <wbr />
                (ソフトM日計)
              </ExtTableCell>
              <ExtTableCell border fixedWidth={120}>
                10円
                <wbr />
                (アナログM累計)
              </ExtTableCell>
              <ExtTableCell border fixedWidth={120}>
                100円
                <wbr />
                (アナログM累計)
              </ExtTableCell>
              <ExtTableCell border fixedWidth={120}>
                500円
                <wbr />
                (アナログM累計)
              </ExtTableCell>
              <ExtTableCell border fixedWidth={120}>
                プライズ
                <wbr />
                (アナログM累計)
              </ExtTableCell>
              <ExtTableCell border fixedWidth={125} align="center">
                P/O管理方法
              </ExtTableCell>
              <ExtTableCell border fixedWidth={120} align="center">
                見なしP/O
              </ExtTableCell>
              <ExtTableCell border fixedWidth={74} align="center">
                故障
              </ExtTableCell>
              <ExtTableCell border fixedWidth={74} align="center">
                撤去
              </ExtTableCell>
            </TableRow>
          </TableHead>
        }
        renderRow={([boothNameWithoutSeatNumber, tableRowData]) => {
          const seats = Array.from(tableRowData)
          const numberOfSeats = seats.length
          const isShowAlert = seats.some(([, { meter }]) => {
            return (
              meter.thincaTerminalNumber == "" &&
              ((meter.softYen100CoinCount || 0) >= 300 ||
                (meter.softYen500CoinCount || 0) >= 300 ||
                (meter.softPayout || 0) >= 300)
            )
          })
          return (
            <Fragment key={boothNameWithoutSeatNumber}>
              {seats.map(([seatNumber, { booth, meter }], index) => {
                const isEditable = booth.isAvailable && !isDisableEditByDate
                return (
                  <TableRow key={seatNumber} sx={{ td: { p: 1 } }}>
                    {index === 0 && (
                      <>
                        <ExtTableCell
                          border
                          sticky
                          zIndex={99}
                          rowSpan={numberOfSeats}
                        >
                          <Stack
                            sx={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Stack
                              sx={{
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              {isShowAlert && (
                                <ReportProblemOutlined
                                  sx={{ color: "warning.main" }}
                                />
                              )}
                              <Stack
                                sx={{
                                  ...(isShowAlert && {
                                    color: "warning.main",
                                  }),
                                }}
                              >
                                <Link
                                  to={`/arcades/${arcadeCd}/prizes/meterRead/show/${booth.machineName}-${booth.machineNumber.toString().padStart(3, "0")}`}
                                  underline="none"
                                  component={RouterLink}
                                >
                                  {boothNameWithoutSeatNumber}
                                </Link>
                              </Stack>
                            </Stack>

                            <Stack>
                              <IconButton
                                aria-label="edit"
                                color="primary"
                                disabled={!isEditable}
                                onClick={() =>
                                  isEditable &&
                                  navigate(
                                    `/arcades/${arcadeCd}/prizes/meterRead/edit/${boothNameWithoutSeatNumber}?selected_date=${selectedDate}`,
                                  )
                                }
                              >
                                <Edit fontSize="small" />
                              </IconButton>
                            </Stack>
                          </Stack>
                          {isShowAlert && (
                            <Typography variant="caption" color="warning.main">
                              ソフト値が大きい数値です。メーター値を再確認し、入力ミスの場合は修正してください
                            </Typography>
                          )}
                        </ExtTableCell>
                      </>
                    )}

                    <ExtTableCell border>{seatNumber}P</ExtTableCell>
                    <ExtTableCell border>
                      {meter.thincaTerminalNumber ? "連携" : "-"}
                    </ExtTableCell>
                    <ExtTableCell border sx={{ textAlign: "end" }}>
                      {meter.softYen10CoinCount ?? "-"}
                    </ExtTableCell>
                    <ExtTableCell border sx={{ textAlign: "end" }}>
                      {meter.softYen100CoinCount ?? "-"}
                    </ExtTableCell>
                    <ExtTableCell border sx={{ textAlign: "end" }}>
                      {meter.softYen500CoinCount ?? "-"}
                    </ExtTableCell>
                    <ExtTableCell border sx={{ textAlign: "end" }}>
                      {meter.softPayout ?? "-"}
                    </ExtTableCell>
                    <ExtTableCell border sx={{ textAlign: "end" }}>
                      {meter.yen10CoinCount ?? "-"}
                    </ExtTableCell>
                    <ExtTableCell border sx={{ textAlign: "end" }}>
                      {meter.yen100CoinCount ?? "-"}
                    </ExtTableCell>
                    <ExtTableCell border sx={{ textAlign: "end" }}>
                      {meter.yen500CoinCount ?? "-"}
                    </ExtTableCell>
                    <ExtTableCell border sx={{ textAlign: "end" }}>
                      {meter.payout ?? "-"}
                    </ExtTableCell>
                    <ExtTableCell border sx={{ whiteSpace: "nowrap" }}>
                      {meter.payoutCategory === "payout_out_meter" &&
                        "Pアウトメータ"}
                      {meter.payoutCategory === "assumed_payout_rate" &&
                        "見なしP/O"}
                      {meter.payoutCategory === undefined && "-"}
                    </ExtTableCell>
                    <ExtTableCell border sx={{ textAlign: "end" }}>
                      {getRatioLabel(meter.assumedPayoutRate)}
                    </ExtTableCell>
                    <ExtTableCell border sx={{ whiteSpace: "nowrap" }}>
                      {meter.isBroken ? (
                        <Typography color="error" variant="body2">
                          故障中
                        </Typography>
                      ) : (
                        "-"
                      )}
                    </ExtTableCell>
                    {index === 0 && (
                      <ExtTableCell
                        border
                        sx={{ whiteSpace: "nowrap" }}
                        rowSpan={numberOfSeats}
                      >
                        {!booth.isAvailable && "撤去済"}
                      </ExtTableCell>
                    )}
                  </TableRow>
                )
              })}
            </Fragment>
          )
        }}
      />
    </Stack>
  )
}

const PrizeMeterReadFloorMap: React.FC<{
  selectedDate: DateLabelString
  isDisableEditByDate?: boolean
}> = ({ selectedDate, isDisableEditByDate }) => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  const floorMapPoints = useResource({
    subject: "フロアマップの取得",
    fetch: arcadeCd ? () => getPrizeFloorMap(arcadeCd) : undefined,
    recoilKey: `getPrizeFloorMap:${arcadeCd}`,
  }).resource?.data.floorMapPoints

  const prizeMeterReads = useResource({
    subject: "メーターリード結果の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeMeterReads(arcadeCd, {
            from: selectedDate,
            to: selectedDate,
          })
      : undefined,
    recoilKey: `getPrizeMeterReads:${arcadeCd}:${selectedDate}`,
  }).resource?.data.booths

  const prizeMeterReadFloorMapPoints = useMemo(() => {
    return (floorMapPoints || []).map((point) =>
      convertMeterReadFloorMapPoint({
        point,
        prizeMeterReads: prizeMeterReads || [],
      }),
    )
  }, [floorMapPoints, prizeMeterReads])

  const onClickPoint = useCallback(
    (point: PrizeMeterReadFloorMapPoint) => {
      if (!point.booth) return
      const boothNameWithoutSeatNumber = getBoothNameWithoutSeatNumber(
        point.booth,
      )
      navigate(
        `/arcades/${arcadeCd}/prizes/meterRead/edit/${boothNameWithoutSeatNumber}?selected_date=${selectedDate}`,
      )
    },
    [arcadeCd, selectedDate, navigate],
  )

  return (
    <PrizeMeterReadFloorMapBox
      points={prizeMeterReadFloorMapPoints}
      isDisableEditByDate={isDisableEditByDate}
      onClickPoint={onClickPoint}
    />
  )
}
