import { useMemo, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { Add, Delete, Edit } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Typography,
  Button,
  DialogContent,
  TextField,
  Stack,
  IconButton,
  TableRow,
} from "@mui/material"
import {
  Controller,
  SubmitHandler,
  useForm,
  UseFormReturn,
  useWatch,
} from "react-hook-form"
import * as Yup from "yup"

import { getMachineCategories } from "src/api/machine-categories"
import { deleteMachine, getMachines, putMachine } from "src/api/machines"
import { Machine, MachineCategory, PutMachineRequest } from "src/api/models"
import { BackButton } from "src/components/atoms/BackButton"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { SearchAutoComplete } from "src/components/molecules/SearchAutoComplete"
import { DeleteModal } from "src/components/organisms/DeleteModal"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { useUserRole } from "src/hooks/useUserRole"

export const Machines = () => {
  return (
    <MainContentLayout
      title="プライズ機種一覧"
      renderContent={() => <MachinesInner />}
    />
  )
}

const MachinesInner: React.FC = () => {
  const { isAdmin } = useUserRole()
  const { resource: machinesResource, refetch: refetchMachines } = useResource({
    subject: "機種リストの取得",
    fetch: () => getMachines(),
    recoilKey: `getMachines`,
  })
  const machines = useMemo(
    () => machinesResource?.data.machines || [],
    [machinesResource],
  )
  const {
    resource: machineCategoriesResource,
    refetch: refetchMachineCategories,
  } = useResource({
    subject: "機種分類リストの取得",
    fetch: () => getMachineCategories(),
    recoilKey: `getMachineCategories`,
  })
  const machineCategories = useMemo(
    () => machineCategoriesResource?.data.machineCategories || [],
    [machineCategoriesResource],
  )
  const refetch = () => {
    refetchMachines()
    refetchMachineCategories()
  }

  const [showAddModal, setShowAddModal] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [selectedMachine, setSelectedMachine] = useState<Machine | undefined>(
    undefined,
  )
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const onFinishModal = () => refetch()

  return (
    <>
      {isAdmin && (
        <Button
          variant="contained"
          sx={{ mr: 2, mb: 2, whiteSpace: "nowrap" }}
          startIcon={<Add />}
          onClick={() => setShowAddModal(true)}
        >
          追加
        </Button>
      )}

      <Stack sx={{ maxHeight: "80dvh" }}>
        <MachinesTable
          machines={machines}
          onClickEdit={(machine: Machine) => {
            setSelectedMachine(machine)
            setShowUpdateModal(true)
          }}
          onClickDelete={(machine: Machine) => {
            setSelectedMachine(machine)
            setShowDeleteModal(true)
          }}
        />
      </Stack>

      <MachineAddModal
        showModal={showAddModal}
        machines={machines}
        machineCategories={machineCategories}
        onClose={() => setShowAddModal(false)}
        onFinish={onFinishModal}
      />

      <MachineUpdateModal
        showModal={showUpdateModal}
        machine={selectedMachine}
        machines={machines}
        machineCategories={machineCategories}
        onClose={() => {
          setShowUpdateModal(false)
          setSelectedMachine(undefined)
        }}
        onFinish={onFinishModal}
      />

      <MachineDeleteModal
        showModal={showDeleteModal}
        machine={selectedMachine}
        onClose={() => {
          setShowDeleteModal(false)
          setSelectedMachine(undefined)
        }}
        onFinish={onFinishModal}
      />
    </>
  )
}

type MachinesTableProps = {
  machines: Machine[]
  onClickEdit: (machine: Machine) => void
  onClickDelete: (machine: Machine) => void
}

const MachinesTable: React.FC<MachinesTableProps> = ({
  machines,
  onClickEdit,
  onClickDelete,
}: MachinesTableProps) => {
  const { isAdmin } = useUserRole()
  return (
    <PaginatedTable
      noMargin
      scrollableY
      items={machines}
      stateKey="machines"
      renderRow={(machine) => {
        return (
          <TableRow key={machine.name} data-testid={machine.name}>
            <ExtTableCell sx={{ p: 2 }}>
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Stack gap={1}>
                  <Typography variant="subtitle1">{machine.name}</Typography>
                  <Typography variant="caption">
                    {machine.machineCategory.name}
                  </Typography>
                  {machine.usbMachineName !== "" && (
                    <Typography variant="caption">
                      USBキー本体名: {machine.usbMachineName}
                    </Typography>
                  )}
                </Stack>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  <IconButton
                    color="primary"
                    disabled={!isAdmin}
                    onClick={() => onClickEdit(machine)}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                  <IconButton
                    color="error"
                    disabled={!isAdmin}
                    onClick={() => onClickDelete(machine)}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Stack>
              </Stack>
            </ExtTableCell>
          </TableRow>
        )
      }}
    />
  )
}

type MachineFormInput = PutMachineRequest

type MachineAddModalProps = {
  showModal: boolean
  machines: Machine[]
  machineCategories: MachineCategory[]
  onClose: () => void
  onFinish?: () => void
}

export const MachineAddModal: React.FC<MachineAddModalProps> = ({
  showModal,
  machines,
  machineCategories,
  onClose,
  onFinish = () => undefined,
}) => {
  const { submitPromises } = useSubmitting()

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("必須です")
      .test(
        "should-not-be-duplicated",
        "既にその名称は登録されています",
        (value) => !machines.some((m) => m.name === value),
      ),
    machineCategoryId: Yup.number().required("必須です"),
  })

  const useFormReturn = useForm<MachineFormInput>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      machineCategoryId: 0,
      usbMachineName: undefined,
    },
  })
  const { reset } = useFormReturn

  const onSubmit: SubmitHandler<MachineFormInput> = async ({
    name,
    machineCategoryId,
  }) => {
    const request: PutMachineRequest = {
      name: name,
      machineCategoryId: machineCategoryId,
    }

    await submitPromises([
      {
        subject: "機種の追加",
        showSuccessMessage: true,
        promise: async () => {
          await putMachine(request)
          reset()
          onClose()
          onFinish()
        },
      },
    ])
  }

  return (
    <EditModalInner
      showModal={showModal}
      isEditName={true}
      machineCategories={machineCategories}
      onSubmit={onSubmit}
      onClose={() => {
        reset()
        onClose()
      }}
      useFormReturn={useFormReturn}
      title="プライズ機種を追加"
    />
  )
}

type MachineUpdateModalProps = {
  showModal: boolean
  machine?: Machine
  machines: Machine[]
  machineCategories: MachineCategory[]
  onClose: () => void
  onFinish?: () => void
}

export const MachineUpdateModal: React.FC<MachineUpdateModalProps> = ({
  showModal,
  machine,
  machines,
  machineCategories,
  onClose,
  onFinish = () => undefined,
}) => {
  const { submitPromises } = useSubmitting()

  const validationSchema = Yup.object({
    name: Yup.string()
      .required()
      .test(
        "should-not-be-duplicated",
        "既にその名称は登録されています",
        (value) =>
          !machines
            .filter((m) => machine && m.name !== machine.name)
            .some((m) => m.name === value),
      ),
    machineCategoryId: Yup.number().required("必須です"),
  })

  const useFormReturn = useForm<MachineFormInput>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: machine?.name || "",
      machineCategoryId: machine?.machineCategory.id || 0,
    },
  })
  const { setValue, reset } = useFormReturn

  useMemo(() => {
    if (machine) {
      setValue("name", machine.name)
      setValue("machineCategoryId", machine.machineCategory.id)
      setValue("usbMachineName", machine.usbMachineName)
    }
  }, [machine, setValue])

  const onSubmit: SubmitHandler<MachineFormInput> = async ({
    machineCategoryId,
    usbMachineName,
  }) => {
    if (!machine) return
    const request: PutMachineRequest = {
      name: machine.name,
      machineCategoryId: machineCategoryId,
      usbMachineName: usbMachineName,
    }

    await submitPromises([
      {
        subject: "機種の変更",
        showSuccessMessage: true,
        promise: async () => {
          await putMachine(request)
          reset()
          onClose()
          onFinish()
        },
      },
    ])
  }

  return (
    <EditModalInner
      showModal={showModal}
      isEditName={false}
      machineCategories={machineCategories}
      onSubmit={onSubmit}
      onClose={() => {
        reset()
        onClose()
      }}
      useFormReturn={useFormReturn}
      title="プライズ機種編集"
    />
  )
}

type EditModalInnerProps = {
  showModal: boolean
  isEditName: boolean
  machineCategories: MachineCategory[]
  onSubmit: SubmitHandler<MachineFormInput>
  onClose: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useFormReturn: UseFormReturn<MachineFormInput, any>
  title: string
}

const EditModalInner: React.FC<EditModalInnerProps> = ({
  showModal,
  isEditName,
  machineCategories,
  onSubmit,
  onClose,
  useFormReturn,
  title,
}) => {
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
  } = useFormReturn

  const name = useWatch({ control, name: "name" })

  return (
    <CustomDialog fullWidth maxWidth="sm" open={showModal} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitleWithClose
          sx={{ textAlign: "left", mt: 1 }}
          variant="h2"
          onClose={onClose}
        >
          <Typography variant="h2">{title}</Typography>
        </DialogTitleWithClose>
        <DialogContent>
          <Stack gap={1}>
            {/* 機種名 */}
            {isEditName ? (
              <>
                <Typography
                  variant="h3"
                  sx={(theme) => ({ color: theme.palette.gray[50] })}
                >
                  機種名
                </Typography>
                <Controller
                  control={control}
                  name={"name"}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!errors.name?.message}
                      helperText={errors.name?.message}
                    />
                  )}
                />
              </>
            ) : (
              <Typography variant="h3">{name}</Typography>
            )}

            {/* USBキー本体名 */}
            <Typography
              variant="body2"
              sx={(theme) => ({ color: theme.palette.gray[50] })}
            >
              USBキー本体名
            </Typography>
            <Controller
              control={control}
              name={"usbMachineName"}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!errors.usbMachineName?.message}
                  helperText={errors.usbMachineName?.message}
                />
              )}
            />

            {/* 機種分類 */}
            <Typography
              variant="body2"
              sx={(theme) => ({ color: theme.palette.gray[50] })}
            >
              機種分類
            </Typography>
            <Controller
              control={control}
              name={"machineCategoryId"}
              render={({ field, fieldState }) => (
                <SearchAutoComplete
                  items={machineCategories.map(({ id, name }) => ({
                    label: name,
                    value: id,
                  }))}
                  {...field}
                  value={field.value}
                  error={!!fieldState.error}
                />
              )}
            />
          </Stack>
          <Typography variant="subtitle2" color="error" sx={{ mt: 2 }}>
            必ずMachineDB.xlsxと一致するように、MachineDB.xlsxも変更して下さい
          </Typography>
        </DialogContent>
        <CustomDialogActions>
          <BackButton onClick={() => onClose()}>保存せず戻る</BackButton>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSubmitting}
            fullWidth
          >
            保存
          </LoadingButton>
        </CustomDialogActions>
      </form>
    </CustomDialog>
  )
}

type MachineDeleteModalProps = {
  showModal: boolean
  machine?: Machine
  onClose: () => void
  onFinish?: () => void
}

export const MachineDeleteModal: React.FC<MachineDeleteModalProps> = ({
  showModal,
  machine,
  onClose,
  onFinish = () => undefined,
}) => {
  const { submitPromises } = useSubmitting()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onClickDelete = async () => {
    if (!machine) return
    setIsSubmitting(true)
    await submitPromises([
      {
        subject: "機種の削除",
        showSuccessMessage: true,
        promise: async () => {
          await deleteMachine(machine.name)
          onClose()
          onFinish()
        },
      },
    ])
    setIsSubmitting(false)
  }

  if (machine) {
    // NOTE: onClose 時に machine が undefined になるので、message のちらつきを防止
    return (
      <DeleteModal
        showModal={showModal}
        onSubmit={onClickDelete}
        onClose={onClose}
        isSubmitting={isSubmitting}
        message={`「${machine.name}」を削除しますか？`}
      />
    )
  } else {
    return <></>
  }
}
