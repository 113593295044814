import { TextField, MenuItem } from "@mui/material"
import Select from "@mui/material/Select"
import { useRecoilState } from "recoil"

import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { SearchAutoComplete } from "src/components/molecules/SearchAutoComplete"
import {
  placementTypeNames,
  placementTypes,
  placementValidationNames,
  placementValidations,
} from "src/domains/prizes/inventoryExecuteRepository"
import {
  InventoryPrizePlacementsSearchParams,
  Placement,
} from "src/pages/prizes/placements/InventoryPrizePlacements"
import { inventoryPlacementsSearchParamsState } from "src/recoil/inventory"
import { generateFuzzyRegExp } from "src/utils"

export const InventoryPrizePlacementsFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useRecoilState(
    inventoryPlacementsSearchParamsState,
  )

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み"
      defaultExpanded={
        !!(
          searchParams.name ||
          searchParams.placementType ||
          searchParams.placementValidation
        )
      }
      formInputs={[
        {
          name: "placementType",
          label: "区分",
          render: ({ field, fieldState: { error } }) => (
            <SearchAutoComplete
              items={placementTypes.map((placementType) => ({
                label: placementTypeNames[placementType],
                value: placementType,
              }))}
              defaultValue={
                placementTypes.filter(
                  (placementType) =>
                    placementType === searchParams.placementType,
                )[0]
              }
              inputPlaceholder="プライズ機外／内／上"
              {...field}
              error={!!error}
            />
          ),
        },
        {
          name: "name",
          label: "名前",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              inputProps={{ "aria-label": "name" }}
            />
          ),
        },
        {
          name: "placementValidation",
          label: "有効・無効",
          render: ({ field }) => (
            <Select
              {...field}
              value={field.value ?? ""}
              inputProps={{ "aria-label": "placementValidation" }}
            >
              {placementValidations.map((key) => (
                <MenuItem value={key} key={key}>
                  {placementValidationNames[key]}
                </MenuItem>
              ))}
            </Select>
          ),
        },
      ]}
    />
  )
}

export const filterPlacements = (
  placements: Placement[],
  params: InventoryPrizePlacementsSearchParams,
) => {
  const { name, placementType, placementValidation } = params

  return placements
    .filter((p) => (name ? generateFuzzyRegExp(name).test(p.name) : true))
    .filter((p) => (placementType ? p.placementType === placementType : true))
    .filter((p) => {
      if (!placementValidation || placementValidation === "all") return true
      if (placementValidation === (p.isAvailable ? "valid" : "invalid")) {
        return true
      }
      return false
    })
}
