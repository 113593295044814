import { PrizeMeterRead } from "src/api/models"

export function getMeterReadHasInput(meter: PrizeMeterRead | undefined) {
  if (!meter) return false

  return (
    meter.softYen10CoinCount != null ||
    meter.softYen100CoinCount != null ||
    meter.softYen500CoinCount != null
  )
}
