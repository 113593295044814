import { useCallback } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import {
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import * as Yup from "yup"

import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"

const validationSchema = Yup.object({
  count: Yup.number()
    .required("必須です")
    .typeError("数値を入力してください")
    .min(0, "0以上の数値を入力してください")
    .test("max-count", "移動できる個数を越えています", (value, context) => {
      const maxCount = context.options.context?.maxCount
      return typeof maxCount !== "number" || value <= maxCount
    }),
})

const resolver = yupResolver(validationSchema)

type FormValues = {
  count: number
}

export type OnSubmitBulkInputMoveCount = SubmitHandler<FormValues>

type Props = {
  open: boolean
  maxCount?: number
  onClose?: () => void
  onSubmit?: OnSubmitBulkInputMoveCount
}

export const BulkInputMoveCountModal: React.FC<Props> = ({
  open,
  maxCount,
  onClose,
  onSubmit,
}) => {
  const { control, handleSubmit } = useForm({
    resolver,
    defaultValues: { count: 0 },
    context: { maxCount },
  })

  const _onSubmit: OnSubmitBulkInputMoveCount = useCallback(
    async (data) => {
      await onSubmit?.(data)
      onClose?.()
    },
    [onSubmit, onClose],
  )

  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="body1">
          一括入力する個数を入力してください
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems="center" sx={{ p: 2 }}>
          <Grid item xs="auto">
            <Typography variant="body2" width={100}>
              移動する数
            </Typography>
          </Grid>

          <Grid
            item
            xs
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap={1}
          >
            <Controller
              control={control}
              name="count"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="number"
                  error={error != null}
                  helperText={error?.message}
                  inputProps={{ inputMode: "numeric" }}
                />
              )}
            />
            <Typography variant="body2">個</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <CustomDialogActions>
        {/* form がネストすると意図しない挙動になるので form を使わずに onClick で submit */}
        <Button fullWidth variant="contained" onClick={handleSubmit(_onSubmit)}>
          一括入力する
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}
