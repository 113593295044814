import { useMemo } from "react"

import { Box, Link, Divider, Drawer, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Link as RouterLink, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import gigoNaviLogoImage from "src/assets/gigo_navi_logo.png"
import { ConfidentialAlert } from "src/components/molecules/ConfidentialAlert"
import { NavItem } from "src/components/molecules/NavItem"
import { useBreakpoints } from "src/hooks/useBreakpoints"
import { useUserRole } from "src/hooks/useUserRole"
import { currentArcadeState } from "src/recoil"

function createGeneralMenuItems({
  isViewableArcadeSearch,
}: {
  isViewableArcadeSearch: boolean
}) {
  return [
    ...(isViewableArcadeSearch
      ? [
          {
            href: "/arcades/search",
            title: "店舗検索",
          },
        ]
      : []),
    {
      href: "/qr",
      title: "二次元コード読み取り",
    },
  ]
}

function createManagementMenuItems({
  isViewableManagementMenu,
  isViewableLastExecutionPeriods,
}: {
  isViewableManagementMenu: boolean
  isViewableLastExecutionPeriods: boolean
}) {
  // TODO 権限表と合わせて棚卸確定ステータスをChief以上でも閲覧可能にする
  // https://genda-group.atlassian.net/wiki/spaces/GGE/pages/67305575/v2
  // Routeも合わせて修正する必要がある
  return [
    ...(isViewableManagementMenu
      ? [
          {
            href: "/users",
            title: "ユーザー管理",
          },
        ]
      : []),
    ...(isViewableLastExecutionPeriods
      ? [
          {
            href: "/lastExecutionPeriods",
            title: "棚卸確定ステータス",
          },
        ]
      : []),
    {
      href: "/settings/prizes/machines",
      title: "プライズ機種一覧",
    },
    {
      href: "/settings/prizes/machineCategories",
      title: "プライズ機種分類一覧",
    },
    {
      href: "/settings/prizes/fieldSettings",
      title: "プライズ機フィールド設定一覧",
    },
    {
      href: "/settings/prizes/fees",
      title: "プライズ機料金設定一覧",
    },
  ]
}

function createArcadeMenuItems({
  arcadeCd,
  isLocationTest,
  isViewableManagementMenu,
}: {
  arcadeCd: string | undefined
  isLocationTest: boolean | undefined
  isViewableManagementMenu: boolean
}) {
  if (!arcadeCd) return []

  return [
    {
      href: `/arcades/${arcadeCd}`,
      title: "店舗トップ",
    },
    {
      href: `/arcades/${arcadeCd}/prizes`,
      title: "景品業務",
    },
    {
      href: `/arcades/${arcadeCd}/materials`,
      title: "材料業務",
    },
    {
      href: `/arcades/${arcadeCd}/inventory`,
      title: "棚卸業務",
    },
    {
      href: `/arcades/${arcadeCd}/users`,
      title: "店舗所属ユーザー",
    },
    {
      href: `/arcades/${arcadeCd}/amMachines`,
      title: "AM機器一覧",
    },
    {
      href: `/arcades/${arcadeCd}/prizes/settings/booths`,
      title: "プライズ機ブース一覧",
    },
    {
      href: `/arcades/${arcadeCd}/prizes/settings/boothCategories`,
      title: "プライズ機ブース区分一覧",
    },
    ...(isViewableManagementMenu
      ? [
          {
            href: `/arcades/${arcadeCd}/prizes/meterRead/init`,
            title: "プライズ売上情報の初期設定",
          },
        ]
      : []),
  ]
}

const NavTitle = styled(Typography)({
  typography: "h3",
  padding: 16,
})

interface SideBarProps {
  open: boolean
  onClose: () => void
}

const backgroundColor =
  IS_DEBUG || IS_VADDY || IS_STAGING ? "neutral.600" : "neutral.900"

export const SideBar: React.FC<SideBarProps> = ({
  open,
  onClose,
}: SideBarProps) => {
  const { isPc } = useBreakpoints()
  const { arcadeCd } = useParams()
  const arcade = useRecoilValue(currentArcadeState)
  const { isLocationTest } = arcade || {}
  const {
    isViewableManagementMenu,
    isViewableArcadeSearch,
    isViewableLastExecutionPeriods,
    isAdmin,
  } = useUserRole()

  const generalMenuItems = useMemo(
    () => createGeneralMenuItems({ isViewableArcadeSearch }),
    [isViewableArcadeSearch],
  )

  const managementMenuItems = useMemo(
    () =>
      createManagementMenuItems({
        isViewableManagementMenu,
        isViewableLastExecutionPeriods,
      }),
    [isViewableManagementMenu, isViewableLastExecutionPeriods],
  )

  const arcadeMenuItems = useMemo(
    () =>
      createArcadeMenuItems({
        arcadeCd,
        isLocationTest,
        isViewableManagementMenu,
      }),
    [arcadeCd, isLocationTest, isViewableManagementMenu],
  )

  const content = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div>
          <Box sx={{ p: 1, pt: 2 }}>
            <Link
              component={RouterLink}
              to="/my-page"
              underline="none"
              onClick={() => onClose()}
            >
              <img
                src={gigoNaviLogoImage}
                alt="GiGO NAVI"
                style={{ height: 40 }}
              />
            </Link>

            {(IS_DEBUG || IS_VADDY || IS_STAGING) && (
              <ConfidentialAlert short />
            )}
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: "#2D3748",
          }}
        />
        {arcadeMenuItems.length > 0 && (
          <>
            <NavTitle>{arcade?.name}</NavTitle>
            {arcadeMenuItems.map((item) => (
              <NavItem
                key={item.title}
                icon={null}
                href={item.href}
                title={item.title}
                onClick={() => onClose()}
              />
            ))}
            <Divider sx={{ borderColor: "#2D3748" }} />
          </>
        )}

        <NavTitle>一般メニュー</NavTitle>
        {generalMenuItems.map((item) => (
          <NavItem
            key={item.title}
            icon={null}
            href={item.href}
            title={item.title}
            onClick={() => onClose()}
          />
        ))}

        {managementMenuItems.length > 0 && (
          <>
            <Divider sx={{ borderColor: "#2D3748" }} />
            <NavTitle>管理・設定</NavTitle>
            {managementMenuItems.map((item) => (
              <NavItem
                key={item.title}
                icon={null}
                href={item.href}
                title={item.title}
                onClick={() => onClose()}
              />
            ))}
            {/* {isAdmin && (
              <NavItem
                icon={null}
                href="/notices"
                title="お知らせ管理"
                onClick={() => onClose()}
              />
            )} */}
          </>
        )}
      </Box>
    </>
  )

  if (isPc) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor,
            color: "#FFFFFF",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    )
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor,
          color: "#FFFFFF",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  )
}
