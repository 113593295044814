import { Close } from "@mui/icons-material"
import {
  DialogTitleProps,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material"

type Props = DialogTitleProps & {
  onClose?: () => void
}

export const CustomDialogTitle: React.FC<Props> = ({
  children,
  onClose,
  ...rest
}) => {
  return (
    <DialogTitle component="div" {...rest}>
      <Typography variant="h2" sx={{ pt: 0.5, pr: 2 }}>
        {children}
      </Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      )}
    </DialogTitle>
  )
}
