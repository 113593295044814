import { Suspense, useEffect } from "react"

import { CircularProgress, Stack, Typography } from "@mui/material"
import { useDebounce } from "use-debounce"

import { getPrize } from "src/api/prizes"
import { CustomAlert } from "src/components/molecules/CustomAlert"
import { useResource } from "src/hooks/useResource"

type Props = {
  arcadeCd: string | undefined
  prizeCd: string | undefined | null
  onResult?: (prizeName: string | null) => void
  delayMs?: number
}

export const PrizeNameByPrizeCd: React.FC<Props> = ({
  arcadeCd,
  prizeCd,
  onResult,
  delayMs = 800,
}) => {
  return (
    <Suspense fallback={<PrizeNameByPrizeCdLoading />}>
      <PrizeNameByPrizeCdInner
        arcadeCd={arcadeCd}
        prizeCd={prizeCd}
        onResult={onResult}
        delayMs={delayMs}
      />
    </Suspense>
  )
}

const PrizeNameByPrizeCdInner: React.FC<Props> = ({
  arcadeCd,
  prizeCd,
  onResult,
  delayMs = 800,
}) => {
  const trimmedPrizeCd = (prizeCd || "").trim()
  const [debouncedPrizeCd] = useDebounce(trimmedPrizeCd, delayMs)

  const isDebouncing = debouncedPrizeCd !== trimmedPrizeCd

  const { status, resource } = useResource({
    subject: "景品名の取得",
    fetch:
      arcadeCd && debouncedPrizeCd
        ? () => getPrize(arcadeCd, debouncedPrizeCd)
        : undefined,
    skip: !arcadeCd || !debouncedPrizeCd,
    recoilKey: `getPrize:${arcadeCd}:${debouncedPrizeCd}`,
    hideErrorMessage: true,
  })

  useEffect(() => {
    if (onResult) {
      if (!isDebouncing && status === "success" && resource) {
        onResult(resource.data.prize.prizeName)
      } else {
        onResult(null)
      }
    }
  }, [isDebouncing, status, resource, onResult])

  // 景品CDが空の場合は検索が必要ないので debounce せずにすぐ空表示にする
  if (!trimmedPrizeCd) {
    return <PrizeNameByPrizeCdResult>-</PrizeNameByPrizeCdResult>
  }

  // ロード中
  if (isDebouncing || status === "fetching") {
    return <PrizeNameByPrizeCdLoading />
  }

  // エラー
  if (status === "error") {
    // TODO: 景品が見つからなかった場合とそれ以外でメッセージを変える
    return <PrizeNameByPrizeCdError />
  }

  // 景品名が取得できた場合
  if (status === "success" && resource) {
    return (
      <PrizeNameByPrizeCdResult>
        {resource.data.prize.prizeName}
      </PrizeNameByPrizeCdResult>
    )
  }

  // ここに入ってくることはないはずだが念の為空表示にしておく
  return <PrizeNameByPrizeCdResult>-</PrizeNameByPrizeCdResult>
}

const PrizeNameByPrizeCdLoading: React.FC = () => {
  return (
    <Stack p={1} alignItems="center" justifyContent="center">
      <CircularProgress size={32} />
    </Stack>
  )
}

const PrizeNameByPrizeCdError: React.FC = () => {
  return (
    <CustomAlert severity="error">
      <Typography variant="body2">
        指定の景品CDでは景品が見つかりません
      </Typography>
    </CustomAlert>
  )
}

const PrizeNameByPrizeCdResult: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return <Typography variant="body1">{children}</Typography>
}
