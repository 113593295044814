import { Button, Stack } from "@mui/material"
import { Link as RouterLink, useParams } from "react-router-dom"

import { InventoryPrizeExecuteBoothFloorMap } from "src/components/organisms/prizes/InventoryPrizeExecuteBoothFloorMap"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { PlacementType } from "src/domains/prizes/placementStatusRepository"

export const InventoryPrizeExecuteOnBoothFloorMap: React.FC = () => {
  const { arcadeCd } = useParams()

  return (
    <MainContentLayout
      title="在庫カウント(プライズ機[上])"
      renderContent={() => (
        <Stack gap={2}>
          <InventoryPrizeExecuteBoothFloorMap
            placementType={PlacementType.OnBooth}
          />
          <Button
            variant="outlined"
            fullWidth
            component={RouterLink}
            to={`/arcades/${arcadeCd}/inventory/prizes/execute/onBooth/add`}
          >
            景品を検索してブース上に登録
          </Button>
        </Stack>
      )}
    />
  )
}
