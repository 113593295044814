import React, { SetStateAction, useState } from "react"

import { LoadingButton } from "@mui/lab"
import {
  Typography,
  Stack,
  TableHead,
  TableRow,
  Link,
  TextField,
  Button,
  DialogContent,
  Box,
  Grid,
} from "@mui/material"
import { useForm } from "react-hook-form"
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { Prize, PrizeInventoryEstimation } from "src/api/models"
import { getPrizeInventoryEstimations } from "src/api/prize-inventory-estimations"
import { postPrizeDailyPlans } from "src/api/prize-plans"
import { BackButton } from "src/components/atoms/BackButton"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { findCurrentFeatureExecutionPeriod } from "src/domains/inventoryExecutionPeriodRepository"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import {
  currentInventoryPeriodState,
  filterAccordionSearchState,
} from "src/recoil"

export const InventoryPrizeEstimationsVerify: React.FC = () => {
  return (
    <MainContentLayout
      title="カウントなし異常データ確認"
      renderFilter={() => <InventoryPrizeEstimationsVerifyFilter />}
      renderContent={() => <InventoryPrizeEstimationsVerifyInner />}
    />
  )
}

type InventoryPrizeEstimationsVerifySearchParams = {
  prizeName?: string
  prizeCd?: string
  prizeCdSeams?: string
  prizeNameKana?: string // Prize["prizeNameKana"]
  makerName?: Prize["makerName"]
  ipName?: Prize["ipName"]
}

const defaultSearchParams: InventoryPrizeEstimationsVerifySearchParams = {}

const InventoryPrizeEstimationsVerifyFilter: React.FC = () => {
  const [recoilSearchParams, setRecoilSearchParams] = useRecoilState(
    filterAccordionSearchState,
  )

  const searchParams: InventoryPrizeEstimationsVerifySearchParams =
    recoilSearchParams["inventoryPrizeNoCountVerifySearchParams"] ??
    defaultSearchParams
  const setSearchParams = (
    params: SetStateAction<InventoryPrizeEstimationsVerifySearchParams>,
  ) =>
    setRecoilSearchParams((prev) => ({
      ...prev,
      inventoryPrizeNoCountVerifySearchParams: params,
    }))

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み"
      formInputs={[
        {
          name: "prizeName",
          label: "景品名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "prizeCd",
          label: "景品CD（GiGO NAVI）",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "prizeCdSeams",
          label: "景品CD（SEAMS）",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "prizeNameKana",
          label: "景品名カナ",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              fullWidth
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "makerName",
          label: "メーカー名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "ipName",
          label: "IP名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              placeholder="カタカナ部分一致"
            />
          ),
        },
      ]}
    />
  )
}

const InventoryPrizeEstimationsVerifyInner: React.FC = () => {
  const { arcadeCd } = useParams()
  const searchParams: InventoryPrizeEstimationsVerifySearchParams =
    useRecoilValue(filterAccordionSearchState)[
      "inventoryPrizeNoCountVerifySearchParams"
    ] ?? defaultSearchParams

  const { prizeCd, prizeCdSeams, prizeName, prizeNameKana, ipName, makerName } =
    searchParams

  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentPrizeExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.prizeExecutionPeriods || [],
  )
  const { resource: prizeInventoryEstimations, refetchForce } = useResource({
    subject: "棚卸の実行記録の取得",
    fetch:
      arcadeCd && currentPrizeExecutionPeriod
        ? () =>
            getPrizeInventoryEstimations(
              arcadeCd,
              currentPrizeExecutionPeriod.id,
              {
                prizeCd,
                prizeName,
                prizeNameKana,
                ipName,
                makerName,
              },
            )
        : undefined,
    recoilKey: `getPrizeInventoryEstimations:${arcadeCd}:${prizeCd}:${prizeName}:${prizeNameKana}:${ipName}:${makerName}`,
  })
  const estimations = prizeInventoryEstimations?.data.estimations || []

  const navigate = useNavigate()

  return (
    <Stack sx={{ gap: 2 }}>
      <InventoryPrizeEstimationsVerifyTable
        estimations={estimations}
        refetchForce={refetchForce}
      />
      <Button
        variant="outlined"
        onClick={() => {
          navigate(`/arcades/${arcadeCd}/inventory/prizes/no-count/adjust`)
        }}
        fullWidth
        disabled={estimations.length > 0}
      >
        棚卸カウント無し最終調整へ
      </Button>
    </Stack>
  )
}

interface InventoryPrizeEstimationsVerifyTableProps {
  estimations: PrizeInventoryEstimation[]
  refetchForce: () => Promise<void>
}

const InventoryPrizeEstimationsVerifyTable: React.FC<
  InventoryPrizeEstimationsVerifyTableProps
> = ({ estimations, refetchForce }) => {
  const { arcadeCd } = useParams()

  return (
    <Stack
      sx={{
        maxHeight: "calc(100dvh - 380px)",
      }}
    >
      <PaginatedTable
        scrollableX
        scrollableY
        stickyHeader
        noMargin
        items={estimations}
        stateKey="inventoryPrizeNoCountVerifyTable"
        header={
          <TableHead>
            <TableRow
              sx={{
                th: {
                  py: 2,
                  px: 1,
                  whiteSpace: "nowrap",
                  textAlign: "center",
                },
              }}
            >
              <ExtTableCell border sticky zIndex={100} fixedWidth={240}>
                景品名
              </ExtTableCell>
              <ExtTableCell fixedWidth={120}>
                景品CD
                <br />
                （GiGO NAVI）
              </ExtTableCell>
              <ExtTableCell fixedWidth={120}>払い出し個数</ExtTableCell>
              <ExtTableCell fixedWidth={120}>
                景品CD
                <br />
                （SEAMS）
              </ExtTableCell>
              <ExtTableCell fixedWidth={120}>SEAMSデータ個数</ExtTableCell>
            </TableRow>
          </TableHead>
        }
        renderRow={(item, i) => {
          return (
            <TableRow sx={{ td: { p: 2 } }} key={i}>
              <ExtTableCell border sticky zIndex={99}>
                <Link
                  component={RouterLink}
                  to={`/arcades/${arcadeCd}/prizes/${item.prizeCd ?? item.prizeCdsInSeams}`}
                  underline="none"
                >
                  <Typography variant="subtitle1">{item.prizeName}</Typography>
                </Link>
              </ExtTableCell>
              <ExtTableCell>{item.prizeCd}</ExtTableCell>
              <ExtTableCell>{item.estimatedStock ?? "-"}</ExtTableCell>
              <EstimationsTablePrizeCdsInSeamsCell
                estimation={item}
                tableItemPrizeCdsInSeams={item.prizeCdsInSeams}
                fetchEstimations={refetchForce}
              />
              <ExtTableCell>{item.importedStock ?? "-"}</ExtTableCell>
            </TableRow>
          )
        }}
      />
    </Stack>
  )
}

interface EstimationsTablePrizeCdsInSeamsCellProps {
  estimation: PrizeInventoryEstimation
  tableItemPrizeCdsInSeams: string[]
  fetchEstimations: () => void
}

const EstimationsTablePrizeCdsInSeamsCell: React.FC<
  EstimationsTablePrizeCdsInSeamsCellProps
> = ({ estimation, tableItemPrizeCdsInSeams, fetchEstimations }) => {
  const [showFixModal, setShowFixModal] = useState<{
    show: boolean
    prizeCdInSeams?: string
  }>({ show: false })

  const onClickPrizeCdInSeams = (prizeCdInSeams: string) => {
    setShowFixModal({
      show: true,
      prizeCdInSeams,
    })
  }

  const applyStopPropagation = (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    estimation: PrizeInventoryEstimation,
  ) => {
    if (!estimation.isInSeams) {
      e.stopPropagation()
    }
  }

  return (
    <ExtTableCell onClick={(e) => applyStopPropagation(e, estimation)}>
      <Stack gap={0.25}>
        {tableItemPrizeCdsInSeams.map((prizeCdInSeams) => (
          <Stack key={prizeCdInSeams}>
            {!estimation.isInSeams ? (
              <Button
                variant="outlined"
                color="error"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "background.paper",
                  px: 1,
                  py: 0,
                }}
                onClick={() => onClickPrizeCdInSeams(prizeCdInSeams)}
              >
                {prizeCdInSeams}
              </Button>
            ) : (
              <Box>{prizeCdInSeams}</Box>
            )}
          </Stack>
        ))}
      </Stack>

      <EstimationPrizeFixPrizeCdModal
        showModal={showFixModal.show}
        estimation={estimation}
        selectedPrizeCdInSeams={showFixModal.prizeCdInSeams}
        onClose={() => setShowFixModal({ show: false })}
        onFinish={() => fetchEstimations()}
      />
    </ExtTableCell>
  )
}

interface EstimationFixPrizeCdModalProps {
  showModal: boolean
  estimation: PrizeInventoryEstimation
  selectedPrizeCdInSeams?: string
  onClose: () => void
  onFinish?: () => void
}

const EstimationPrizeFixPrizeCdModal: React.FC<
  EstimationFixPrizeCdModalProps
> = ({
  showModal,
  estimation,
  selectedPrizeCdInSeams,
  onClose,
  onFinish = () => undefined,
}: EstimationFixPrizeCdModalProps) => {
  const { arcadeCd } = useParams()

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm()

  const { submitPromises } = useSubmitting()
  const onSubmit = async () => {
    if (!arcadeCd || !selectedPrizeCdInSeams) return
    await submitPromises([
      {
        subject: "日毎の入替計画の更新",
        showSuccessMessage: true,
        promise: async () => {
          await postPrizeDailyPlans(arcadeCd, {
            plans: estimation.planIds.map((planId) => ({
              id: planId,
              prizeCd: selectedPrizeCdInSeams,
            })),
          })
          onClose()
          onFinish()
        },
      },
    ])
  }

  return (
    <CustomDialog
      fullWidth
      maxWidth="sm"
      open={showModal}
      onClose={onClose}
      scroll="paper"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitleWithClose onClose={onClose}>
          <Typography variant="h1">
            GiGO NAVIの景品CDを書き換えますか？
          </Typography>
        </DialogTitleWithClose>

        <DialogContent>
          <Box sx={{ pt: 3 }}>
            <Grid container sx={{ textAlign: "center", fontWeight: "bold" }}>
              <Grid item xs={5}>
                <Typography variant="caption" color="neutral.600">
                  修正前
                </Typography>
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={5}>
                <Typography variant="caption" color="neutral.600">
                  修正後
                </Typography>
              </Grid>

              <Grid item xs={5}>
                {estimation.prizeCd}
              </Grid>
              <Grid item xs={2}>
                →
              </Grid>
              <Grid item xs={5} sx={{ color: "success.main" }}>
                {selectedPrizeCdInSeams}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <CustomDialogActions>
          <BackButton onClick={() => onClose()}>保存せず戻る</BackButton>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSubmitting}
            fullWidth
          >
            書き換える
          </LoadingButton>
        </CustomDialogActions>
      </form>
    </CustomDialog>
  )
}
