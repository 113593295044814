import React, { useMemo } from "react"

import { Grid, Typography, Button } from "@mui/material"
import dayjs, { Dayjs } from "dayjs"
import { useParams } from "react-router-dom"

import { CsvRow } from "src/api/models"
import { getPrizeBoothSales } from "src/api/prize-sales"
import { CsvTable } from "src/components/organisms/CsvTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useDownloadCsv } from "src/hooks/useDownloadCsv"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import {
  formatApiDate,
  getDatesBetween,
  getJpTodayDayjs,
  getMonthEnd,
  getMonthStart,
} from "src/utils"

const dateRange = (date: Dayjs | undefined) => {
  if (!date || date.day() > 7) {
    return {
      from: formatApiDate(getMonthStart()),
      to: formatApiDate(getMonthEnd()),
    }
  } else {
    // 初週は前月をデフォルトにする
    return {
      from: formatApiDate(getMonthStart(date.add(-7, "day"))),
      to: formatApiDate(getMonthEnd(date.add(-7, "day"))),
    }
  }
}

export const PrizePayoutCsvDownload = () => {
  return (
    <MainContentLayout
      title="カウント無し用CSVダウンロード"
      renderContent={() => <PrizePayoutCsvDownloadMenu />}
    />
  )
}

const PrizePayoutCsvDownloadMenu = () => {
  const { arcadeCd } = useParams()
  const { from, to } = dateRange(getJpTodayDayjs())

  const { resource } = useResource({
    subject: "変換マクロ用の売上データの取得",
    fetch: arcadeCd
      ? () => getPrizeBoothSales(arcadeCd, { from, to })
      : undefined,
    recoilKey: `getPrizeBoothSales:${arcadeCd}:${from}:${to}`,
  })
  const salesElements = useMemo(() => resource?.data.sales || [], [resource])
  const outputCsv = useMemo(() => {
    const month = dayjs(from).format("YYYY/MM")
    const dates = getDatesBetween(from, to)
    const headerRow: CsvRow = { columns: ["ArcadeCd", "Month", "PrizeCd"] }
    const rows: CsvRow[] = []

    dates.forEach((date) => {
      headerRow.columns.push(date.format("YYYY/M/D"))
    })

    const salesSet = new Map<string, Map<string, number>>() // key: PrizeCd -> RecordedAt
    salesElements.forEach((sale) => {
      if (sale.sale.prize) {
        const prizeCd = sale.sale.prize.prizeCd
        let dateSet = salesSet.get(prizeCd)
        if (!dateSet) {
          dateSet = new Map<string, number>()
        }
        const recordedAt = formatApiDate(sale.sale.recordedAt)
        dateSet.set(
          recordedAt,
          (dateSet.get(recordedAt) || 0) + (sale.sale.payout || 0),
        )
        salesSet.set(prizeCd, dateSet)
      }
    })
    salesSet.forEach((dateSet, prizeCd) => {
      const columns = [arcadeCd || "", month, prizeCd]
      dates.forEach((date) => {
        columns.push((dateSet.get(formatApiDate(date)) || 0).toString())
      })
      rows.push({ columns })
    })

    return {
      headerRow: headerRow,
      rows: rows,
    }
  }, [salesElements, arcadeCd, from, to])

  const { downloadCsv } = useDownloadCsv()

  const { isAvailablePrizePayoutCsvDownload } = useUserRole()

  return (
    <>
      {salesElements.length > 0 ? (
        <>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Typography variant="caption" sx={{ mb: 1 }}>
              Toneの代わりにつかうカウント無しファイルの払出情報です。
              <br />
              カウント無しファイル内の説明を読み、対応してください。
            </Typography>
            <CsvTable csv={outputCsv} limit={25} />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={() =>
                downloadCsv({
                  csv: outputCsv,
                  fileName: `prize_payout_output_${arcadeCd}.csv`,
                })
              }
              disabled={!isAvailablePrizePayoutCsvDownload}
            >
              CSVファイルに出力する
            </Button>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          出力対象のデータがありません。
        </Grid>
      )}
    </>
  )
}
