import { Button, Stack } from "@mui/material"
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom"

import {
  InventoryPrizeExecutePlacementListItem,
  InventoryPrizeExecuteList,
} from "src/components/organisms/prizes/InventoryPrizeExecuteList"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { PlacementType } from "src/domains/prizes/placementStatusRepository"
import { useInventoryExecuteBoothFloorMap } from "src/hooks/useInventoryExecuteBoothFloorMap"

export const InventoryPrizeExecuteInBoothList: React.FC = () => {
  const { arcadeCd } = useParams()

  return (
    <MainContentLayout
      title="在庫カウント(プライズ機[内])"
      renderContent={() => (
        <Stack gap={2}>
          <InventoryPrizeExecuteInBoothTable />
          <Button
            variant="outlined"
            fullWidth
            component={RouterLink}
            to={`/arcades/${arcadeCd}/inventory/prizes/execute/inBooth/add`}
          >
            景品を検索してブース内に登録
          </Button>
        </Stack>
      )}
    />
  )
}

const InventoryPrizeExecuteInBoothTable = () => {
  const navigate = useNavigate()
  const { arcadeCd } = useParams()

  const onClickPoint = ({
    inventoryFloorMapPointId,
    isAvailable,
  }: InventoryPrizeExecutePlacementListItem<"in_booth">) => {
    if (!isAvailable) return

    navigate(
      `/arcades/${arcadeCd}/inventory/prizes/execute/inBooth/${inventoryFloorMapPointId}`,
    )
  }

  const { placementListItems, pointGroupNames } =
    useInventoryExecuteBoothFloorMap({
      placementType: PlacementType.InBooth,
    })

  return (
    <InventoryPrizeExecuteList
      placement={PlacementType.InBooth}
      groupNames={pointGroupNames}
      listItems={placementListItems}
      handleOnClick={onClickPoint}
    />
  )
}
