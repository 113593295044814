import { forwardRef, useCallback } from "react"

import { Search } from "@mui/icons-material"
import { Autocomplete, InputAdornment, SvgIcon, TextField } from "@mui/material"

import { WordBreakAllList } from "src/components/atoms/WordBreakAllList"

type Value = string | number | undefined
export type SearchAutoCompleteItem = {
  label: string
  value: Value
}

export type SearchAutoCompleteProps = {
  onChange: (value: Value) => void
  value: Value
  defaultValue?: Value
  items: SearchAutoCompleteItem[]
  id?: string
  inputLabel?: string
  inputPlaceholder?: string
  inputHelperText?: string
  error?: boolean
  disabled?: boolean
}

export const SearchAutoComplete = forwardRef<
  HTMLElement,
  Omit<React.HTMLAttributes<HTMLDivElement>, keyof SearchAutoCompleteProps> &
    SearchAutoCompleteProps
>(
  (
    {
      onChange,
      value,
      defaultValue,
      items,
      id,
      inputLabel,
      inputPlaceholder,
      inputHelperText,
      error,
      disabled = false,
      ...autocompleteProps
    },
    ref,
  ) => {
    const getAutocompleteValue = useCallback(
      (argValue: Value) => {
        const label = items.find((item) => item.value === argValue)?.label ?? ""
        if (!label || !argValue) return null
        return {
          label,
          value: argValue,
        }
      },
      [items],
    )
    return (
      <Autocomplete
        {...autocompleteProps}
        ref={ref}
        id={id}
        value={getAutocompleteValue(value)}
        defaultValue={getAutocompleteValue(defaultValue)}
        options={items.map((item) => ({
          label: item.label,
          value: item.value,
        }))}
        renderOption={(props, option) => (
          <WordBreakAllList {...props} key={option.value}>
            {option.label}
          </WordBreakAllList>
        )}
        onChange={(_, option) => onChange(option?.value)}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField
            {...params}
            label={inputLabel}
            error={!!error}
            helperText={inputHelperText}
            placeholder={inputPlaceholder}
            sx={{ background: "white" }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon
                    sx={(theme) => ({
                      color: theme.palette.gray[40],
                    })}
                    fontSize="small"
                  >
                    <Search />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
          />
        )}
        disabled={disabled}
      />
    )
  },
)

SearchAutoComplete.displayName = "SearchAutoComplete"
