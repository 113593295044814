import {
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Stack,
  Link,
  Divider,
} from "@mui/material"
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { OutlinedMenuButton } from "src/components/atoms/OutlinedMenuButton"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { currentArcadeState } from "src/recoil"
import { getJpDateLabel } from "src/utils"

export const Arcade: React.FC = () => {
  const currentArcade = useRecoilValue(currentArcadeState)
  const navigate = useNavigate()
  return (
    <>
      {currentArcade && (
        <MainContentLayout
          title={currentArcade.name}
          renderContent={() => (
            <Stack gap={2}>
              {/* <NoticeList /> */}
              <OperationMenu />
            </Stack>
          )}
          onClickBackButton={() => navigate("/my-page")}
        />
      )}
    </>
  )
}

const noticeList = [
  {
    id: 1,
    date: "2024-01-04T00:00:00",
    title: "新機能リリースのお知らせ",
    status: "public",
    pdfLink: "/path/to/pdf",
  },
  {
    id: 2,
    date: "2024-01-05T00:00:00",
    title: "新機能リリースのお知らせ",
    status: "public",
    pdfLink: "/path/to/pdf",
  },
  {
    id: 3,
    date: "2024-01-06T00:00:00",
    title: "新機能リリースのお知らせ",
    status: "public",
    pdfLink: "/path/to/pdf",
  },
]

export const NoticeList: React.FC = () => {
  const { arcadeCd } = useParams()
  return (
    <Card
      sx={{
        p: 1,
      }}
    >
      <CardHeader title="お知らせ" />
      <CardContent>
        <Stack gap={1} divider={<Divider />}>
          {noticeList.length === 0 && (
            <Typography>現在お知らせはありません</Typography>
          )}
          {noticeList.map((notice) => (
            <Stack direction="row" gap={1} key={notice.id}>
              <Typography>{getJpDateLabel(notice.date)}</Typography>
              <Link component={"a"} href={notice.pdfLink} underline="none">
                <Typography>{notice.title}</Typography>
              </Link>
            </Stack>
          ))}
        </Stack>
        <Divider />
        <Stack direction="row" justifyContent="end" sx={{ pt: 1 }}>
          <Link
            component={RouterLink}
            to={`/arcades/${arcadeCd}/notices`}
            underline="none"
          >
            過去のお知らせ
          </Link>
        </Stack>
      </CardContent>
    </Card>
  )
}

const OperationMenu: React.FC = () => {
  const { arcadeCd } = useParams()
  return (
    <Card sx={{ p: 3, pb: 0 }}>
      <CardHeader title="業務メニュー" sx={{ p: 0 }} />
      <CardContent sx={{ p: 0, pt: 0 }}>
        <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
          <OutlinedMenuButton to={`/arcades/${arcadeCd}/prizes`}>
            <Typography variant="subtitle1">景品業務</Typography>
          </OutlinedMenuButton>
          <OutlinedMenuButton to={`/arcades/${arcadeCd}/materials`}>
            <Typography variant="subtitle1">材料業務</Typography>
          </OutlinedMenuButton>
        </Box>
        <Box sx={{ display: "flex", mt: 3 }}>
          <OutlinedMenuButton to={`/arcades/${arcadeCd}/inventory`}>
            棚卸業務
          </OutlinedMenuButton>
        </Box>
      </CardContent>
    </Card>
  )
}
