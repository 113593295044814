import { Card, CardContent, List, Typography, Stack } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"

import { MenuButton } from "src/components/atoms/MenuButton"
import { MainContentLayout } from "src/components/templates/MainContentLayout"

export const PrizeTop: React.FC = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  return (
    <MainContentLayout
      title="景品業務"
      renderContent={() => (
        <Stack gap={2}>
          <Card>
            <CardContent sx={{ pt: 3, ":last-child": { pb: 2 } }}>
              <Typography variant="h2" sx={{ mb: 1 }}>
                入庫・出庫
              </Typography>
              <List>
                <MenuButton
                  to={`/arcades/${arcadeCd}/prizes/register/deliveries`}
                >
                  <Typography>着荷予定景品一覧</Typography>
                </MenuButton>
                <MenuButton to={`/arcades/${arcadeCd}/prizes/register/accept`}>
                  <Typography>入庫検収</Typography>
                </MenuButton>
                <MenuButton
                  to={`/arcades/${arcadeCd}/prizes/register/histories/in`}
                >
                  <Typography>入庫ログ一覧</Typography>
                </MenuButton>
                <MenuButton to={`/arcades/${arcadeCd}/prizes/register/out`}>
                  <Typography>店外出庫</Typography>
                </MenuButton>
                <MenuButton
                  to={`/arcades/${arcadeCd}/prizes/register/histories/out`}
                >
                  <Typography>出庫ログ一覧</Typography>
                </MenuButton>
              </List>
            </CardContent>
          </Card>

          <Card>
            <CardContent sx={{ pt: 3, ":last-child": { pb: 2 } }}>
              <Typography variant="h2" sx={{ mb: 1 }}>
                景品検索・移動
              </Typography>
              <List>
                <MenuButton to={`/arcades/${arcadeCd}/prizes/search`}>
                  過去取扱景品一覧
                </MenuButton>
                <MenuButton
                  to={`/arcades/${arcadeCd}/prizes/move/prizeChanges`}
                >
                  当日入れ替える景品一覧
                </MenuButton>
                <MenuButton to={`/arcades/${arcadeCd}/prizes/move/temporary`}>
                  荷捌き中の景品一覧
                </MenuButton>
                <MenuButton to={`/arcades/${arcadeCd}/prizes/move/stocks`}>
                  景品一覧から入替／移動
                </MenuButton>
                <MenuButton to={`/arcades/${arcadeCd}/prizes/move/booths`}>
                  フロアマップから入替／移動
                </MenuButton>
                <MenuButton to={`/arcades/${arcadeCd}/prizes/placements`}>
                  棚・ブース一覧から入替／移動
                </MenuButton>
              </List>
            </CardContent>
          </Card>

          <Card>
            <CardContent sx={{ pt: 3, ":last-child": { pb: 2 } }}>
              <Typography variant="h2" sx={{ mb: 1 }}>
                売上実績
              </Typography>
              <List>
                <MenuButton to={`/arcades/${arcadeCd}/prizes/meterRead`}>
                  プライズ売上情報の入力・更新
                </MenuButton>
                <MenuButton
                  to={`/arcades/${arcadeCd}/prizes/sales/daily/floorMap`}
                >
                  フロアマップ
                </MenuButton>
                <MenuButton to={`/arcades/${arcadeCd}/prizes/sales/arcade`}>
                  日別のプライズ全体売上・P/O一覧
                </MenuButton>
                <MenuButton to={`/arcades/${arcadeCd}/prizes/sales/plans`}>
                  投入景品別の実績一覧
                </MenuButton>
                <MenuButton to={`/arcades/${arcadeCd}/prizes/sales/booth`}>
                  ブース別の日ごと実績一覧
                </MenuButton>
                <MenuButton to={`/arcades/${arcadeCd}/prizes/sales/ip`}>
                  IP別の売上ランキング一覧
                </MenuButton>
                <MenuButton to={`/arcades/${arcadeCd}/prizes/sales/rank`}>
                  ランク別の実績一覧
                </MenuButton>
                <MenuButton to={`/arcades/${arcadeCd}/prizes/sales/daily`}>
                  日次プライズ実績
                </MenuButton>
              </List>
            </CardContent>
          </Card>

          <Card>
            <CardContent sx={{ pt: 3, ":last-child": { pb: 2 } }}>
              <Typography variant="h2" sx={{ mb: 1 }}>
                入替・投入計画
              </Typography>
              <List>
                <MenuButton to={`/arcades/${arcadeCd}/prizes/plans/monthly`}>
                  プライズ投入計画一覧
                </MenuButton>
                <MenuButton to={`/arcades/${arcadeCd}/prizes/plans/daily`}>
                  ブース別投入景品一覧
                </MenuButton>
              </List>
            </CardContent>
          </Card>

          <Card>
            <CardContent sx={{ pt: 3, ":last-child": { pb: 2 } }}>
              <Typography variant="h2" sx={{ mb: 1 }}>
                在庫一括編集
              </Typography>
              <List>
                <MenuButton
                  to={`/arcades/${arcadeCd}/prizes/batch/stocks/output`}
                >
                  現在の在庫をCSVで出力
                </MenuButton>
                <MenuButton
                  to={`/arcades/${arcadeCd}/prizes/batch/stocks/import`}
                >
                  CSVで在庫を一括登録
                </MenuButton>
              </List>
            </CardContent>
          </Card>
        </Stack>
      )}
      onClickBackButton={() => navigate(`/arcades/${arcadeCd}`)}
    />
  )
}
