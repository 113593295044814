import { useState, useTransition } from "react"

import {
  Dialog,
  DialogContent,
  Typography,
  Grid,
  Stack,
  TextField,
  Button,
  Box,
} from "@mui/material"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"

import { getPrizes } from "src/api/prizes"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { TableBorderedRow } from "src/components/molecules/CardTableCells"
import { LoadingBox } from "src/components/molecules/LoadingBox"
import { PrizeImageBox } from "src/components/molecules/PrizeImageBox"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { useResource } from "src/hooks/useResource"

type PrizeCodeSearchModalSearchParams = {
  prizeCd?: string
  prizeName?: string
  prizeNameKana?: string
}

type PrizeCodeSearchModalProps = {
  open: boolean
  handleClose: () => void
  onSelect: (prizeCd: string) => void
}

export const PrizeCodeSearchModal: React.FC<PrizeCodeSearchModalProps> = ({
  open,
  handleClose,
  onSelect,
}) => {
  const { arcadeCd } = useParams()
  const [searchParams, setSearchParams] =
    useState<PrizeCodeSearchModalSearchParams>({
      prizeCd: undefined,
      prizeName: undefined,
      prizeNameKana: undefined,
    })
  const { register, handleSubmit } = useForm<PrizeCodeSearchModalSearchParams>()

  const { resource: prizeReturn } = useResource({
    subject: "過去取扱景品一覧の取得",
    fetch: arcadeCd ? () => getPrizes(arcadeCd, searchParams) : undefined,
    recoilKey: `getPrizes:${arcadeCd}:${JSON.stringify(searchParams)}`,
  })
  const { prizes } = prizeReturn?.data ?? {}

  const [isLoading, startTransition] = useTransition()

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xl">
      {isLoading && <LoadingBox />}
      <DialogContent sx={{ p: 3 }}>
        <Stack gap={2}>
          <Stack pb={2}>
            <Typography variant="h1">景品CD検索</Typography>
          </Stack>

          <Stack gap={1}>
            <Grid
              container
              alignItems="center"
              flexWrap={{ xs: "wrap", sm: "nowrap" }}
            >
              <Grid item xs={12} sm="auto">
                <Typography variant="body1" width={{ sm: 100 }}>
                  景品CD
                </Typography>
              </Grid>
              <Grid
                item
                xs
                display="flex"
                flexDirection="column"
                alignItems="stretch"
              >
                <TextField
                  fullWidth
                  placeholder="検索ワードを入力/部分一致"
                  {...register("prizeCd")}
                />
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              flexWrap={{ xs: "wrap", sm: "nowrap" }}
            >
              <Grid item xs={12} sm="auto">
                <Typography variant="body1" width={{ sm: 100 }}>
                  景品名
                </Typography>
              </Grid>
              <Grid
                item
                xs
                display="flex"
                flexDirection="column"
                alignItems="stretch"
              >
                <TextField
                  fullWidth
                  placeholder="検索ワードを入力/部分一致"
                  {...register("prizeName")}
                />
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              flexWrap={{ xs: "wrap", sm: "nowrap" }}
            >
              <Grid item xs={12} sm="auto">
                <Typography variant="body1" width={{ sm: 100 }}>
                  景品名カナ
                </Typography>
              </Grid>
              <Grid
                item
                xs
                display="flex"
                flexDirection="column"
                alignItems="stretch"
              >
                <TextField
                  fullWidth
                  placeholder="検索ワードを入力/部分一致"
                  {...register("prizeNameKana")}
                />
              </Grid>
            </Grid>
          </Stack>

          <Stack pb={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleSubmit((data) =>
                startTransition(() =>
                  setSearchParams({
                    prizeCd: data.prizeCd === "" ? undefined : data.prizeCd,
                    prizeName:
                      data.prizeName === "" ? undefined : data.prizeName,
                    prizeNameKana:
                      data.prizeNameKana === ""
                        ? undefined
                        : data.prizeNameKana,
                  }),
                ),
              )}
            >
              検索する
            </Button>
          </Stack>

          <Stack gap={1}>
            <Stack sx={{ flexDirection: "row" }} gap={1}>
              <Typography variant="h3" pb={1}>
                検索結果
              </Typography>
              <Typography variant="h3" pb={1}>
                {(prizes ?? []).length}件
              </Typography>
            </Stack>
            <PaginatedTable
              items={prizes ?? []}
              scrollableX
              renderRow={(prize) => {
                return (
                  <TableBorderedRow key={prize.prizeCd}>
                    <ExtTableCell sx={{ py: 2, px: 1 }}>
                      <Box
                        sx={{
                          maxWidth: 88,
                          minWidth: 44,
                          maxHeight: 88,
                          minHeight: 44,
                        }}
                      >
                        <PrizeImageBox prizeCd={prize.prizeCd} />
                      </Box>
                    </ExtTableCell>
                    <ExtTableCell sx={{ py: 2, pl: 0, pr: 1 }}>
                      <Typography variant="body2" pb={1}>
                        {prize.prizeName}
                      </Typography>
                      <Typography variant="body2">{prize.prizeCd}</Typography>
                    </ExtTableCell>
                    <ExtTableCell sx={{ py: 2, pl: 0, pr: 1 }}>
                      <Stack sx={{ alignItems: "center" }}>
                        <Button
                          variant="outlined"
                          sx={{ whiteSpace: "nowrap" }}
                          onClick={() => onSelect(prize.prizeCd)}
                        >
                          選択
                        </Button>
                      </Stack>
                    </ExtTableCell>
                  </TableBorderedRow>
                )
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
