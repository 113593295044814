import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import { Box, Typography } from "@mui/material"

import {
  FloorMapPointBox,
  FloorMapPointBoxProps,
  PrizeDailyPlanFloorMapPointBox,
} from "src/components/organisms/FloorMapBox"
import { BaseFloorMapPoint } from "src/domains/prizes/floorMapRepository"

export interface FloorMapPaperProps {
  pageSize?: "a3" | "a4"
  title: string
  points: BaseFloorMapPoint[]
  // getFloorMapPointBox?: (baseProps: FloorMapPointBoxProps) => React.ReactNode
}

export const FloorMapPaper: React.FC<FloorMapPaperProps> = ({
  pageSize = "a4",
  title,
  points,
  // getFloorMapPointBox = ({ point, offset }) => (
  //   <FloorMapPointBox key={point.id} point={point} offset={offset} />
  // ),
}) => {
  const mapContainerRef = useRef<HTMLDivElement>(null)

  const [scale, setScale] = useState<number | null>(null)

  const { mapWidth, mapHeight, offset } = useMemo(() => {
    // points の中で最小および最大の x, y 座標を計算する
    // 可読性を優先しているが points が非常に多い場合パフォーマンス的には1つのループで計算したほうがよい
    const minX = Math.min(...points.map((point) => point.topLeftX))
    const minY = Math.min(...points.map((point) => point.topLeftY))
    const maxX = Math.max(...points.map((point) => point.bottomRightX))
    const maxY = Math.max(...points.map((point) => point.bottomRightY))

    // マップがぴったり収まるように width, height, offset を計算する
    return {
      mapWidth: maxX - minX,
      mapHeight: maxY - minY,
      offset: { x: -minX, y: -minY },
    }
  }, [points])

  // mapWidth, mapHeight が container に収まるように scale をセットする
  // 実際に表示されるまで container のサイズを取得できないので useEffect を使用
  useEffect(() => {
    if (!mapContainerRef.current || !mapWidth || !mapHeight) {
      return setScale(null)
    }
    const containerRect = mapContainerRef.current.getBoundingClientRect()
    setScale(
      Math.min(
        containerRect.width / mapWidth,
        containerRect.height / mapHeight,
      ),
    )
  }, [mapWidth, mapHeight])

  const getFloorMapPointBox = useCallback(
    ({ point, offset }: FloorMapPointBoxProps) => (
      <FloorMapPointBox
        key={point.id}
        point={point}
        offset={offset}
        sx={{
          backgroundColor: "transparent",
          cursor: "pointer",
          overflow: "hidden",
        }}
      >
        <PrizeDailyPlanFloorMapPointBox point={point} />
      </FloorMapPointBox>
    ),
    [],
  )

  return (
    <Box
      sx={{
        width: pageSize === "a3" ? "420mm" : "297mm",
        height: pageSize === "a3" ? "297mm" : "210mm",
        overflow: "hidden",
        // 印刷時に余白がないと切れてしまうので余白を設定
        py: "5mm",
        px: "7mm",
        display: "flex",
        flexDirection: "column",
        // ブラウザで表示時のみ見やすいように影をつける
        "@media screen": {
          background: "white",
          boxShadow: "0 .5mm 2mm rgba(0,0,0,.3)",
          margin: "5mm",
        },
        // 「背景のグラフィック」のチェックが外れていても背景色が印刷されるようにする
        "*": {
          "-webkit-print-color-adjust": "exact",
        },
      }}
    >
      <Typography sx={{ mb: "3mm" }}>{title}</Typography>
      <Box
        ref={mapContainerRef}
        sx={{
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "relative",
            // width, height をしっかり指定しないと印刷時に position: absolute の出力が崩れる
            width: mapWidth,
            height: mapHeight,
            ...(scale != null && {
              transformOrigin: "0 0",
              transform: `scale(${scale})`,
            }),
          }}
        >
          {scale != null &&
            points.map((point) => {
              return getFloorMapPointBox({ point, offset })
            })}
        </Box>
      </Box>
    </Box>
  )
}
