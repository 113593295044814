import { forwardRef, useState } from "react"

import { Stack, TextField, TextFieldProps } from "@mui/material"

type Props = TextFieldProps & {
  maxCount?: number
}

// https://github.com/mui/material-ui/issues/12913#issuecomment-1439983117
export const TextFieldWithCount = forwardRef<HTMLDivElement, Props>(
  ({ value, defaultValue, onChange, helperText, maxCount, ...rest }, ref) => {
    const [innerValue, setInnerValue] = useState(() =>
      typeof defaultValue === "string" ? defaultValue : "",
    )

    const _value = typeof value === "string" ? value : innerValue
    const _onChange: React.ChangeEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    > = (event) => {
      onChange?.(event)
      setInnerValue(event.target.value)
    }

    // `value.length` を参照しているため絵文字が含まれる場合に正しくカウントされないが yup でも同様のロジックのためそのままとした
    // https://github.com/jquense/yup/blob/master/src/string.ts#L163
    const count = _value.length

    return (
      <TextField
        ref={ref}
        {...rest}
        value={_value}
        onChange={_onChange}
        helperText={
          <Stack
            component="span"
            direction="row"
            justifyContent="space-between"
          >
            <span>{helperText}</span>
            <span>
              {count}
              {maxCount != null ? ` / ${maxCount} ` : ""}文字
            </span>
          </Stack>
        }
      />
    )
  },
)

TextFieldWithCount.displayName = "TextFieldWithCount"
