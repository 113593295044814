import { Close } from "@mui/icons-material"
import { IconButton, TableHead, TableRow } from "@mui/material"

import { Prize, PrizeDailyPlan } from "src/api/models"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import {
  SelectablePaginatedTable,
  SELECTABLE_PAGINATED_TABLE_CHECKBOX_COL_WIDTH,
} from "src/components/organisms/SelectablePaginatedTable"
import { getJpDateLabel } from "src/utils"

export type PrizeDailyBulkInputPlansTableItem = {
  // useFieldArray で id が必要なはずだが使用されていないためコメントアウト
  // id: string
  originalId: number
} & Pick<
  PrizeDailyPlan,
  "boothCategory" | "boothName" | "setting" | "recordedAt"
> &
  Pick<Prize, "prizeCd" | "prizeName">

export const PrizeDailyBulkInputPlansTable: React.FC<{
  selectable?: boolean
  items: PrizeDailyBulkInputPlansTableItem[]
  selectedItems?: PrizeDailyBulkInputPlansTableItem[]
  onChangeSelectedItems?: (
    selectedItems: PrizeDailyBulkInputPlansTableItem[],
  ) => void
  onRemove?: (item: PrizeDailyBulkInputPlansTableItem, index: number) => void
}> = ({
  selectable,
  items,
  selectedItems = [],
  onChangeSelectedItems = () => {
    /* noop */
  },
  onRemove,
}) => {
  // カラムが二重定義になっており columns として定義して共通化することもできるが可読性を考慮してそのままにしている
  if (selectable) {
    return (
      <SelectablePaginatedTable
        scrollableX
        scrollableY
        stickyHeader
        noMargin
        items={items}
        stateKey="PrizeBoothSearchModal"
        renderHeaderCells={() => (
          <>
            <ExtTableCell
              sticky
              zIndex={4}
              fixedWidth={124}
              sx={{ left: SELECTABLE_PAGINATED_TABLE_CHECKBOX_COL_WIDTH }}
            >
              日付
            </ExtTableCell>
            <ExtTableCell
              border
              sticky
              zIndex={4}
              fixedWidth={180}
              sx={{
                left: SELECTABLE_PAGINATED_TABLE_CHECKBOX_COL_WIDTH + 124,
              }}
            >
              プライズ機種名（ブース名）
            </ExtTableCell>
            <ExtTableCell fixedWidth={162}>ブース区分</ExtTableCell>
            <ExtTableCell fixedWidth={162}>景品CD</ExtTableCell>
            <ExtTableCell fixedWidth={162}>設定</ExtTableCell>
            <ExtTableCell fixedWidth={260}>景品名</ExtTableCell>
          </>
        )}
        renderRowCells={(item) => {
          return (
            <>
              <ExtTableCell sticky zIndex={3}>
                {getJpDateLabel(item.recordedAt)}
              </ExtTableCell>
              <ExtTableCell border sticky zIndex={3}>
                {item.boothName}
              </ExtTableCell>
              <ExtTableCell>{item.boothCategory}</ExtTableCell>
              <ExtTableCell>{item.prizeCd}</ExtTableCell>
              <ExtTableCell>{item.setting}</ExtTableCell>
              <ExtTableCell>{item.prizeName}</ExtTableCell>
            </>
          )
        }}
        checkIsSameItem={(a, b) => a.originalId === b.originalId}
        selectedItems={selectedItems}
        onChangeSelectedItems={onChangeSelectedItems}
      />
    )
  }

  return (
    <PaginatedTable
      items={items}
      noMargin
      header={
        <TableHead>
          <TableRow>
            <ExtTableCell>日付</ExtTableCell>
            <ExtTableCell>プライズ機種名（ブース名）</ExtTableCell>
            <ExtTableCell>ブース区分</ExtTableCell>
            <ExtTableCell>景品CD</ExtTableCell>
            <ExtTableCell>設定</ExtTableCell>
            <ExtTableCell fixedWidth={162}>景品名</ExtTableCell>
            {onRemove && <ExtTableCell>削除</ExtTableCell>}
          </TableRow>
        </TableHead>
      }
      emptyRowProps={{ colCount: 6, text: "ブースが未選択です" }}
      renderRow={(item, index) => (
        <TableRow key={index}>
          <ExtTableCell>{getJpDateLabel(item.recordedAt)}</ExtTableCell>
          <ExtTableCell>{item.boothName}</ExtTableCell>
          <ExtTableCell>{item.boothCategory}</ExtTableCell>
          <ExtTableCell>{item.prizeCd}</ExtTableCell>
          <ExtTableCell>{item.setting}</ExtTableCell>
          <ExtTableCell>{item.prizeName}</ExtTableCell>
          {onRemove && (
            <ExtTableCell>
              <IconButton
                disableRipple
                sx={{ color: "error.main" }}
                onClick={() => onRemove(item, index)}
              >
                <Close fontSize="inherit" />
              </IconButton>
            </ExtTableCell>
          )}
        </TableRow>
      )}
    />
  )
}
