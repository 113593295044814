import { Location } from "react-router-dom"

export const pageView = (location: Location): void => {
  if (!GTAG_ID || GTAG_ID === "") return
  if (window !== undefined) {
    let pathname = location.pathname
    if (pathname.startsWith("/arcades/")) {
      const paths = pathname.split("/")
      paths[2] = ":arcadeCd"
      pathname = paths.join("/")
    }
    window.gtag("event", "page_view", {
      page_location: "http://gigonavi.analytics" + pathname,
      search: location.search,
    })
  }
}
