import { SetStateAction, useMemo } from "react"

import {
  Typography,
  Stack,
  TableRow,
  TableHead,
  Link,
  Card,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import {
  Link as RouterLink,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { atom, useRecoilState, useRecoilValue } from "recoil"

import { getPrizeBoothSales } from "src/api/prize-sales"
import { AdminExtTableCell } from "src/components/atoms/AdminExtTableHeaderCell"
import {
  DateRangePicker,
  DateRangePickerDateLabel,
} from "src/components/atoms/DateRangePicker"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  PrizeBoothDateRangeSale,
  calcPrizeBoothDateRangeSales,
} from "src/domains/prizes/prizeSalesRepository"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import { filterAccordionSearchState } from "src/recoil"
import {
  formatApiDate,
  getDatesBetween,
  getJpDateLabel,
  getJpDateLabelColor,
  getRatioLabel,
  getIsoWeekEnd,
  getIsoWeekStart,
} from "src/utils"

type PrizeWeeklyBoothSalesDateRangeLabelState =
  | DateRangePickerDateLabel
  | undefined

const defaultDateRangeLabel = (selectedDate: string | undefined) => ({
  start: formatApiDate(getIsoWeekStart(selectedDate)),
  end: formatApiDate(getIsoWeekEnd(selectedDate)),
})

const prizeWeeklyBoothSalesDateRangeLabelState =
  atom<PrizeWeeklyBoothSalesDateRangeLabelState>({
    key: "prizeWeeklyBoothSalesDateRangeLabelState",
    default: undefined,
  })

export const PrizeWeeklyBoothSales: React.FC = () => {
  const [dateRangeLabelState, setDateRangeLabel] = useRecoilState(
    prizeWeeklyBoothSalesDateRangeLabelState,
  )

  const [urlSearchParams] = useSearchParams()
  const selectedDate = urlSearchParams.get("selected_date") || undefined
  const dateRangeLabel =
    dateRangeLabelState || defaultDateRangeLabel(selectedDate)

  return (
    <MainContentLayout
      title="ブース別の日ごと実績一覧"
      renderFilter={() => (
        <Stack gap={2}>
          <Card sx={{ p: 3 }}>
            <DateRangePicker
              dateRangeLabel={dateRangeLabel}
              setDateRangeLabel={setDateRangeLabel}
              fixedRange="week"
            />
          </Card>
          <PrizeWeeklyBoothSalesFilter />
        </Stack>
      )}
      renderContent={() => (
        <PrizeWeeklyBoothSalesInner dateRangeLabel={dateRangeLabel} />
      )}
    />
  )
}

const sortByLabels = {
  salesAsc: "売上実績(昇順)",
  salesDesc: "売上実績(降順)",
} as const

type PrizeWeeklyBoothSalesFilterSearchParams = {
  boothName?: string
  boothCategory?: string
  sortBy: keyof typeof sortByLabels
}

const defaultSearchParams: PrizeWeeklyBoothSalesFilterSearchParams = {
  sortBy: "salesAsc",
}

const PrizeWeeklyBoothSalesFilter: React.FC = () => {
  const [recoilSearchParams, setRecoilSearchParams] = useRecoilState(
    filterAccordionSearchState,
  )
  const searchParams: PrizeWeeklyBoothSalesFilterSearchParams =
    recoilSearchParams["prizeWeeklyBoothSalesSearchParams"] ??
    defaultSearchParams
  const setSearchParams = (
    params: SetStateAction<PrizeWeeklyBoothSalesFilterSearchParams>,
  ) =>
    setRecoilSearchParams((prev) => ({
      ...prev,
      prizeWeeklyBoothSalesSearchParams: params,
    }))

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み・並び替え"
      formInputs={[
        {
          name: "boothName",
          label: "プライズ機種名（ブース名）",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "boothCategory",
          label: "ブース区分",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "sortBy",
          label: "並び替え",
          render: ({ field }) => (
            <Select {...field} fullWidth>
              <MenuItem key="" value="" sx={{ height: 36 }} />
              {Object.entries(sortByLabels).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          ),
        },
      ]}
    />
  )
}

type PrizeWeeklyBoothSalesInnerProps = {
  dateRangeLabel: DateRangePickerDateLabel
}

const PrizeWeeklyBoothSalesInner: React.FC<PrizeWeeklyBoothSalesInnerProps> = ({
  dateRangeLabel,
}) => {
  const { arcadeCd } = useParams()
  const { start, end } = dateRangeLabel
  const recoilSearchParams = useRecoilValue(filterAccordionSearchState)
  const searchParams: PrizeWeeklyBoothSalesFilterSearchParams =
    recoilSearchParams["prizeWeeklyBoothSalesSearchParams"] ??
    defaultSearchParams

  const prizeBoothSalesReturn = useResource({
    subject: "ブース別売上結果の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeBoothSales(arcadeCd, {
            from: start,
            to: end,
            boothName: searchParams.boothName,
            boothCategory: searchParams.boothCategory,
          })
      : undefined,
    recoilKey: `getPrizeBoothSales:${arcadeCd}:${start}:${end}:${searchParams.boothName}:${searchParams.boothCategory}`,
  }).resource
  const prizeBoothSales = useMemo(
    () => prizeBoothSalesReturn?.data.sales || [],
    [prizeBoothSalesReturn],
  )

  const sales = useMemo(
    () =>
      calcPrizeBoothDateRangeSales(prizeBoothSales).items.sort((a, b) => {
        if (searchParams.sortBy === "salesAsc") {
          return (a.sales ?? 0) - (b.sales ?? 0)
        } else if (searchParams.sortBy === "salesDesc") {
          return (b.sales ?? 0) - (a.sales ?? 0)
        }
        return 0
      }),
    [prizeBoothSales, searchParams.sortBy],
  )

  return (
    <PrizeWeeklyBoothSalesTable sales={sales} startDate={start} endDate={end} />
  )
}

interface PrizeWeeklyBoothSalesTableProps {
  sales: PrizeBoothDateRangeSale[]
  startDate: string
  endDate: string
}

const PrizeWeeklyBoothSalesTable: React.FC<PrizeWeeklyBoothSalesTableProps> = ({
  sales,
  startDate,
  endDate,
}) => {
  const stateKey = "prizeWeeklyBoothPOTable"
  const { arcadeCd } = useParams()
  const { isAdmin } = useUserRole()

  const dates = getDatesBetween(startDate, endDate)
  return (
    <Stack
      sx={{
        maxHeight: "calc(100dvh - 300px)",
      }}
    >
      <PaginatedTable
        scrollableY
        scrollableX
        stickyHeader
        noMargin
        items={sales}
        stateKey={stateKey}
        header={
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 5,
              th: { whiteSpace: "nowrap" },
            }}
          >
            <TableRow sx={{ th: { textAlign: "center" } }}>
              <ExtTableCell
                border
                sticky
                zIndex={4}
                fixedWidth={200}
                rowSpan={2}
              >
                プライズ機種名（ブース名）
              </ExtTableCell>
              <ExtTableCell border fixedWidth={120} rowSpan={2}>
                ブース区分
              </ExtTableCell>
              <ExtTableCell border fixedWidth={120} rowSpan={2}>
                景品CD
              </ExtTableCell>
              <ExtTableCell border fixedWidth={240} rowSpan={2}>
                景品名
              </ExtTableCell>
              <ExtTableCell border fixedWidth={80} rowSpan={2}>
                投入日数
              </ExtTableCell>
              <ExtTableCell border sx={{ py: 1 }} colSpan={7}>
                売上計
              </ExtTableCell>
              <ExtTableCell border fixedWidth={100} rowSpan={2}>
                売上計
              </ExtTableCell>
              <ExtTableCell border fixedWidth={100} rowSpan={2}>
                P/O
              </ExtTableCell>
              <ExtTableCell border fixedWidth={100} rowSpan={2}>
                同一機種
                <wbr />
                対ブース平均
              </ExtTableCell>
              <ExtTableCell border fixedWidth={60} rowSpan={2}>
                ランク
              </ExtTableCell>

              {isAdmin && (
                <>
                  <AdminExtTableCell
                    border
                    header
                    word="払出個数"
                    rowSpan={2}
                  />
                  <AdminExtTableCell
                    border
                    header
                    word="景品単価"
                    rowSpan={2}
                  />
                  <AdminExtTableCell border header word="景品代" rowSpan={2} />
                  <AdminExtTableCell
                    border
                    header
                    word="ブースPDPB"
                    rowSpan={2}
                  />
                  <AdminExtTableCell
                    border
                    header
                    word="同一機種PDPB"
                    rowSpan={2}
                  />
                </>
              )}
            </TableRow>
            <TableRow>
              {dates.map((date, i) => {
                return (
                  <ExtTableCell
                    key={"headerRow" + i}
                    border
                    fixedWidth={140}
                    sx={{ py: 1 }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{ color: getJpDateLabelColor(date) }}
                    >
                      {getJpDateLabel(date)}
                    </Typography>
                  </ExtTableCell>
                )
              })}
            </TableRow>
          </TableHead>
        }
        renderRow={(item, index) => {
          return (
            <TableRow
              key={"row" + index}
              sx={{
                td: {
                  px: 1,
                },
              }}
            >
              <ExtTableCell border sticky zIndex={3} fixedWidth={200}>
                <Link
                  component={RouterLink}
                  underline="none"
                  sx={{ color: "primary.main" }}
                  to={`/arcades/${arcadeCd}/prizes/sales/booth/${item.booth.boothName}`}
                >
                  <Typography variant="subtitle1">
                    {item.booth.boothName}
                  </Typography>
                </Link>
              </ExtTableCell>
              <ExtTableCell border>
                {item.latestSale?.boothCategory ?? "-"}
              </ExtTableCell>
              <ExtTableCell border>
                {item.latestSale?.prize?.prizeCd ?? "-"}
              </ExtTableCell>
              <ExtTableCell border>
                {item.latestSale?.prize?.prizeName ?? "-"}
              </ExtTableCell>
              <ExtTableCell border sx={{ textAlign: "end" }}>
                {item.count}
              </ExtTableCell>

              {dates.map((date, i) => {
                const sales = item.salesByDate.get(formatApiDate(date))
                return (
                  <ExtTableCell
                    key={"cell" + i}
                    border
                    sx={{ py: 1, textAlign: "end" }}
                  >
                    {sales?.toLocaleString() ?? "-"}
                  </ExtTableCell>
                )
              })}

              <ExtTableCell border sx={{ textAlign: "end" }}>
                {item.sales?.toLocaleString() ?? "-"}
              </ExtTableCell>
              <ExtTableCell border sx={{ textAlign: "end" }}>
                {getRatioLabel(item.payoutRate)}
              </ExtTableCell>
              <ExtTableCell border sx={{ textAlign: "end" }}>
                {getRatioLabel(item.pdpbRatio)}
              </ExtTableCell>
              <ExtTableCell border sx={{ textAlign: "center" }}>
                {item.prizeRank ?? "-"}
              </ExtTableCell>

              {isAdmin && (
                <>
                  <AdminExtTableCell
                    border
                    sx={{ textAlign: "end" }}
                    word={item.payout?.toLocaleString() ?? "-"}
                  />
                  <AdminExtTableCell
                    border
                    sx={{ textAlign: "end" }}
                    word={
                      item.latestSale?.prize?.unitPriceJpy?.toLocaleString() ??
                      "-"
                    }
                  />
                  <AdminExtTableCell
                    border
                    sx={{ textAlign: "end" }}
                    word={item.payoutPrice?.toLocaleString() ?? "-"}
                  />
                  <AdminExtTableCell
                    border
                    sx={{ textAlign: "end" }}
                    word={item.pdpb?.toLocaleString() ?? "-"}
                  />
                  <AdminExtTableCell
                    border
                    sx={{ textAlign: "end" }}
                    word={item.machinePdpb?.toLocaleString() ?? "-"}
                  />
                </>
              )}
            </TableRow>
          )
        }}
      />
    </Stack>
  )
}
