import { Suspense, useState } from "react"

import { ExpandCircleDown } from "@mui/icons-material"
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@mui/material"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useRecoilState, useRecoilValue } from "recoil"

import { getMachineCategories } from "src/api/machine-categories"
import { PrizeToneBoothInfo, Machine, MachineCategory } from "src/api/models"
import { SearchAutoComplete } from "src/components/molecules/SearchAutoComplete"
import { BoothSearchParams } from "src/components/templates/BoothsSearchTab"
import { findBoothYesterday } from "src/domains/prizes/boothRepository"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import { boothSearchParamsState } from "src/recoil"
import { generateFuzzyRegExp } from "src/utils"

export const BoothFilter: React.FC = () => {
  const boothSearchParams = useRecoilValue(boothSearchParamsState)
  const { machineCategoryId, ipName, sortBy } = boothSearchParams
  const { isViewableCurrentArcadeSales } = useUserRole()

  const [expanded, setExpanded] = useState(
    !!(machineCategoryId || ipName || sortBy),
  )

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={
          <ExpandCircleDown
            sx={(theme) => ({ color: theme.palette.gray["40"] })}
          />
        }
      >
        絞り込み{isViewableCurrentArcadeSales && "・並び替え"}
      </AccordionSummary>

      <AccordionDetails
        sx={{
          m: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Suspense
          fallback={
            <CircularProgress sx={{ display: "block", margin: "auto" }} />
          }
        >
          <BoothFilterForm />
        </Suspense>
      </AccordionDetails>
    </Accordion>
  )
}

export interface BoothFilterFormInput {
  machineCategoryId?: MachineCategory["id"]
  ipName: PrizeToneBoothInfo["ipName"]
  sortBy: "salesHigher" | "salesLower" | "payoutRateHigher" | "payoutRateLower"
}

export const sortByLabels = {
  salesHigher: "売上高い順",
  salesLower: "売上低い順",
  payoutRateHigher: "PO率高い順",
  payoutRateLower: "PO率低い順",
}

export const filterBooths = (
  boothsToday: PrizeToneBoothInfo[],
  boothsYesterday: PrizeToneBoothInfo[],
  machines: Machine[],
  params: BoothSearchParams,
) => {
  const { machineCategoryId, ipName, sortBy } = params

  const belongsToMachineCategory = (
    boothToday: PrizeToneBoothInfo,
    machineCategoryId: number,
  ): boolean => {
    const machine = machines.find(
      (machine) => boothToday.machineName === machine.name,
    )
    return machine?.machineCategory.id === machineCategoryId
  }

  const compareBy = (
    todayA: PrizeToneBoothInfo,
    todayB: PrizeToneBoothInfo,
  ) => {
    const yesterdayA = findBoothYesterday(todayA, boothsYesterday)
    const yesterdayB = findBoothYesterday(todayB, boothsYesterday)
    if (!yesterdayA || !yesterdayB) {
      return 1
    }

    if (sortBy === "salesHigher") {
      return (yesterdayA.dailySales || "") <= (yesterdayB.dailySales || "")
        ? 1
        : -1
    }
    if (sortBy === "salesLower") {
      return (yesterdayA.dailySales || "") >= (yesterdayB.dailySales || "")
        ? 1
        : -1
    }
    if (sortBy === "payoutRateHigher") {
      return (yesterdayA.dailyPayOut || "") <= (yesterdayB.dailyPayOut || "")
        ? 1
        : -1
    }
    if (sortBy === "payoutRateLower") {
      return (yesterdayA.dailyPayOut || "") >= (yesterdayB.dailyPayOut || "")
        ? 1
        : -1
    }
    return 1
  }

  return boothsToday
    .filter((boothToday) =>
      machineCategoryId
        ? belongsToMachineCategory(boothToday, machineCategoryId)
        : true,
    )
    .filter((boothToday) =>
      ipName ? generateFuzzyRegExp(ipName).test(boothToday.ipName ?? "") : true,
    )
    .sort((todayA, todayB) => compareBy(todayA, todayB))
}

export const BoothFilterForm: React.FC = () => {
  const [boothSearchParams, setBoothSearchParams] = useRecoilState(
    boothSearchParamsState,
  )
  const { isViewableCurrentArcadeSales } = useUserRole()

  const machineCategories = useResource({
    subject: "機種分類情報の取得",
    recoilKey: "getMachineCategories",
    useCache: true,
    fetch: () => getMachineCategories(),
  }).resource?.data.machineCategories

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<BoothFilterFormInput>()

  const onSubmit: SubmitHandler<BoothFilterFormInput> = (data) => {
    const { machineCategoryId, ipName, sortBy } = data

    setBoothSearchParams({
      machineCategoryId,
      ipName,
      sortBy,
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={4} pb={1}>
          機種分類
        </Grid>
        <Grid item xs={8} pb={1}>
          <FormControl fullWidth error={"machineCategoryId" in errors}>
            <Controller
              control={control}
              name="machineCategoryId"
              render={({ field }) => (
                <SearchAutoComplete
                  items={[
                    ...(machineCategories || [])
                      .filter((c) => c.name !== "その他")
                      .map((c) => ({
                        label: c.name,
                        value: c.id,
                      })),
                    ...(machineCategories || [])
                      .filter((c) => c.name === "その他")
                      .map((c) => ({
                        label: c.name,
                        value: c.id,
                      })),
                  ]}
                  defaultValue={
                    (machineCategories || []).filter(
                      (c) => c.id === boothSearchParams.machineCategoryId,
                    )[0]?.id
                  }
                  {...field}
                />
              )}
            />
            {errors.machineCategoryId?.message && (
              <FormHelperText>
                {errors.machineCategoryId?.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={4} pb={1}>
          IP名
        </Grid>
        <Grid item xs={8} pb={1}>
          <TextField
            defaultValue={boothSearchParams.ipName || ""}
            placeholder="カタカナ部分一致"
            error={"ipName" in errors}
            helperText={errors.ipName?.message}
            {...register("ipName")}
          />
        </Grid>

        {isViewableCurrentArcadeSales && (
          <>
            <Grid item xs={4} pb={1}>
              並び替え
            </Grid>
            <Grid item xs={8} pb={1}>
              <FormControl fullWidth error={"sortBy" in errors}>
                <Controller
                  name="sortBy"
                  control={control}
                  defaultValue={boothSearchParams.sortBy}
                  render={({ field }) => (
                    <Select {...field} value={field.value ?? ""}>
                      <MenuItem key="" value="" sx={{ height: 36 }} />
                      {Object.entries(sortByLabels).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.sortBy?.message && (
                  <FormHelperText>{errors.sortBy?.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </>
        )}

        <Grid item xs={12} pt={1}>
          <Button variant="contained" type="submit" fullWidth>
            反映
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
