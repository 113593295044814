import { GlobalStyles } from "@mui/material"
import { useParams, useSearchParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { getPrizeFloorMapPrintSettings } from "src/api/prize-floor-map"
import { FloorMapPaper } from "src/components/organisms/FloorMapPaper"
import { PrizeDailyPlanFloorMapType } from "src/domains/prizes/floorMapRepository"
import { useAutoPrint } from "src/hooks/useAutoPrint"
import { usePrizeDailyPlanFloorMapPoints } from "src/hooks/usePrizeDailyPlanFloorMapPoints"
import { useResource } from "src/hooks/useResource"
import { currentArcadeState } from "src/recoil"
import { getJpDateLabel, getToday, isValidDateLabel } from "src/utils"

const type: PrizeDailyPlanFloorMapType = "plans"

export const PrizeDailyPlansFloorMapPrint: React.FC = () => {
  const { arcadeCd } = useParams()
  const currentArcade = useRecoilValue(currentArcadeState)

  const [searchParams] = useSearchParams()

  const dateParams = searchParams.get("date") || ""
  const date = isValidDateLabel(dateParams) ? dateParams : getToday()
  const areas = (searchParams.get("area") || "").split(",").filter(Boolean)
  const autoPrint = Boolean(searchParams.get("auto"))
  const pageSize = searchParams.get("pageSize") === "a3" ? "a3" : "a4"

  const { prizePlanFloorMapPoints } = usePrizeDailyPlanFloorMapPoints({
    arcadeCd,
    type,
    date,
  })

  const printSettings = useResource({
    subject: "印刷範囲一覧の取得",
    fetch: arcadeCd ? () => getPrizeFloorMapPrintSettings(arcadeCd) : undefined,
    recoilKey: `getPrizeFloorMapPrintSettings:${arcadeCd}`,
  }).resource?.data.settings

  const isReady = currentArcade && prizePlanFloorMapPoints && printSettings

  // ページを開いたら自動で印刷ダイアログを表示する
  useAutoPrint({
    disabled: !autoPrint,
    isReady: Boolean(isReady),
  })

  if (!isReady) {
    return null
  }

  return (
    <>
      <GlobalStyles
        styles={{
          // デフォルトでA3横で印刷されるように指定
          "@page": {
            size: `${pageSize.toUpperCase()} landscape`,
            margin: 0,
          },
        }}
      />
      {printSettings
        .filter(
          // area が指定されている場合はその範囲のみ、指定されていない場合は全ての範囲を印刷する
          (printSetting) =>
            areas.length === 0 || areas.includes(printSetting.name),
        )
        .map((printSetting) => {
          const points = prizePlanFloorMapPoints.filter((point) =>
            printSetting.boothNames.includes(point.name),
          )

          return (
            <FloorMapPaper
              key={printSetting.name}
              pageSize={pageSize}
              title={`${currentArcade.name} 投入計画マップ ${getJpDateLabel(date)} ${printSetting.name}`}
              points={points}
            />
          )
        })}
    </>
  )
}
