/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetNoticeResponse,
  GetNoticesResponse,
  PutNoticeRequest,
  UploadNoticeFileRequest,
  UploadNoticeFileResponse,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary お知らせ一覧の取得
 */
export const getNotices = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetNoticesResponse>(
    { url: `/notices`, method: "GET" },
    options,
  )
}
/**
 * @summary お知らせの作成・更新
 */
export const putNotice = (
  putNoticeRequest: PutNoticeRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/notices`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putNoticeRequest,
    },
    options,
  )
}
/**
 * @summary お知らせの削除
 */
export const deleteNotice = (
  noticeId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/notices/${noticeId}`, method: "DELETE" },
    options,
  )
}
/**
 * @summary お知らせの取得
 */
export const getNotice = (
  noticeId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetNoticeResponse>(
    { url: `/notices/${noticeId}`, method: "GET" },
    options,
  )
}
/**
 * @summary お知らせファイルのアップロード
 */
export const uploadNoticeFile = (
  uploadNoticeFileRequest: UploadNoticeFileRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  const formData = new FormData()
  if (uploadNoticeFileRequest.filename !== undefined) {
    formData.append("filename", uploadNoticeFileRequest.filename)
  }
  if (uploadNoticeFileRequest.file !== undefined) {
    formData.append("file", uploadNoticeFileRequest.file)
  }

  return customInstance<UploadNoticeFileResponse>(
    {
      url: `/notices/upload`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  )
}
export type GetNoticesResult = NonNullable<
  Awaited<ReturnType<typeof getNotices>>
>
export type PutNoticeResult = NonNullable<Awaited<ReturnType<typeof putNotice>>>
export type DeleteNoticeResult = NonNullable<
  Awaited<ReturnType<typeof deleteNotice>>
>
export type GetNoticeResult = NonNullable<Awaited<ReturnType<typeof getNotice>>>
export type UploadNoticeFileResult = NonNullable<
  Awaited<ReturnType<typeof uploadNoticeFile>>
>
