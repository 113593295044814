import { useMemo, useState } from "react"

import {
  CheckCircleOutlineOutlined,
  ErrorOutlineOutlined,
  ExpandCircleDown,
  InfoOutlined,
  WarningAmberOutlined,
} from "@mui/icons-material"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material"

type Severity = "success" | "info" | "warning" | "error"

type Props = {
  severity?: Severity
  accordion?: boolean
  title?: string
  children?: React.ReactNode
}

export const CustomAlert = ({
  severity = "info",
  accordion,
  title,
  children,
}: Props) => {
  const expandable = Boolean(title && children && accordion)
  const [expanded, setExpanded] = useState(false)

  const color = `${severity}.dark`
  const backgroundColor = `${severity}.light`

  const IconComponent = useMemo(() => {
    switch (severity) {
      case "success":
        return CheckCircleOutlineOutlined
      case "info":
        return InfoOutlined
      case "warning":
        return WarningAmberOutlined
      case "error":
        return ErrorOutlineOutlined
    }
  }, [severity])

  return (
    <Accordion
      expanded={!expandable || expanded}
      onChange={() => setExpanded(!expanded)}
      disableGutters
      sx={{
        backgroundColor,
        color,
        pointerEvents: expandable ? "auto" : "none",
      }}
    >
      <AccordionSummary
        expandIcon={expandable ? <ExpandCircleDown sx={{ color }} /> : null}
        sx={(theme) => ({
          "& .MuiAccordionSummary-content": { margin: theme.spacing(2, 0) },
        })}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <IconComponent color={severity} />
          {title ? (
            <Typography variant="body1" fontWeight="bold">
              {title}
            </Typography>
          ) : (
            children
          )}
        </Stack>
      </AccordionSummary>
      {title && children && <AccordionDetails>{children}</AccordionDetails>}
    </Accordion>
  )
}
