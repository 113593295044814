import { useCallback, useState } from "react"

import { Add, Delete, Launch } from "@mui/icons-material"
import {
  Typography,
  Stack,
  Button,
  Paper,
  TextField,
  MenuItem,
} from "@mui/material"
import { Navigate, Link as RouterLink, useParams } from "react-router-dom"
import { useRecoilState } from "recoil"

import {
  deletePrizeFloorMapPrintSetting,
  getPrizeFloorMapPrintSettings,
} from "src/api/prize-floor-map"
import { DatePicker } from "src/components/organisms/DatePicker"
import {
  FloorMapDeletePrintSettingModal,
  OnSubmitFloorMapDeletePrintSetting,
} from "src/components/organisms/FloorMapDeletePrintSettingModal"
import {
  FloorMapPrintSettingList,
  PrintMode,
} from "src/components/organisms/FloorMapPrintSettingList"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  openPrintTab,
  PrizeDailyPlanFloorMapType,
} from "src/domains/prizes/floorMapRepository"
import { usePageSize } from "src/hooks/usePageSize"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { prizeDailyFloorMapDateLabelState } from "src/recoil"

const type: PrizeDailyPlanFloorMapType = "sales"
const addPageUrl = ({ arcadeCd }: { arcadeCd: string | undefined }) =>
  `/arcades/${arcadeCd}/prizes/${type}/daily/floorMap/printSettings/add`

export const PrizeDailySalesFloorMapPrintSettings: React.FC = () => {
  const { arcadeCd } = useParams()
  const [date, setDate] = useRecoilState(prizeDailyFloorMapDateLabelState)

  const { pageSize, setPageSize, savePageSize } = usePageSize()

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const printSettingsReturn = useResource({
    subject: "印刷範囲一覧の取得",
    fetch: arcadeCd ? () => getPrizeFloorMapPrintSettings(arcadeCd) : undefined,
    recoilKey: `getPrizeFloorMapPrintSettings:${arcadeCd}`,
  })
  const printSettings = printSettingsReturn.resource?.data.settings
  const refetchPrintSettings = printSettingsReturn.refetch

  const { submitPromises } = useSubmitting()

  const [printMode, setPrintMode] = useState<PrintMode>("all")

  const [selectedPrintSettingNames, setSelectedPrintSettingNames] = useState<
    string[]
  >([])

  const onSelectPrintSetting = useCallback(
    (name: string, checked: boolean) => {
      // 扱いやすいように printSettings と同じ順で setState する
      setSelectedPrintSettingNames((prev) => {
        const next = checked ? [...prev, name] : prev.filter((n) => n !== name)
        return (printSettings || [])
          .map((setting) => setting.name)
          .filter((name) => next.includes(name))
      })
    },
    [printSettings],
  )

  const onPreview = useCallback(
    (name: string) => {
      openPrintTab({
        arcadeCd,
        type,
        pageSize,
        date,
        area: name,
      })
    },
    [arcadeCd, date, pageSize],
  )

  const onDeletePrintSetting: OnSubmitFloorMapDeletePrintSetting = async ({
    name,
  }) => {
    const printSetting = printSettings?.find((setting) => setting.name === name)
    if (!arcadeCd || !printSetting) {
      return
    }

    await submitPromises([
      {
        subject: "フロアマップ印刷設定の削除",
        showSuccessMessage: true,
        promise: async () => {
          await deletePrizeFloorMapPrintSetting(arcadeCd, printSetting.id)
          await refetchPrintSettings()
        },
      },
    ])
  }

  // 印刷範囲が未登録の場合は追加画面にリダイレクト
  if (
    arcadeCd &&
    printSettings !== undefined &&
    (printSettings == null || printSettings.length === 0)
  ) {
    return <Navigate replace to={addPageUrl({ arcadeCd })} />
  }

  return (
    <MainContentLayout
      title="フロアマップの印刷設定 ※PC推奨"
      renderContent={() => (
        <>
          <Stack gap={3}>
            <Stack direction="row" gap={2}>
              <Button
                variant="outlined"
                startIcon={<Add />}
                component={RouterLink}
                to={addPageUrl({ arcadeCd })}
              >
                印刷範囲を追加
              </Button>
              <Button
                variant="contained"
                color="error"
                startIcon={<Delete />}
                disabled={!printSettings || printSettings.length === 0}
                onClick={() => setDeleteModalOpen(true)}
              >
                印刷範囲の削除
              </Button>
            </Stack>
            <Paper sx={{ p: 2 }}>
              <Stack gap={2}>
                <Typography>
                  ブースの印刷設定を選択し、範囲を指定してください。
                </Typography>

                <Stack direction="row" gap={2}>
                  <Stack flex={1}>
                    <DatePicker
                      label="日付指定"
                      value={date}
                      onChange={setDate}
                      hideTodayButton
                    />
                  </Stack>
                  <Stack flex={1}>
                    <TextField
                      select
                      label="用紙サイズ"
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(e.target.value === "a3" ? "a3" : "a4")
                      }}
                    >
                      <MenuItem value="a4">A4 横</MenuItem>
                      <MenuItem value="a3">A3 横</MenuItem>
                    </TextField>
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
            <FloorMapPrintSettingList
              printMode={printMode}
              printSettingNames={printSettings?.map((setting) => setting.name)}
              selectedPrintSettingNames={selectedPrintSettingNames}
              onChangePrintMode={setPrintMode}
              onSelectPrintSetting={onSelectPrintSetting}
              onPreview={onPreview}
            />
            <Button
              variant="contained"
              endIcon={<Launch />}
              disabled={
                printMode === "select" && selectedPrintSettingNames.length === 0
              }
              onClick={() => {
                savePageSize()
                openPrintTab({
                  arcadeCd,
                  type,
                  pageSize,
                  date,
                  autoPrint: true,
                  // 選択モードの場合は選択中の範囲を area として渡す
                  ...(printMode === "select" && {
                    area: selectedPrintSettingNames.join(","),
                  }),
                })
              }}
            >
              マップを印刷
            </Button>
          </Stack>
          <FloorMapDeletePrintSettingModal
            open={deleteModalOpen}
            printSettingNames={(printSettings || []).map(
              (setting) => setting.name,
            )}
            onClose={() => setDeleteModalOpen(false)}
            onSubmit={onDeletePrintSetting}
          />
        </>
      )}
    />
  )
}
