import { ReactNode, useCallback, useEffect, useRef, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import {
  Typography,
  Stack,
  Card,
  CardContent,
  Button,
  Divider,
} from "@mui/material"
import { FormProvider, useForm } from "react-hook-form"
import { useParams } from "react-router-dom"

import { getMachines } from "src/api/machines"
import { Prize } from "src/api/models/prize"
import { PrizeMonthlyPlan } from "src/api/models/prizeMonthlyPlan"
import { getPrizeDelivery } from "src/api/prize-deliveries"
import { putPrizeMonthlyPlan } from "src/api/prize-plans"
import {
  getPrizeSettingBoothCategories,
  getPrizeSettingFees,
} from "src/api/prize-settings"
import { PrizeImageBox } from "src/components/molecules/PrizeImageBox"
import {
  PrizeMonthlyForm,
  PrizeMonthlyFormValues,
  getPrizeMonthlyFormValidationSchema,
} from "src/components/organisms/prizes/monthly/PrizeMonthlyForm"
import { PrizeMonthlyFormResult } from "src/components/organisms/prizes/monthly/PrizeMonthlyFormResult"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { getDeliveredQuantityLabel } from "src/domains/prizes/deliveryRepository"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { getJpDateLabel } from "src/utils"

type PageState = "edit" | "result"
type Page = {
  content: ReactNode
  backButtonLabel: string
}

export const PrizeMonthlyEdit: React.FC = () => {
  const [pageState, setPageState] = useState<PageState>("edit")
  const [prizeMonthlyPlan, setPrizeMonthlyPlan] = useState<
    PrizeMonthlyPlan | undefined
  >(undefined)
  const [prize, setPrize] = useState<Prize | undefined>(undefined)
  const onResult = (prizeMonthlyPlan: PrizeMonthlyPlan, prize: Prize) => {
    setPrizeMonthlyPlan(prizeMonthlyPlan)
    setPrize(prize)
    setPageState("result")
  }
  let page = {} as Page
  if (pageState === "edit") {
    page = {
      content: <PrizeMonthlyEditInner onResult={onResult} />,
      backButtonLabel: "保存せず戻る",
    }
  }
  if (pageState === "result" && prizeMonthlyPlan && prize) {
    page = {
      content: <PrizeMonthlyFormResult plan={prizeMonthlyPlan} prize={prize} />,
      backButtonLabel: "プライズ投入計画一覧へ戻る",
    }
  }
  return (
    <MainContentLayout
      title="プライズ投入計画 投入情報入力"
      renderContent={() => page.content}
      backButtonLabel={page.backButtonLabel}
    />
  )
}

type PrizeMonthlyEditInnerProps = {
  onResult: (prizeMonthlyPlan: PrizeMonthlyPlan, prize: Prize) => void
}

const PrizeMonthlyEditInner: React.FC<PrizeMonthlyEditInnerProps> = ({
  onResult,
}) => {
  const { arcadeCd, prizeDeliveryId } = useParams()

  const { submitPromises } = useSubmitting()

  const { resource: prizeDeliveryReturn, refetch: refetchPrizeDelivery } =
    useResource({
      subject: "着荷予定景品の取得",
      fetch:
        arcadeCd && prizeDeliveryId
          ? () => getPrizeDelivery(arcadeCd, Number(prizeDeliveryId))
          : undefined,
      recoilKey: `getPrizeDelivery:${arcadeCd}:${prizeDeliveryId}`,
    })
  const prizeDelivery = prizeDeliveryReturn?.data.delivery
  const prize = prizeDeliveryReturn?.data.prize
  const prizeMonthlyPlan = prizeDeliveryReturn?.data.plan

  const getPrizeSettingBoothCategoriesReturn = useResource({
    subject: "ブース区分の取得",
    fetch:
      arcadeCd && prizeDeliveryId
        ? () => getPrizeSettingBoothCategories(arcadeCd)
        : undefined,
    recoilKey: `getPrizeSettingBoothCategories:${arcadeCd}`,
  }).resource
  const prizeBoothCategories =
    getPrizeSettingBoothCategoriesReturn?.data.boothCategories

  const prizeFeesReturn = useResource({
    subject: "料金設定の取得",
    fetch: () => getPrizeSettingFees(),
    recoilKey: `getPrizeSettingFees`,
  }).resource
  const prizeFees = prizeFeesReturn?.data.fees

  const machinesReturn = useResource({
    subject: "機種一覧の取得",
    fetch: () => getMachines(),
    recoilKey: `getMachines`,
  }).resource
  const machines = machinesReturn?.data.machines

  const {
    orderCarton,
    arriveAt,
    machineInputDate,
    specifiedEntryDate,
    charterFlightDate,
  } = prizeDelivery ?? {}
  const {
    targetSegmentName,
    unitPerCarton,
    unitPriceJpy,
    widthCm,
    depthCm,
    heightCm,
    prizeCd,
    prizeName,
    ipName,
    prizeCategory,
    recommendedMachine,
  } = prize ?? {}

  const formMethods = useForm<PrizeMonthlyFormValues>({
    mode: "onChange",
    defaultValues: {
      ...prizeMonthlyPlan,
      feeId: prizeFees?.find(
        (fee) =>
          fee.fee === prizeMonthlyPlan?.fee &&
          fee.playCount == prizeMonthlyPlan.playCount,
      )?.id,
      boothCategoryId: prizeBoothCategories?.find(
        (category) => category.name === prizeMonthlyPlan?.boothCategory,
      )?.id,
      expectedConsumptionRate: prizeMonthlyPlan?.expectedConsumptionRate
        ? prizeMonthlyPlan?.expectedConsumptionRate * 100
        : undefined,
    },
    resolver: yupResolver(getPrizeMonthlyFormValidationSchema(unitPriceJpy)),
  })
  const {
    formState: { isSubmitSuccessful },
  } = formMethods

  const onSubmit = useCallback(
    async (data: PrizeMonthlyFormValues) => {
      if (!arcadeCd || !prizeDelivery) return
      const fee = prizeFees?.find((fee) => fee.id === data.feeId)
      const boothCategory = prizeBoothCategories?.find(
        (category) => category.id === data.boothCategoryId,
      )

      const { success, error } = await submitPromises([
        {
          subject: "プライズ投入計画の確定",
          showSuccessMessage: true,
          promise: async () => {
            await putPrizeMonthlyPlan(arcadeCd, prizeDelivery.id, {
              ...data,
              expectedConsumptionRate: data.expectedConsumptionRate
                ? data.expectedConsumptionRate / 100
                : undefined,
              fee: fee?.fee,
              playCount: fee?.playCount,
              boothCategory: boothCategory?.name,
              machine: data.machine !== "" ? data.machine : undefined,
            })
            await refetchPrizeDelivery()
          },
        },
      ])
      if (!success) throw error
    },
    [
      arcadeCd,
      submitPromises,
      prizeDelivery,
      prizeFees,
      prizeBoothCategories,
      refetchPrizeDelivery,
    ],
  )

  useEffect(() => {
    if (isSubmitSuccessful && prizeMonthlyPlan && prize) {
      onResult(prizeMonthlyPlan, prize)
    }
  }, [prizeMonthlyPlan, prize, isSubmitSuccessful, onResult])

  const formRef = useRef<HTMLFormElement>(null)

  return (
    <Stack gap={2}>
      <Card>
        <CardContent sx={{ px: 3, py: 2, ":last-child": { pb: 2 } }}>
          <Stack gap={2}>
            <Stack
              gap={3}
              divider={<Divider sx={{ borderWidth: "0 1px 0 0" }} />}
              direction="row"
            >
              <Stack flex={1}>
                <Typography variant="h2">{prizeName}</Typography>

                <Stack direction="row" pt={2}>
                  <Typography variant="caption" color="gray.50">
                    景品CD
                  </Typography>
                  <Typography variant="subtitle2" sx={{ ml: 1 }}>
                    {prizeCd}
                  </Typography>
                </Stack>
                <Stack sx={{ pt: 2 }}>
                  <FormProvider {...formMethods}>
                    <PrizeMonthlyForm
                      onSubmit={onSubmit}
                      prizeBoothCategories={prizeBoothCategories ?? []}
                      prize={prize}
                      prizeDelivery={prizeDelivery}
                      prizeFees={prizeFees ?? []}
                      machines={machines ?? []}
                      id="edit-monthly-form"
                      ref={formRef}
                    />
                  </FormProvider>
                </Stack>
              </Stack>

              <Stack flex={1}>
                <Stack gap={2}>
                  <Typography variant="h3">着荷予定景品情報</Typography>

                  <Stack flex={1} justifyContent="center">
                    <PrizeImageBox
                      prizeCd={prizeCd ?? ""}
                      noImageSize="large"
                    />
                  </Stack>

                  <Stack direction="row" gap={2}>
                    <Stack flex={1}>
                      <Typography variant="body2" color="gray.50">
                        入数
                      </Typography>
                      <Typography>
                        <Typography
                          variant="h3"
                          component="span"
                          sx={{ pr: 0.5 }}
                        >
                          {unitPerCarton?.toLocaleString("ja-JP")}
                        </Typography>
                        個
                      </Typography>
                    </Stack>
                    <Stack flex={1}>
                      <Typography variant="body2" color="gray.50">
                        納品数量（CT）
                      </Typography>
                      <Typography variant="h3" sx={{ pr: 0.5 }}>
                        {orderCarton?.toLocaleString("ja-JP")}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack direction="row" gap={2}>
                    <Stack flex={1}>
                      <Typography variant="body2" color="gray.50">
                        納品数量（バラ）
                      </Typography>
                      <Typography>
                        <Typography
                          variant="h3"
                          component="span"
                          sx={{ pr: 0.5 }}
                        >
                          {getDeliveredQuantityLabel(
                            unitPerCarton,
                            orderCarton,
                          )}
                        </Typography>
                        個
                      </Typography>
                    </Stack>
                    <Stack flex={1}>
                      <Typography variant="body2" color="gray.50">
                        単価
                      </Typography>
                      <Typography>
                        <Typography
                          variant="h3"
                          component="span"
                          sx={{ pr: 0.5 }}
                        >
                          {unitPriceJpy?.toLocaleString("ja-JP")}
                        </Typography>
                        円
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack direction="row" gap={2}>
                    <Stack flex={1}>
                      <Typography variant="body2" color="gray.50">
                        景品物販分類
                      </Typography>
                      <Typography variant="h3" sx={{ pr: 0.5 }}>
                        {prizeCategory || "-"}
                      </Typography>
                    </Stack>
                    <Stack flex={1}>
                      <Typography variant="body2" color="gray.50">
                        キャラクター名
                      </Typography>
                      <Typography variant="h3" sx={{ pr: 0.5 }}>
                        {ipName || "-"}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack direction="row" gap={2}>
                    <Stack flex={1}>
                      <Typography variant="body2" color="gray.50">
                        ターゲット
                      </Typography>
                      <Typography variant="h3" sx={{ pr: 0.5 }}>
                        {targetSegmentName}
                      </Typography>
                    </Stack>
                    <Stack flex={1}>
                      <Typography variant="body2" color="gray.50">
                        推奨投入機械
                      </Typography>
                      <Typography variant="h3" sx={{ pr: 0.5 }}>
                        {recommendedMachine}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack direction="row" gap={2}>
                    <Stack flex={1}>
                      <Typography variant="body2" color="gray.50">
                        景品サイズ
                      </Typography>
                      <Typography variant="h3" sx={{ pr: 0.5 }}>
                        {widthCm}cm*{depthCm}cm*{heightCm}cm
                      </Typography>
                    </Stack>
                    <Stack flex={1}>
                      <Typography variant="body2" color="gray.50">
                        着荷日
                      </Typography>
                      <Typography variant="h3" sx={{ pr: 0.5 }}>
                        {getJpDateLabel(arriveAt)}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack direction="row" gap={2}>
                    <Stack flex={1}>
                      <Typography variant="body2" color="gray.50">
                        投入可能日
                      </Typography>
                      <Typography variant="h3" sx={{ pr: 0.5 }}>
                        {getJpDateLabel(machineInputDate)}
                      </Typography>
                    </Stack>
                    <Stack flex={1}>
                      <Typography>指定投入日</Typography>
                      <Typography variant="h3" sx={{ pr: 0.5 }}>
                        {getJpDateLabel(specifiedEntryDate)}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack direction="row" gap={2}>
                    <Stack flex={1}>
                      <Typography variant="body2" color="gray.50">
                        チャーター便投入可能日
                      </Typography>
                      <Typography variant="h3" sx={{ pr: 0.5 }}>
                        {getJpDateLabel(charterFlightDate)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>

      <Stack>
        <Button
          variant="contained"
          color="primary"
          onClick={() => formRef.current?.requestSubmit()}
        >
          保存する
        </Button>
      </Stack>
    </Stack>
  )
}
