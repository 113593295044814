import { Suspense, useEffect, useState } from "react"

import { CheckCircleOutline } from "@mui/icons-material"
import { Alert, Box, Snackbar } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Outlet, useParams, useMatches, matchPath } from "react-router-dom"
import { useSetRecoilState, useRecoilValue, useResetRecoilState } from "recoil"

import { LoadingBox } from "src/components/molecules/LoadingBox"
import { NavBar } from "src/components/organisms/NavBar"
import { SideBar } from "src/components/organisms/SideBar"
import {
  snackbarErrorMessageState,
  snackbarSuccessMessageState,
  snackbarMessageState,
  currentArcadeState,
  alertParamsState,
  alertDefaultValueState,
  floorMapZoomRangeState,
  floorMapScrollPositionState,
} from "src/recoil"
import { SnackbarMessage } from "src/types"

const Main = styled("main")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  paddingTop: 64,
  [theme.breakpoints.up("md")]: {
    paddingLeft: 280,
  },
}))

export const Layout: React.FC = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false)
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)
  const setSuccessMessage = useSetRecoilState(snackbarSuccessMessageState)
  const snackbarMessage = useRecoilValue(snackbarMessageState)

  const matches = useMatches()

  const isPrintRoute = matches.some(({ pathname }) =>
    [
      "/arcades/:arcadeCd/prizes/plans/daily/floorMap/print",
      "/arcades/:arcadeCd/prizes/sales/daily/floorMap/print",
    ].some((pattern) => matchPath(pattern, pathname)),
  )

  const [severity, setSeverity] = useState<SnackbarMessage["severity"]>("error")
  useEffect(() => {
    if (snackbarMessage.content) {
      setSeverity(snackbarMessage.severity)
    }
  }, [snackbarMessage])

  const closeSnackbar = () => {
    setErrorMessage("")
    setSuccessMessage("")
  }

  const resetCurrentArcade = useResetRecoilState(currentArcadeState)
  const resetAlertDefaultValue = useResetRecoilState(alertDefaultValueState)
  const resetAlertParams = useResetRecoilState(alertParamsState)
  const resetFloorMapZoomRange = useResetRecoilState(floorMapZoomRangeState)
  const resetFloorMapScrollPosition = useResetRecoilState(
    floorMapScrollPositionState,
  )
  const { arcadeCd } = useParams()
  useEffect(() => {
    if (!arcadeCd) {
      resetCurrentArcade()
      resetAlertDefaultValue()
      resetAlertParams()
      resetFloorMapZoomRange()
      resetFloorMapScrollPosition()
    }
  }, [
    arcadeCd,
    resetCurrentArcade,
    resetAlertDefaultValue,
    resetAlertParams,
    resetFloorMapZoomRange,
    resetFloorMapScrollPosition,
  ])

  // 印刷ページではナビゲーションバーを表示しない
  if (isPrintRoute) {
    return <Outlet />
  }

  return (
    <>
      <Main>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Suspense fallback={<LoadingBox />}>
            <Outlet />
          </Suspense>
        </Box>
      </Main>

      <NavBar onSidebarOpen={() => setSidebarOpen(true)} />
      <SideBar onClose={() => setSidebarOpen(false)} open={isSidebarOpen} />

      <Snackbar
        open={!!snackbarMessage.content}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ top: { xs: 40 } }}
        onClose={(_e, reason) => {
          if (reason !== "clickaway") {
            closeSnackbar()
          }
        }}
      >
        <Alert
          severity={severity}
          iconMapping={{ success: <CheckCircleOutline fontSize="inherit" /> }}
          onClick={() => closeSnackbar()}
        >
          {snackbarMessage.content}
        </Alert>
      </Snackbar>
    </>
  )
}
