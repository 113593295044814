import dayjs from "dayjs"

import {
  Prize,
  PrizeBooth,
  PrizeBoothSale,
  PrizeBoothSalesElement,
  PrizeDailyPlan,
  PrizeDailyPlansElement,
  PrizeDelivery,
  PrizeDeliveryElement,
  PrizeMonthlyPlan,
} from "src/api/models"
import { sortFnPrizeDeliveries } from "src/domains/prizes/deliveryRepository"
import { calcRatio, formatApiDate, getDatesBetween, round } from "src/utils"

type AggregatedSales = {
  count?: number
  sales?: number
  payout?: number
  payoutPrice?: number
  payoutRate?: number
  pdpb?: number
}

export const aggregateSales = (
  sales: {
    sales?: number
    payout?: number
    payoutPrice?: number
  }[],
): AggregatedSales => {
  const total: AggregatedSales = sales.reduce(
    (prev, current) => ({
      sales:
        current.sales !== undefined
          ? current.sales + Number(prev.sales || 0)
          : prev.sales !== undefined
            ? prev.sales
            : undefined,
      payout:
        current.payout !== undefined
          ? current.payout + Number(prev.payout || 0)
          : prev.payout !== undefined
            ? prev.payout
            : undefined,
      payoutPrice:
        current.payoutPrice !== undefined
          ? current.payoutPrice + Number(prev.payoutPrice || 0)
          : prev.payoutPrice !== undefined
            ? prev.payoutPrice
            : undefined,
    }),
    {} as AggregatedSales,
  )
  const count = sales.filter(
    (sale) => sale.sales !== undefined && sale.payout !== undefined,
  ).length
  return {
    ...total,
    payoutRate: calcRatio(total.payoutPrice, total.sales),
    count: count,
    pdpb: calcRatio(total.sales, count),
  }
}

const calcAggregateSales = (
  sales: PrizeBoothSalesElement[] | undefined,
): AggregatedSales | undefined => {
  if (!sales) return undefined
  return aggregateSales(sales.map((s) => s.sale))
}

const calcPdpbRatio = (
  as1: AggregatedSales | undefined,
  as2: AggregatedSales | undefined,
) => calcRatio(as1?.pdpb, as2?.pdpb)

export type PrizeRank = "S" | "A" | "B" | "C"

const judgePrizeRank = (
  pdpbRatio: number | undefined,
): PrizeRank | undefined => {
  if (pdpbRatio === undefined) return undefined
  const p = pdpbRatio * 100
  if (p >= 200) return "S"
  else if (p >= 100) return "A"
  else if (p >= 50) return "B"
  return "C"
}

export type PrizeTotalSale = AggregatedSales

const toPrizeTotalSale = (aggregatedSales: AggregatedSales | undefined) =>
  ({
    ...aggregatedSales,
  }) as PrizeTotalSale

export type PrizeSale = AggregatedSales & {
  count: number
  pdpbRatio?: number
  prizeRank?: PrizeRank
}

const toPrizeSale = (
  aggregatedSales: AggregatedSales | undefined,
  pdpbRatio: number | undefined,
) =>
  ({
    count: aggregatedSales?.count || 0,
    sales: aggregatedSales?.sales,
    payout: aggregatedSales?.payout,
    payoutPrice: aggregatedSales?.payoutPrice,
    payoutRate: aggregatedSales?.payoutRate,
    pdpb: round(aggregatedSales?.pdpb),
    pdpbRatio: pdpbRatio,
    prizeRank: judgePrizeRank(pdpbRatio),
  }) as PrizeSale

export type PrizeArcadeSale = PrizeSale & {
  recordedAt: string
}

export type PrizeArcadeSales = {
  total: PrizeTotalSale
  items: PrizeArcadeSale[]
}

export const calcPrizeArcadeSales = function (
  from: dayjs.Dayjs,
  to: dayjs.Dayjs,
  sales: PrizeBoothSalesElement[],
): PrizeArcadeSales {
  const dataSet = new Map<string, PrizeBoothSalesElement[]>() // key: 日付
  sales.forEach((sale) => {
    const dateStr = formatApiDate(sale.sale.recordedAt)
    dataSet.set(dateStr, [...(dataSet.get(dateStr) || []), sale])
  })

  const arcadeSales = calcAggregateSales(sales)
  const dates = getDatesBetween(from, to)
  return {
    total: toPrizeTotalSale(arcadeSales),
    items: dates.map((date) => {
      const dateStr = formatApiDate(date)
      const aggregatedSales = calcAggregateSales(dataSet.get(dateStr))
      const pdpbRatio = calcPdpbRatio(aggregatedSales, arcadeSales)

      return {
        ...toPrizeSale(aggregatedSales, pdpbRatio),
        recordedAt: dateStr,
      }
    }),
  }
}

export type PrizePlanSale = PrizeSale & {
  consumptionRate?: number
  stock: number
  logicalStock?: number
  prize: Prize
  deliveries: PrizeDeliveryElement[]
}

export type PrizePlanSales = {
  total: PrizeTotalSale
  items: PrizePlanSale[]
}

export const calcPrizePlanSales = function (
  deliveries: PrizeDeliveryElement[],
  sales: PrizeBoothSalesElement[],
  filterFn: (e: PrizePlanSale) => boolean,
  sortFn: ((a: PrizePlanSale, b: PrizePlanSale) => number) | undefined,
): PrizePlanSales {
  const salesSet = new Map<string, PrizeBoothSalesElement[]>() // key: PrizeCd
  sales.forEach((sale) => {
    if (sale.sale.prize) {
      const prizeCd = sale.sale.prize.prizeCd
      salesSet.set(prizeCd, [...(salesSet.get(prizeCd) || []), sale])
    }
  })
  const deliveriesSet = new Map<string, PrizeDeliveryElement[]>() // key: PrizeCd
  deliveries.forEach((delivery) => {
    const prizeCd = delivery.prize.prizeCd
    deliveriesSet.set(prizeCd, [
      ...(deliveriesSet.get(prizeCd) || []),
      delivery,
    ])
  })

  const arcadeSales = calcAggregateSales(sales)
  return {
    total: toPrizeTotalSale(arcadeSales),
    items: Array.from(salesSet)
      .flatMap(([prizeCd, sales]) => {
        if (sales.length === 0 || sales[0] === undefined) {
          return []
        }

        const aggregatedSales = calcAggregateSales(sales)
        const pdpbRatio = calcPdpbRatio(aggregatedSales, arcadeSales)
        const prize = sales[0].sale.prize
        const deliveries = (deliveriesSet.get(prizeCd) || []).sort(
          sortFnPrizeDeliveries("arriveAtOrderAsc"),
        )
        const stock = deliveries.reduce(
          (prev, d) => prev + d.delivery.orderCarton * d.prize.unitPerCarton,
          0,
        )

        return {
          ...toPrizeSale(aggregatedSales, pdpbRatio),
          consumptionRate: calcRatio(aggregatedSales?.payout, stock),
          stock: stock,
          logicalStock:
            stock && aggregatedSales?.payout !== undefined
              ? stock - aggregatedSales.payout
              : undefined,
          prize: prize,
          deliveries: deliveries,
        } as PrizePlanSale
      })
      .filter(filterFn)
      .sort(sortFn),
  }
}

export type PrizeBoothDateRangeSale = PrizeSale & {
  booth: PrizeBooth
  latestSale?: PrizeBoothSale
  salesByDate: Map<string, number | undefined> // key: recordedAt (YYYY-MM-DD)
  machinePdpb?: number
}

export type PrizeBoothDateRangeSales = {
  items: PrizeBoothDateRangeSale[]
}

export const calcPrizeBoothDateRangeSales = function (
  sales: PrizeBoothSalesElement[],
): PrizeBoothDateRangeSales {
  const boothSet = new Map<string, PrizeBooth>() // key: BoothName
  const boothSalesSet = new Map<string, PrizeBoothSalesElement[]>() // key: BoothName
  const machineSalesSet = new Map<string, PrizeBoothSalesElement[]>() // key: MachineName

  sales.forEach((sale) => {
    const boothName = sale.booth.boothName
    boothSet.set(boothName, sale.booth)

    boothSalesSet.set(boothName, [
      ...(boothSalesSet.get(boothName) || []),
      sale,
    ])

    const machineName = sale.booth.machineName
    machineSalesSet.set(machineName, [
      ...(machineSalesSet.get(machineName) || []),
      sale,
    ])
  })

  const machineAggregatedSalesSet = new Map<string, AggregatedSales>() // key: MachineName
  Array.from(machineSalesSet.entries()).forEach(
    ([machineName, machineSales]) => {
      const machineAggregatedSales = calcAggregateSales(machineSales)
      if (machineAggregatedSales) {
        machineAggregatedSalesSet.set(machineName, machineAggregatedSales)
      }
    },
  )

  const items = Array.from(boothSet.values()).map((booth) => {
    const prizeBoothSales = boothSalesSet.get(booth.boothName)
    const latestSale = prizeBoothSales?.reduce(
      (prev, current) => {
        if (prev === undefined) return current
        return prev.sale.recordedAt >= current.sale.recordedAt ? prev : current
      },
      undefined as PrizeBoothSalesElement | undefined,
    )
    const filteredSales = prizeBoothSales?.filter(
      (sale) => sale.sale.prize?.prizeCd === latestSale?.sale.prize?.prizeCd,
    )

    const aggregatedSales = calcAggregateSales(filteredSales)
    const salesByDate = new Map<string, number | undefined>() // key: recordedAt
    filteredSales?.forEach((sale) => {
      const date = formatApiDate(sale.sale.recordedAt)
      const value = salesByDate.get(date)
      const sales =
        sale.sale.sales !== undefined
          ? sale.sale.sales + Number(value || 0)
          : undefined
      salesByDate.set(date, sales)
    })

    const machineAggregatedSales = machineAggregatedSalesSet.get(
      booth.machineName,
    )

    const pdpbRatio = calcPdpbRatio(aggregatedSales, machineAggregatedSales)

    return {
      ...toPrizeSale(aggregatedSales, pdpbRatio),
      booth: booth,
      latestSale: latestSale?.sale,
      salesByDate: salesByDate,
      machinePdpb: round(machineAggregatedSales?.pdpb),
    }
  })

  return {
    items: items,
  }
}

export type PrizeBoothDailySale = PrizeSale & {
  recordedAt: string
  prize?: Prize
}

export type PrizeBoothDailySales = {
  total: PrizeTotalSale
  items: PrizeBoothDailySale[]
}

export const calcPrizeBoothDailySales = function (
  sales: PrizeBoothSalesElement[],
): PrizeBoothDailySales {
  const boothSalesSet = new Map<string, PrizeBoothSalesElement>() // key: recordedAt
  sales.forEach((sale) => {
    boothSalesSet.set(formatApiDate(sale.sale.recordedAt), sale)
  })

  const boothAggregatedSales = calcAggregateSales(sales)
  return {
    total: toPrizeTotalSale(boothAggregatedSales),
    items: Array.from(boothSalesSet).map(([recordedAt, prizeBoothSale]) => {
      const aggregatedSales = calcAggregateSales([prizeBoothSale])
      const pdpbRatio = calcPdpbRatio(aggregatedSales, boothAggregatedSales)

      return {
        ...toPrizeSale(aggregatedSales, pdpbRatio),
        recordedAt: recordedAt,
        prize: prizeBoothSale.sale.prize,
      }
    }),
  }
}

const unknownPrizeCdSaleKey = "UnknownPrizeCd"

export type PrizeIpSale = PrizeSale & {
  ipName: string
  countRatio?: number
  ranking: number
}

export type PrizeIpSales = {
  total: PrizeTotalSale
  items: PrizeIpSale[]
}

export const calcPrizeIpSales = function (
  sales: PrizeBoothSalesElement[],
): PrizeIpSales {
  const ipSalesSet = new Map<string, PrizeBoothSalesElement[]>() // key: ipName
  sales.forEach((sale) => {
    let ipName = unknownPrizeCdSaleKey
    if (sale.sale.prize) {
      ipName = sale.sale.prize.ipName
    }
    ipSalesSet.set(ipName, [...(ipSalesSet.get(ipName) || []), sale])
  })

  const arcadeSales = calcAggregateSales(sales)
  const ipSales = Array.from(ipSalesSet.keys()).map((ipName) => {
    const prizeBoothSales = ipSalesSet.get(ipName)
    const aggregatedSales = calcAggregateSales(prizeBoothSales)
    const pdpbRatio = calcPdpbRatio(aggregatedSales, arcadeSales)

    return {
      ...toPrizeSale(aggregatedSales, pdpbRatio),
      ipName: ipName === unknownPrizeCdSaleKey ? "unknown" : ipName,
      countRatio: calcRatio(aggregatedSales?.count, arcadeSales?.count),
    }
  })

  return {
    total: toPrizeTotalSale(arcadeSales),
    items: ipSales
      .sort((a, b) => (b.sales ?? -1) - (a.sales ?? -1))
      .map((sale, i) => ({ ...sale, ranking: i + 1 })),
  }
}

export type PrizeRankSale = AggregatedSales & {
  count: number
  pdpbRatio?: number
  prizeRank: PrizeRank | "unknown" | "all"
  averageSales?: number
  averageDailySales?: number
  averageUnitPrice?: number
  salesRatio?: number
  countRatio?: number
}

export type PrizeRankSales = {
  total: PrizeTotalSale
  items: PrizeRankSale[]
}

export const calcPrizeRankSales = function (
  sales: PrizeBoothSalesElement[],
  from: dayjs.Dayjs | string,
  to: dayjs.Dayjs | string,
): PrizeRankSales {
  const salesSet = new Map<string, PrizeBoothSalesElement[]>()
  const prizeSet = new Map<string, Prize>()
  sales.forEach((sale) => {
    let prizeCd = unknownPrizeCdSaleKey
    if (sale.sale.prize) {
      prizeCd = sale.sale.prize.prizeCd
      prizeSet.set(prizeCd, sale.sale.prize)
    }
    salesSet.set(prizeCd, [...(salesSet.get(prizeCd) || []), sale])
  })

  const arcadeSales = calcAggregateSales(sales)
  const rankSalesSet = new Map<
    PrizeRank | "unknown",
    Map<string, PrizeBoothSalesElement[]>
  >()

  Array.from(salesSet.entries()).forEach(([prizeCd, prizeSales]) => {
    const aggregatedSales = calcAggregateSales(prizeSales)
    const pdpbRatio = calcPdpbRatio(aggregatedSales, arcadeSales)
    let rank: PrizeRank | "unknown" | undefined = judgePrizeRank(pdpbRatio)
    if (prizeCd === unknownPrizeCdSaleKey) {
      rank = "unknown"
    }
    if (rank) {
      let prizeSalesSet = rankSalesSet.get(rank)
      if (prizeSalesSet === undefined) {
        prizeSalesSet = new Map<string, PrizeBoothSalesElement[]>()
      }
      prizeSalesSet.set(prizeCd, prizeSales)
      rankSalesSet.set(rank, prizeSalesSet)
    }
  })

  const dates = getDatesBetween(from, to)
  const prizes = Array.from(prizeSet.values())
  const total = {
    ...toPrizeSale(arcadeSales, 1),
    prizeRank: "all",
    averageSales: round(calcRatio(arcadeSales?.sales, prizes.length)),
    averageDailySales: round(
      calcRatio(arcadeSales?.sales, prizes.length * dates.length),
    ),
    averageUnitPrice: round(
      calcRatio(
        prizes.reduce((prev, current) => prev + (current.unitPriceJpy || 0), 0),
        prizes.length,
      ),
    ),
    salesRatio: calcRatio(arcadeSales?.sales, arcadeSales?.sales),
    countRatio: calcRatio(arcadeSales?.count, arcadeSales?.count),
  } as PrizeRankSale
  return {
    total: toPrizeTotalSale(arcadeSales),
    items: Array.from(rankSalesSet.entries())
      .map(([rank, prizeSalesSet]) => {
        const { rankSales, prizes, totalUnitPrice } = Array.from(
          prizeSalesSet.entries(),
        ).reduce(
          (prev, [prizeCd, prizeSales]) => {
            const prize = prizeSet.get(prizeCd)
            if (prize) {
              return {
                rankSales: [...prev.rankSales, ...prizeSales],
                prizes: [...prev.prizes, prize],
                totalUnitPrice: prev.totalUnitPrice + (prize.unitPriceJpy || 0),
              }
            } else {
              return {
                rankSales: [...prev.rankSales, ...prizeSales],
                prizes: prev.prizes,
                totalUnitPrice: prev.totalUnitPrice,
              }
            }
          },
          {
            rankSales: [] as PrizeBoothSalesElement[],
            prizes: [] as Prize[],
            totalUnitPrice: 0,
          },
        )
        const aggregatedSales = calcAggregateSales(rankSales)
        const pdpbRatio = calcPdpbRatio(aggregatedSales, arcadeSales)

        return {
          ...toPrizeSale(aggregatedSales, pdpbRatio),
          prizeRank: rank,
          averageSales: round(calcRatio(aggregatedSales?.sales, prizes.length)),
          averageDailySales: round(
            calcRatio(aggregatedSales?.sales, prizes.length * dates.length),
          ),
          averageUnitPrice: round(calcRatio(totalUnitPrice, prizes.length)),
          salesRatio: calcRatio(aggregatedSales?.sales, arcadeSales?.sales),
          countRatio: calcRatio(aggregatedSales?.count, arcadeSales?.count),
        } as PrizeRankSale
      })
      .concat(total)
      .sort((a: PrizeRankSale, b: PrizeRankSale) => {
        if (a.prizeRank === b.prizeRank) {
          return 0
        }
        if (a.prizeRank === "all") {
          return 1
        } else if (b.prizeRank === "all") {
          return -1
        }
        if (a.prizeRank === "unknown") {
          return 1
        } else if (b.prizeRank === "unknown") {
          return -1
        }
        if (a.prizeRank === "S") {
          return -1
        } else if (b.prizeRank === "S") {
          return 1
        }
        return a.prizeRank.localeCompare(b.prizeRank)
      }),
  }
}
export type PrizeDailySale = PrizeSale & {
  recordedAt: string
  prize?: Prize
  booth: PrizeBooth
  plan?: PrizeDailyPlan
  delivery?: PrizeDelivery
  monthlyPlan?: PrizeMonthlyPlan
  consumptionRate?: number
  stock: number
  logicalStock?: number
}

export type PrizeDailySales = {
  total: PrizeTotalSale
  items: PrizeDailySale[]
}

export const calcPrizeDailySales = function (
  sales: PrizeBoothSalesElement[],
  plans: PrizeDailyPlansElement[],
  deliveries: PrizeDeliveryElement[],
  filterFn: (e: PrizeDailySale) => boolean,
  sortFn: ((a: PrizeDailySale, b: PrizeDailySale) => number) | undefined,
): PrizeDailySales {
  const dailySales = calcAggregateSales(sales)
  return {
    total: toPrizeTotalSale(dailySales),
    items: sales
      .map((sale) => {
        const targetSales = calcAggregateSales([sale])
        const pdpbRatio = calcPdpbRatio(targetSales, dailySales)
        // NOTE: PrizeDailyPlan と PrizeBoothSale の各 id は同じものを参照しているので、id で判定する
        const plan = plans.find((plan) => plan.plan.id === sale.sale.id)
        const delivery = deliveries.find(
          (delivery) => delivery.prize.prizeCd === sale.sale.prize?.prizeCd,
        )
        const stock =
          (delivery?.delivery?.orderCarton ?? 0) *
          (delivery?.prize?.unitPerCarton ?? 0)
        return {
          ...toPrizeSale(targetSales, pdpbRatio),
          recordedAt: sale.sale.recordedAt,
          prize: sale.sale.prize,
          booth: sale.booth,
          plan: plan?.plan,
          delivery: delivery?.delivery,
          monthlyPlan: delivery?.plan,
          consumptionRate: calcRatio(sale.sale?.payout, stock),
          stock: stock,
          logicalStock: sale.sale?.payout
            ? stock - sale.sale.payout
            : undefined,
        }
      })
      .filter(filterFn)
      .sort(sortFn),
  }
}

export const meterReadTemplateHeaders = [
  "プライズ機種名(ブース名)",
  "端末識別番号",
  "メーター入力方法",
  "故障かどうか",
  "P/O管理方法",
  "見なしP/O",
  "10円コイン枚数初期値",
  "100円コイン枚数初期値",
  "500円コイン枚数初期値",
  "プライズ初期値",
]
