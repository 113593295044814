import { useState, useEffect } from "react"

import {
  Card,
  Button,
  TableHead,
  TableRow,
  RadioGroup,
  Radio,
  Checkbox,
  FormControlLabel,
  Stack,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"

import { getMaterialOperationIn } from "src/api/material-operation-in"
import { MaterialOperationInHistoryElement } from "src/api/models"
import finishedIcon from "src/assets/icon_finished.png"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  CardItemNameBox,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import { DefaultDateRangePicker } from "src/components/organisms/DefaultDateRangePicker"
import { InventoryMaterialSeamsInModal } from "src/components/organisms/materials/InventoryMaterialSeamsInModal"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import {
  defaultDateRangePickerDateLabelStateSelector,
  snackbarSuccessMessageState,
} from "src/recoil"
import { hideMaterialInventoryHistoriesRegisteredSeamsState } from "src/recoil/inventoryMaterials"
import { formatApiDate, getJpDateLabel } from "src/utils"

type MaterialOperationInHistoriesPerDate = {
  [date: string]: MaterialOperationInHistoryElement[]
}

export const InventoryMaterialSeamsIn: React.FC = () => {
  const [hideRegisteredSeams, setHideRegisteredSeams] = useRecoilState(
    hideMaterialInventoryHistoriesRegisteredSeamsState,
  )

  return (
    <MainContentLayout
      title="入庫ログ一覧"
      renderFilter={() => (
        <Stack gap={2}>
          <Card sx={{ p: 3 }}>
            <DefaultDateRangePicker />
          </Card>
          <FormControlLabel
            checked={hideRegisteredSeams}
            onChange={() => setHideRegisteredSeams(!hideRegisteredSeams)}
            control={<Checkbox />}
            disableTypography
            label="SEAMS登録済みを表示しない"
          />
        </Stack>
      )}
      renderContent={() => <InventoryMaterialSeamsInMenu />}
    />
  )
}

const InventoryMaterialSeamsInMenu = () => {
  const { arcadeCd } = useParams()

  const dateRange = useRecoilValue(defaultDateRangePickerDateLabelStateSelector)
  const { resource, refetch } = useResource({
    subject: "入庫記録の取得",
    fetch: arcadeCd
      ? () =>
          getMaterialOperationIn(arcadeCd, {
            from: dateRange.start,
            to: dateRange.end,
          })
      : undefined,
    recoilKey: `getMaterialOperationIn:${arcadeCd}:${dateRange.start}:${dateRange.end}`,
  })
  const { inHistories } = resource?.data || {}

  const inHistoriesPerDate = inHistories?.reduce(
    (result: MaterialOperationInHistoriesPerDate, history) => {
      const date = formatApiDate(history.inHistory.inAt)
      result[date] = [...(result[date] || []), history]
      return result
    },
    {},
  )
  const [selectedInHistoryId, setSelectedInHistoryId] = useState<
    number | undefined
  >()
  const selectedInHistory = inHistories?.find(
    (h) => h.inHistory.id === selectedInHistoryId,
  )
  const hideRegisteredSeams = useRecoilValue(
    hideMaterialInventoryHistoriesRegisteredSeamsState,
  )
  const [showModal, setShowModal] = useState(false)
  const setSuccessMessage = useSetRecoilState(snackbarSuccessMessageState)
  useEffect(() => {
    if (inHistories?.length === 0) {
      setSuccessMessage("指定期間の入庫記録が存在しませんでした")
    }
  }, [inHistories, setSuccessMessage])

  useEffect(() => {
    // NOTE: 日付変更時には選択中の入庫記録をリセット
    setSelectedInHistoryId(undefined)
  }, [dateRange])

  return (
    <>
      {inHistoriesPerDate &&
        Object.keys(inHistoriesPerDate)
          .sort()
          .reverse()
          .map((date) => {
            const inHistories = (inHistoriesPerDate[date] || []).filter((h) =>
              hideRegisteredSeams ? !h.inHistory.isRegisteredSeams : true,
            )
            return (
              inHistories.length > 0 && (
                <Stack key={date} mb={2}>
                  <Stack sx={{ fontWeight: "bold" }}>
                    {getJpDateLabel(date)}
                  </Stack>
                  <Stack>
                    <InHistoryTable
                      inHistories={inHistories}
                      selectedInHistory={selectedInHistory}
                      setSelectedInHistoryId={setSelectedInHistoryId}
                      date={date}
                    />
                  </Stack>
                </Stack>
              )
            )
          })}

      <Stack>
        <Button
          variant="contained"
          fullWidth
          disabled={!selectedInHistory}
          onClick={() => setShowModal(true)}
        >
          {selectedInHistory?.inHistory.isRegisteredSeams
            ? "入庫登録状況を変更する"
            : "選択した材料をSEAMS登録済にする"}
        </Button>
      </Stack>

      {selectedInHistory && (
        <InventoryMaterialSeamsInModal
          showModal={showModal}
          inHistory={selectedInHistory}
          onClose={() => setShowModal(false)}
          onFinish={() => refetch()}
        />
      )}
    </>
  )
}

interface InHistoryTableProps {
  inHistories: MaterialOperationInHistoryElement[]
  selectedInHistory: MaterialOperationInHistoryElement | undefined
  setSelectedInHistoryId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >
  date: string
}

const InHistoryTable: React.FC<InHistoryTableProps> = ({
  inHistories,
  selectedInHistory,
  setSelectedInHistoryId,
  date,
}: InHistoryTableProps) => {
  const columnNames = ["選択", "材料名", "入庫数", "登録済"]

  return (
    <RadioGroup>
      <PaginatedTable
        items={inHistories}
        stateKey={`inHistoryTable-${date}`}
        header={
          <TableHead>
            <TableRow>
              {columnNames.map((columnName) => (
                <ExtTableCell
                  sx={{ px: 1, whiteSpace: "nowrap" }}
                  key={columnName}
                >
                  {columnName}
                </ExtTableCell>
              ))}
            </TableRow>
          </TableHead>
        }
        renderRow={(h) => {
          return (
            <TableBorderedRow
              key={h.inHistory.id}
              sx={{
                td: { p: 1 },
              }}
              onClick={() => setSelectedInHistoryId(h.inHistory.id)}
              selected={selectedInHistory?.inHistory.id === h.inHistory.id}
              data-testid={h.inHistory.id}
            >
              <ExtTableCell>
                <Radio
                  sx={{ p: 0 }}
                  checked={selectedInHistory?.inHistory.id === h.inHistory.id}
                />
              </ExtTableCell>
              <ExtTableCell>
                <CardItemNameBox>{h.material.materialName}</CardItemNameBox>
              </ExtTableCell>
              <ExtTableCell>{h.inHistory.stock}</ExtTableCell>
              <ExtTableCell>
                {h.inHistory.isRegisteredSeams && (
                  <img
                    src={finishedIcon}
                    style={{ width: 32, height: 32 }}
                    alt="済"
                  />
                )}
              </ExtTableCell>
            </TableBorderedRow>
          )
        }}
      />
    </RadioGroup>
  )
}
