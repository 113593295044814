import { Fragment, Suspense, useCallback, useMemo, useState } from "react"

import {
  Print,
  Edit,
  EditNote,
  Launch,
  CategoryOutlined,
} from "@mui/icons-material"
import {
  Typography,
  Stack,
  Tab,
  Tabs,
  Button,
  Link,
  IconButton,
  DialogContent,
  Divider,
  Chip,
  Paper,
  TableHead,
  TableRow,
} from "@mui/material"
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom"
import { atom, useRecoilState, useRecoilValue } from "recoil"

import { PrizeDailyPlansElement } from "src/api/models"
import { getPrizeDeliveries } from "src/api/prize-deliveries"
import { getPrizeDailyPlans } from "src/api/prize-plans"
import { BackButton } from "src/components/atoms/BackButton"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { TableBorderedRow } from "src/components/molecules/CardTableCells"
import { CustomAlert } from "src/components/molecules/CustomAlert"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { LoadingBox } from "src/components/molecules/LoadingBox"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import {
  defaultSearchParams as defaultPlansSearchParams,
  PrizeDailyFilter,
  PrizeDailyFilterSearchParams,
} from "src/components/organisms/prizes/PrizeDailyFilter"
import { PrizeDailyPlanFloorMapBox } from "src/components/organisms/prizes/PrizeDailyPlanFloorMapBox"
import {
  PrizeDailyPlansDatePicker,
  prizeDailyPlansDatePickerState,
} from "src/components/organisms/prizes/PrizeDailyPlansDatePicker"
import {
  SELECTABLE_PAGINATED_TABLE_CHECKBOX_COL_WIDTH,
  SelectablePaginatedTable,
} from "src/components/organisms/SelectablePaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { isPrizeDailyPlanUpdatable } from "src/domains/prizes/dailyRepository"
import { PrizePlanFloorMapPoint } from "src/domains/prizes/floorMapRepository"
import { usePrizeDailyPlanFloorMapPoints } from "src/hooks/usePrizeDailyPlanFloorMapPoints"
import { useResource } from "src/hooks/useResource"
import { filterAccordionSearchState } from "src/recoil"
import { getDaysAgo } from "src/utils"

const tabs = [
  { key: "prize", name: "投入景品" },
  { key: "floorMap", name: "投入計画マップ" },
] as const
type PrizeDailyTab = (typeof tabs)[number]["key"]

const prizeDailyTabState = atom<PrizeDailyTab>({
  key: "prizeDailyTabState",
  default: tabs[0].key,
})

export const PrizeDaily: React.FC = () => {
  const { arcadeCd } = useParams()

  // 画面遷移して戻ってきたときにタブの状態を保持するために recoil を使用
  const [tab, setTab] = useRecoilState(prizeDailyTabState)

  return (
    <MainContentLayout
      title="ブース別投入景品一覧"
      renderContent={() => (
        <>
          <Stack sx={{ pb: 2 }}>
            <Tabs
              value={tab}
              onChange={(_, value) => setTab(value)}
              scrollButtons={false}
            >
              {tabs.map((t) => (
                <Tab key={t.key} value={t.key} label={t.name} />
              ))}
            </Tabs>
          </Stack>

          <Stack>
            {tab === "prize" && (
              <>
                <Paper
                  sx={{
                    px: 3,
                    py: 3.5,
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    mb: 2,
                  }}
                >
                  <Stack flex={1}>
                    <PrizeDailyPlansDatePicker />
                  </Stack>
                </Paper>
                <Stack sx={{ pb: 2 }}>
                  <PrizeDailyFilter />
                </Stack>
                <Suspense fallback={<LoadingBox />}>
                  <PrizeDailyPlans />
                </Suspense>
              </>
            )}
            {tab === "floorMap" && (
              <>
                <Paper
                  sx={{
                    px: 3,
                    py: 3.5,
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    mb: 2,
                  }}
                >
                  <Stack flex={1}>
                    <PrizeDailyPlansDatePicker />
                  </Stack>
                  <Button
                    variant="outlined"
                    startIcon={<Print />}
                    component={RouterLink}
                    to={`/arcades/${arcadeCd}/prizes/plans/daily/floorMap/printSettings`}
                  >
                    印刷設定
                  </Button>
                </Paper>
                <Suspense fallback={<LoadingBox />}>
                  <PrizeDailyFloorMap />
                </Suspense>
              </>
            )}
          </Stack>
        </>
      )}
    />
  )
}

type TableData = PrizeDailyPlansElement
const PrizeDailyPlans: React.FC = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()
  const date = useRecoilValue(prizeDailyPlansDatePickerState)
  const { sortBy, ...searchParams } = (useRecoilValue(
    filterAccordionSearchState,
  )["prizeDailySearchParams"] ??
    defaultPlansSearchParams) as PrizeDailyFilterSearchParams

  const [selectedDailyPlans, setSelectedDailyPlans] = useState<
    PrizeDailyPlansElement[]
  >([])

  // アラート表示に必要なため絞り込み前のデータも取得する
  const prizeDailyPlans = useResource({
    subject: "デイリー入替計画の取得",
    fetch: arcadeCd
      ? () => getPrizeDailyPlans(arcadeCd, { from: date, to: date })
      : undefined,
    // デフォルト表示時の通信が1回で済むように searchParams のデフォルト値とキーを揃えている
    recoilKey: `getPrizeDailyPlans:${arcadeCd}:${date}:${date}:{}`,
  }).resource?.data.plans

  const searchedPrizeDailyPlans = useResource({
    subject: "デイリー入替計画の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeDailyPlans(arcadeCd, {
            from: date,
            to: date,
            ...searchParams,
          })
      : undefined,
    recoilKey: `getPrizeDailyPlans:${arcadeCd}:${date}:${date}:${JSON.stringify(searchParams)}`,
  }).resource?.data.plans

  const prizeDeliveries = useResource({
    subject: "着荷予定景品一覧の取得",
    fetch: arcadeCd
      ? () => getPrizeDeliveries(arcadeCd, { from: date, to: date })
      : undefined,
    recoilKey: `getPrizeDeliveries:${arcadeCd}:${date}:${date}`,
  }).resource?.data.deliveries

  const prizeDeliveriesWithoutPlan = useMemo(() => {
    if (!prizeDeliveries || prizeDailyPlans === undefined) {
      return []
    }

    // prizeDailyPlans が空のとき null で返ってくるため null と undefined を区別する
    if (!prizeDailyPlans) {
      return prizeDeliveries
    }

    return prizeDeliveries.filter(
      (delivery) =>
        !prizeDailyPlans.some(
          ({ plan }) => plan.prize.prizeCd === delivery.prize.prizeCd,
        ),
    )
  }, [prizeDeliveries, prizeDailyPlans])

  const tableData: TableData[] = useMemo(() => {
    const filteredPlans = [...(searchedPrizeDailyPlans || [])].sort((a, b) => {
      if (!a.plan || !b.plan) return 1

      if (
        sortBy === "boothCategoryAsc" &&
        a.plan.boothCategory !== b.plan.boothCategory
      ) {
        return a.plan.boothCategory > b.plan.boothCategory ? 1 : -1
      }

      // 日付を範囲選択から単一選択に変更したため日付ソートを削除したがソートのロジックはそのままにしておく
      return a.plan.recordedAt > b.plan.recordedAt ? -1 : 1
    })

    return filteredPlans.map((prizeDailyPlan) => ({
      ...prizeDailyPlan,
    }))
  }, [searchedPrizeDailyPlans, sortBy])

  return (
    <>
      {prizeDeliveriesWithoutPlan.length > 0 && (
        <Stack pb={2}>
          <CustomAlert
            accordion
            severity="warning"
            title="投入可能日に未投入の景品一覧"
          >
            <Stack direction="column" spacing={2}>
              <Typography variant="body2">
                投入可能日に投入されていない着荷予定景品があります。投入計画漏れではないことを確認してください。
              </Typography>
              <PaginatedTable
                noMargin
                items={prizeDeliveriesWithoutPlan}
                tableLayout="fixed"
                header={
                  <TableHead>
                    <TableRow>
                      <ExtTableCell>景品名</ExtTableCell>
                      <ExtTableCell>景品CD</ExtTableCell>
                    </TableRow>
                  </TableHead>
                }
                renderRow={({ prize }) => {
                  return (
                    <TableBorderedRow key={prize.prizeCd}>
                      <ExtTableCell>{prize.prizeName}</ExtTableCell>
                      <ExtTableCell>{prize.prizeCd}</ExtTableCell>
                    </TableBorderedRow>
                  )
                }}
              />
            </Stack>
          </CustomAlert>
        </Stack>
      )}
      <Stack
        sx={{
          pb: 2,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
          {selectedDailyPlans.length > 0 && (
            <Typography variant="body2" sx={{ mr: 2 }}>
              {selectedDailyPlans.length}件選択中
            </Typography>
          )}
          <Button
            variant="outlined"
            sx={{ mr: 2, whiteSpace: "nowrap" }}
            startIcon={<Edit />}
            disabled={selectedDailyPlans.length === 0}
            onClick={() =>
              navigate(
                `/arcades/${arcadeCd}/prizes/plans/daily/edit?from=${date}&to=${
                  date
                }&prizeDailyPlanIds=${selectedDailyPlans
                  .map(({ plan }) => plan.id)
                  .join(",")}`,
              )
            }
          >
            複数ブース編集
          </Button>
          <Button
            variant="contained"
            sx={{ mr: 2, whiteSpace: "nowrap" }}
            startIcon={<EditNote />}
            onClick={() =>
              navigate(`/arcades/${arcadeCd}/prizes/plans/daily/bulk`)
            }
          >
            一括入力
          </Button>
          <Button
            variant="outlined"
            sx={{ mr: 2, whiteSpace: "nowrap" }}
            startIcon={<CategoryOutlined />}
            endIcon={<Launch />}
            onClick={() =>
              // 選択中の開始日、終了日を反映した状態で着荷予定景品一覧を開く
              window.open(
                `/arcades/${arcadeCd}/prizes/register/deliveries?${new URLSearchParams({ start: getDaysAgo(7, date), end: date })}`,
                "_blank",
              )
            }
          >
            着荷予定景品一覧
          </Button>
        </Stack>
      </Stack>
      <PrizeDailyTable
        tableData={tableData}
        selectedDailyPlans={selectedDailyPlans}
        setSelectedDailyPlans={setSelectedDailyPlans}
      />
    </>
  )
}

interface PrizeDailyTableProps {
  tableData: TableData[]
  selectedDailyPlans: TableData[]
  setSelectedDailyPlans: React.Dispatch<React.SetStateAction<TableData[]>>
}

const PrizeDailyTable: React.FC<PrizeDailyTableProps> = ({
  tableData,
  selectedDailyPlans,
  setSelectedDailyPlans,
}) => {
  const stateKey = "prizeDailyTable"
  const { arcadeCd } = useParams()
  const navigate = useNavigate()
  const date = useRecoilValue(prizeDailyPlansDatePickerState)

  return (
    <Stack
      sx={{
        maxHeight: "calc(100dvh - 360px)",
      }}
    >
      <SelectablePaginatedTable
        scrollableX
        scrollableY
        stickyHeader
        noMargin
        items={tableData}
        disabledItems={tableData.filter(
          (plan) => !isPrizeDailyPlanUpdatable(plan.plan.recordedAt),
        )}
        stateKey={stateKey}
        renderHeaderCells={() => (
          <>
            <ExtTableCell
              border
              sticky
              zIndex={4}
              fixedWidth={180}
              sx={{ left: SELECTABLE_PAGINATED_TABLE_CHECKBOX_COL_WIDTH }}
            >
              プライズ機種名（ブース名）
            </ExtTableCell>
            <ExtTableCell fixedWidth={162}>ブース区分</ExtTableCell>
            <ExtTableCell fixedWidth={162}>景品CD</ExtTableCell>
            <ExtTableCell fixedWidth={162}>設定</ExtTableCell>
            <ExtTableCell fixedWidth={260}>景品名</ExtTableCell>
          </>
        )}
        renderRowCells={({ plan, booth }) => {
          return (
            <Fragment key={plan?.id}>
              <ExtTableCell
                border
                sticky
                zIndex={3}
                sx={{
                  left: SELECTABLE_PAGINATED_TABLE_CHECKBOX_COL_WIDTH,
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flex: 1,
                  }}
                >
                  <Link
                    component={RouterLink}
                    to={`/arcades/${arcadeCd}/prizes/plans/daily/booth/${booth.boothName}?from=${date}&to=${getDaysAgo(-7, date)}`}
                    underline="none"
                    sx={{ fontWeight: "bold" }}
                  >
                    {booth.boothName}
                  </Link>
                  <Stack sx={{ p: 1 }}>
                    <IconButton
                      color="primary"
                      disabled={!isPrizeDailyPlanUpdatable(plan.recordedAt)}
                      onClick={() =>
                        navigate(
                          `/arcades/${arcadeCd}/prizes/plans/daily/edit?from=${date}&to=${getDaysAgo(-7, date)}&boothName=${booth.boothName}`,
                        )
                      }
                    >
                      <Edit />
                    </IconButton>
                  </Stack>
                </Stack>
              </ExtTableCell>
              <ExtTableCell>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {plan.isBoothCategoryChanged && (
                    <Chip label="当日更新" color="info" size="small" />
                  )}
                  <Typography variant="body2">{plan?.boothCategory}</Typography>
                </Stack>
              </ExtTableCell>
              <ExtTableCell>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {plan.isPrizePlanChanged && (
                    <Chip label="当日更新" color="info" size="small" />
                  )}
                  <Typography variant="body2">{plan?.prize.prizeCd}</Typography>
                </Stack>
              </ExtTableCell>
              <ExtTableCell>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {plan.isSettingChanged && (
                    <Chip label="当日更新" color="info" size="small" />
                  )}
                  <Typography variant="body2">{plan.setting}</Typography>
                </Stack>
              </ExtTableCell>
              <ExtTableCell>{plan?.prize.prizeName}</ExtTableCell>
            </Fragment>
          )
        }}
        checkIsSameItem={(a, b) => a.plan.id === b.plan.id}
        selectedItems={selectedDailyPlans}
        onChangeSelectedItems={(selectedItems) =>
          setSelectedDailyPlans(selectedItems)
        }
      />
    </Stack>
  )
}

const PrizeDailyFloorMap: React.FC = () => {
  const { arcadeCd } = useParams()
  const date = useRecoilValue(prizeDailyPlansDatePickerState)

  const { prizePlanFloorMapPoints } = usePrizeDailyPlanFloorMapPoints({
    arcadeCd,
    type: "plans",
    date,
  })

  const [selectedPoint, setSelectedPoint] = useState<
    PrizePlanFloorMapPoint | undefined
  >(undefined)

  const onClickPoint = useCallback((point: PrizePlanFloorMapPoint) => {
    setSelectedPoint(point)
  }, [])

  return (
    <>
      {prizePlanFloorMapPoints && (
        <PrizeDailyPlanFloorMapBox
          prizePlanFloorMapPoints={prizePlanFloorMapPoints}
          onClickPoint={onClickPoint}
        />
      )}
      {selectedPoint && (
        <PrizeDailyFloorMapModal
          showModal={selectedPoint !== undefined}
          point={selectedPoint}
          onClose={() => setSelectedPoint(undefined)}
        />
      )}
    </>
  )
}

type PrizeDailyFloorMapModalProps = {
  showModal: boolean
  point: PrizePlanFloorMapPoint
  onClose: () => void
}

export const PrizeDailyFloorMapModal: React.FC<
  PrizeDailyFloorMapModalProps
> = ({ showModal, point, onClose }) => {
  const navigate = useNavigate()
  const { arcadeCd } = useParams()
  const { plans, name, boothNames } = point
  const from = useRecoilValue(prizeDailyPlansDatePickerState)
  const to = getDaysAgo(-7, from)
  const boothName = boothNames.length > 1 ? name.split("_")[0] : name

  return (
    <CustomDialog fullWidth maxWidth="sm" open={showModal} onClose={onClose}>
      <DialogContent>
        <Stack gap={2}>
          {plans.map((plan, i) => (
            <Stack key={plan.id} gap={2}>
              <Stack gap={1}>
                <Typography
                  variant="body2"
                  sx={(theme) => ({ color: theme.palette.gray[50] })}
                >
                  景品名
                </Typography>
                <Typography>{plan.prize.prizeName}</Typography>
              </Stack>
              <Stack gap={1}>
                <Typography
                  variant="body2"
                  sx={(theme) => ({ color: theme.palette.gray[50] })}
                >
                  プライズ機種名（ブース名）
                </Typography>
                <Typography>{plan.boothName}</Typography>
              </Stack>
              <Stack gap={1}>
                <Typography
                  variant="body2"
                  sx={(theme) => ({ color: theme.palette.gray[50] })}
                >
                  設定
                </Typography>
                <Typography>
                  {plan.setting !== "" ? plan.setting : "-"}
                </Typography>
              </Stack>
              {plans.length - 1 !== i && <Divider />}
            </Stack>
          ))}
        </Stack>
      </DialogContent>
      <CustomDialogActions>
        <BackButton onClick={() => onClose()}>戻る</BackButton>
        <Button
          variant="contained"
          fullWidth
          onClick={() =>
            navigate(
              `/arcades/${arcadeCd}/prizes/plans/daily/edit?from=${from}&to=${to}&boothName=${boothName}`,
            )
          }
        >
          編集する
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}
