import { useEffect, useMemo, useRef, useState, useTransition } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { Add, Search } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Typography,
  Stack,
  Button,
  Card,
  TextField,
  Grid,
  Dialog,
  DialogContent,
  FormHelperText,
} from "@mui/material"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import * as Yup from "yup"

import {
  Prize,
  PrizeBooth,
  PrizeBoothCategory,
  PrizeDailyPlan,
} from "src/api/models"
import { getPrizeDailyPlans, postPrizeDailyPlans } from "src/api/prize-plans"
import {
  getPrizeSettingBoothCategories,
  getPrizeSettingFields,
} from "src/api/prize-settings"
import {
  DateRangePicker,
  DateRangePickerDateLabel,
} from "src/components/atoms/DateRangePicker"
import { AlertCaptionCard } from "src/components/molecules/AlertCaptionCard"
import { LoadingBox } from "src/components/molecules/LoadingBox"
import { PrizeNameByPrizeCd } from "src/components/molecules/PrizeNameByPrizeCode"
import { SearchAutoComplete } from "src/components/molecules/SearchAutoComplete"
import { PrizeCodeSearchModal } from "src/components/organisms/PrizeCodeSearchModal"
import {
  PrizeDailyBulkInputPlansTable,
  PrizeDailyBulkInputPlansTableItem,
} from "src/components/organisms/prizes/PrizeDailyBulkInputPlansTable"
import { PrizeDailyPlanEditResult } from "src/components/organisms/prizes/PrizeDailyPlanEditResult"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { getUpdatablePrizeDailyPlanDayjs } from "src/domains/prizes/dailyRepository"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { getToday } from "src/utils"

const validationSchema = Yup.object({
  plans: Yup.array()
    .of(
      Yup.object({
        id: Yup.number().required("必須です"),
        originalId: Yup.number().required("必須です"),
        recordedAt: Yup.string().required("必須です"),
        boothName: Yup.string().required("必須です"),
        boothCategory: Yup.string().defined().strict(true),
        setting: Yup.string().defined().strict(true),
        prizeCd: Yup.string().defined().strict(true),
        prizeName: Yup.string().defined().strict(true),
      }),
    )
    .required("必須です")
    .min(1, "ブースが未選択です"),
  boothCategory: Yup.string<PrizeBoothCategory["name"]>(),
  setting: Yup.string<PrizeDailyPlan["setting"]>(),
  prizeCd: Yup.string().required("必須です"),
})

function omitEmpty<T extends Record<string, unknown>>(obj: T) {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => !!value),
  ) as Partial<T>
}

type PrizeDailyEditSubmitResult = {
  data: FormValues & { prizeName: string }
}

export const PrizeDailyBulkInput: React.FC = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  const [submitResult, setSubmitResult] =
    useState<PrizeDailyEditSubmitResult | null>(null)

  const onSubmitted = (props: PrizeDailyEditSubmitResult) => {
    setSubmitResult(props)
  }
  const onClose = () => {
    setSubmitResult(null)
  }

  if (submitResult) {
    return (
      <MainContentLayout
        title="ブース別投入景品 ブース編集"
        renderContent={() => (
          <PrizeDailyEditComplete
            arcadeCd={arcadeCd}
            data={submitResult.data}
          />
        )}
        backButtonLabel="ブース別投入景品一覧へ戻る"
        onClickBackButton={() => {
          onClose()
          navigate(`/arcades/${arcadeCd}/prizes/plans/daily`)
        }}
      />
    )
  }

  return (
    <MainContentLayout
      title="ブース別投入景品 一括入力"
      renderContent={() => <PrizeDailyInner onSubmitted={onSubmitted} />}
    />
  )
}

type WithOriginalId<T> = T & { originalId: number }
type PrizeDailyPlanWithOriginalId = {
  plan: WithOriginalId<WithOriginalId<PrizeDailyPlan>>
  booth: PrizeBooth
}

type FormValues = {
  plans: (WithOriginalId<
    Pick<
      PrizeDailyPlan,
      "boothCategory" | "boothName" | "setting" | "recordedAt"
    >
  > &
    Pick<Prize, "prizeCd" | "prizeName">)[]
  boothCategory?: PrizeBoothCategory["name"]
  setting?: PrizeDailyPlan["setting"]
  prizeCd: string
}

type PrizeDailyInnerProps = {
  onSubmitted: (result: PrizeDailyEditSubmitResult) => void
}

const PrizeDailyInner: React.FC<PrizeDailyInnerProps> = ({ onSubmitted }) => {
  const { arcadeCd } = useParams()

  const [boothSearchModalOpen, setBoothSearchModalOpen] = useState(false)
  const [prizeCdSearchModalOpen, setPrizeCdSearchModalOpen] = useState(false)

  const [searchParams, setSearchParams] =
    useState<PrizeBoothSearchModalSearchParams>({
      dateRangeLabel: {
        start: getToday(),
        end: getToday(),
      },
    })

  const { resource: prizeSettingBoothCategoriesReturn } = useResource({
    subject: "ブース区分の一覧の取得",
    fetch: arcadeCd
      ? () => getPrizeSettingBoothCategories(arcadeCd)
      : undefined,
    recoilKey: `getPrizeSettingBoothCategories:${arcadeCd}`,
  })
  const prizeSettingBoothCategories =
    prizeSettingBoothCategoriesReturn?.data.boothCategories

  const { resource: prizeSettingFieldsReturn } = useResource({
    subject: "フィールド設定リストの取得",
    fetch: arcadeCd ? () => getPrizeSettingFields() : undefined,
    recoilKey: `getPrizeSettingFields`,
  })
  const prizeSettingFields = prizeSettingFieldsReturn?.data.fields

  const { dateRangeLabel } = searchParams

  const {
    control,
    setValue,
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver<FormValues>(validationSchema),
  })
  const { fields, append, remove } = useFieldArray({ control, name: "plans" })

  const prizeCd = watch("prizeCd")

  const [prizeName, setPrizeName] = useState<string | null>(null)
  const isPrizeNameValid = prizeName != null

  const prizeDailyPlansReturn = useResource({
    subject: "デイリー入替計画の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeDailyPlans(arcadeCd, {
            from: dateRangeLabel.start,
            to: dateRangeLabel.end,
          })
      : undefined,
    recoilKey: `getPrizeDailyPlans:${arcadeCd}:${dateRangeLabel.start}:${dateRangeLabel.end}`,
  }).resource
  const prizeDailyPlans = prizeDailyPlansReturn?.data.plans?.map(
    ({ plan, booth }): PrizeDailyPlanWithOriginalId => ({
      plan: { ...plan, originalId: plan.id },
      booth,
    }),
  )

  const { submitPromises } = useSubmitting()
  const onSubmit = async (data: FormValues) => {
    const result = await submitPromises([
      {
        showSuccessMessage: true,
        promise: async () => {
          arcadeCd &&
            (await postPrizeDailyPlans(arcadeCd, {
              plans: data.plans.map(({ originalId }) => {
                return {
                  id: originalId,
                  prizeCd: data.prizeCd.trim(),
                  ...omitEmpty({
                    boothCategory: data.boothCategory,
                    setting: data.setting,
                  }),
                }
              }),
            }))
        },
        subject: "ブース別投入景品の一括入力",
      },
    ])
    if (!result.success) {
      throw result.error
    }

    // 結果画面で表示する内容をセットするが data には prizeName が含まれていないので追加する
    onSubmitted({ data: { ...data, prizeName: prizeName ?? "" } })
  }

  return (
    <Stack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={4}>
          <Typography variant="body1">
            選択したブースに同一の「ブース区分」「景品CD」「設定」を一括入力します。ブースを選択してください。
          </Typography>

          <Stack direction="row" gap={2} alignItems="center">
            <Typography variant="body1">{fields.length}件選択中</Typography>

            <Button
              variant="contained"
              startIcon={<Add fontSize="inherit" />}
              onClick={() => setBoothSearchModalOpen(true)}
            >
              ブースを追加
            </Button>
          </Stack>

          <Stack>
            <PrizeDailyBulkInputPlansTable
              items={fields}
              onRemove={(_, index) => remove(index)}
            />
          </Stack>

          <Card sx={{ py: 1.5, px: 2 }}>
            <Stack gap={2}>
              <Typography variant="h2">一括入力する項目</Typography>
              <Stack gap={2}>
                <Stack gap={1}>
                  <Typography
                    variant="body2"
                    color="gray.50"
                    component="label"
                    htmlFor="boothCategory"
                  >
                    ブース区分
                  </Typography>
                  <Controller
                    name="boothCategory"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <SearchAutoComplete
                        items={(prizeSettingBoothCategories || []).map(
                          ({ name }) => ({
                            label: name,
                            value: name,
                          }),
                        )}
                        {...field}
                        error={!!fieldState.error}
                      />
                    )}
                  />
                  {errors.boothCategory && (
                    <FormHelperText error>
                      {errors.boothCategory.message}
                    </FormHelperText>
                  )}
                </Stack>

                <Stack gap={1}>
                  <Typography
                    variant="body2"
                    color="gray.50"
                    component="label"
                    htmlFor="prizeCd"
                  >
                    景品CD
                  </Typography>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      textAlign: "center",
                      gap: 2,
                    }}
                  >
                    <Stack sx={{ flex: 1 }}>
                      <TextField
                        {...register("prizeCd")}
                        error={!!errors.prizeCd}
                      />
                      {errors.prizeCd && (
                        <FormHelperText error>
                          {errors.prizeCd.message}
                        </FormHelperText>
                      )}
                    </Stack>
                    <Stack>
                      <Button
                        variant="outlined"
                        startIcon={<Search color="inherit" />}
                        sx={{
                          whiteSpace: "nowrap",
                          minWidth: 135,
                          height: 48,
                        }}
                        onClick={() => setPrizeCdSearchModalOpen(true)}
                      >
                        検索
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>

              <Stack gap={1}>
                <Typography variant="body2" color="gray.50">
                  景品名
                </Typography>
                <PrizeNameByPrizeCd
                  arcadeCd={arcadeCd}
                  prizeCd={prizeCd}
                  onResult={setPrizeName}
                />
              </Stack>

              <Stack gap={1}>
                <Typography
                  variant="body2"
                  color="gray.50"
                  component="label"
                  htmlFor="setting"
                >
                  設定
                </Typography>
                <Controller
                  name="setting"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <SearchAutoComplete
                      items={(prizeSettingFields || []).map(({ name }) => ({
                        label: name,
                        value: name,
                      }))}
                      {...field}
                      error={!!fieldState.error}
                    />
                  )}
                />
                {errors.setting && (
                  <FormHelperText error>
                    {errors.setting.message}
                  </FormHelperText>
                )}
              </Stack>
            </Stack>
          </Card>
          <Stack>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isSubmitting}
              disabled={!isPrizeNameValid}
            >
              入力する
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
      <PrizeBoothSearchModal
        prizeDailyPlans={prizeDailyPlans ?? []}
        selectedPlanIds={fields.map((plan) => plan.originalId)}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        open={boothSearchModalOpen}
        handleClose={() => setBoothSearchModalOpen(false)}
        onSelect={(prizeDailyPlanIds) => {
          append(
            prizeDailyPlans
              ?.filter(({ plan }) =>
                prizeDailyPlanIds.includes(plan.originalId),
              )
              .map(({ plan }) => ({ ...plan, ...plan.prize })) ?? [],
          )
          setBoothSearchModalOpen(false)
        }}
      />
      <PrizeCodeSearchModal
        open={prizeCdSearchModalOpen}
        handleClose={() => setPrizeCdSearchModalOpen(false)}
        onSelect={(prizeCd: string) => {
          setValue("prizeCd", prizeCd)
          setPrizeCdSearchModalOpen(false)
        }}
      />
    </Stack>
  )
}

type PrizeBoothSearchModalSearchParams = {
  dateRangeLabel: DateRangePickerDateLabel
  boothName?: string
  prizeCd?: string
  prizeName?: string
  prizeNameKana?: string
}
type PrizeBoothSearchModalProps = {
  prizeDailyPlans: PrizeDailyPlanWithOriginalId[]
  selectedPlanIds: PrizeDailyPlan["id"][]
  searchParams: PrizeBoothSearchModalSearchParams
  setSearchParams: (searchParams: PrizeBoothSearchModalSearchParams) => void
  open: boolean
  handleClose: () => void
  onSelect: (prizeDailyPlanIds: PrizeDailyPlan["id"][]) => void
}
const PrizeBoothSearchModal: React.FC<PrizeBoothSearchModalProps> = ({
  prizeDailyPlans,
  selectedPlanIds,
  searchParams,
  setSearchParams,
  open,
  handleClose,
  onSelect,
}) => {
  const { register, handleSubmit, control, reset } =
    useForm<PrizeBoothSearchModalSearchParams>()
  const { boothName, prizeCd, prizeName, prizeNameKana } = searchParams
  useEffect(() => reset(searchParams), [reset, searchParams])

  const tableItems = useMemo(() => {
    const filteredPlans =
      prizeDailyPlans
        .filter((prizeDailyPlan) => {
          if (selectedPlanIds.includes(prizeDailyPlan.plan.originalId)) {
            return false
          }

          if (!boothName && !prizeCd && !prizeName && !prizeNameKana) {
            return true
          }
          // 検索条件が指定されている場合、planが存在しない場合はどれにも当てはまらない為falseを返す
          if (!prizeDailyPlan.plan) {
            return false
          }

          if (
            boothName &&
            prizeDailyPlan.plan.boothName.indexOf(boothName) === -1
          ) {
            return false
          }
          if (
            prizeCd &&
            prizeDailyPlan.plan.prize.prizeCd.indexOf(prizeCd) === -1
          ) {
            return false
          }
          if (
            prizeName &&
            prizeDailyPlan.plan.prize.prizeName.indexOf(prizeName) === -1
          ) {
            return false
          }
          // TODO: APIの値ににカナがない
          // if (
          //   prizeNameKana &&
          //   prizeDailyPlan.plan.prize.prizeNameKana.includes(prizeNameKana)
          // ) {
          //   return false
          // }

          return true
        })
        .sort((a, b) =>
          a.plan && b.plan
            ? a.plan.recordedAt > b.plan.recordedAt
              ? -1
              : 1
            : 1,
        ) ?? []

    return filteredPlans.map<PrizeDailyBulkInputPlansTableItem>(({ plan }) => ({
      originalId: plan.originalId,
      boothCategory: plan.boothCategory,
      boothName: plan.boothName,
      setting: plan.setting,
      recordedAt: plan.recordedAt,
      prizeCd: plan.prize.prizeCd,
      prizeName: plan.prize.prizeName,
    }))
  }, [
    boothName,
    prizeCd,
    prizeDailyPlans,
    prizeName,
    prizeNameKana,
    selectedPlanIds,
  ])

  const [selectedItems, setSelectedItems] = useState<
    PrizeDailyBulkInputPlansTableItem[]
  >([])

  const [isLoading, startTransition] = useTransition()

  const dialogRef = useRef<HTMLDivElement>(null)

  return (
    <Dialog open={open} onClose={handleClose} ref={dialogRef} maxWidth="lg">
      {isLoading && <LoadingBox />}
      <DialogContent sx={{ p: 3 }}>
        <Typography variant="h1" pb={2}>
          ブースを追加
        </Typography>

        <Grid
          sx={{
            mb: 3,
            p: 2,
          }}
          container
        >
          <Grid container item spacing={1} pb={2}>
            <Grid item xs={12} sx={{ display: "flex" }}>
              <Controller
                name="dateRangeLabel"
                control={control}
                render={({ field }) => (
                  <DateRangePicker
                    dateRangeLabel={field.value}
                    setDateRangeLabel={field.onChange}
                    minDate={getUpdatablePrizeDailyPlanDayjs()}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2.5} sx={{ display: "flex" }}>
              <Stack sx={{ justifyContent: "center" }}>
                <Typography variant="body1">ブース名</Typography>
              </Stack>
            </Grid>
            <Grid item xs={9.5}>
              <TextField
                fullWidth
                placeholder="検索ワードを入力/部分一致"
                {...register("boothName")}
              />
            </Grid>
            <Grid item xs={2.5} sx={{ display: "flex" }}>
              <Stack sx={{ justifyContent: "center" }}>
                <Typography variant="body1">景品CD</Typography>
              </Stack>
            </Grid>
            <Grid item xs={9.5}>
              <TextField
                fullWidth
                placeholder="検索ワードを入力/部分一致"
                {...register("prizeCd")}
              />
            </Grid>
            <Grid item xs={2.5} sx={{ display: "flex" }}>
              <Stack sx={{ justifyContent: "center" }}>
                <Typography variant="body1">景品名</Typography>
              </Stack>
            </Grid>
            <Grid item xs={9.5}>
              <TextField
                fullWidth
                placeholder="検索ワードを入力/部分一致"
                {...register("prizeName")}
              />
            </Grid>
            {/* <Grid item xs={2.5} sx={{ display: "flex" }}>
              <Stack sx={{ justifyContent: "center" }}>
                <Typography variant="body1">景品名カナ</Typography>
              </Stack>
            </Grid>
            <Grid item xs={9.5}>
              <TextField
                fullWidth
                placeholder="検索ワードを入力/部分一致"
                {...register("prizeNameKana")}
              />
            </Grid> */}
          </Grid>

          <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit(({ dateRangeLabel, ...rest }) => {
              startTransition(() =>
                setSearchParams({
                  dateRangeLabel,
                  ...omitEmpty(rest),
                }),
              )
            })}
          >
            検索する
          </Button>
        </Grid>

        <Stack
          sx={{
            maxHeight: "60dvh",
            pb: 3,
          }}
        >
          <PrizeDailyBulkInputPlansTable
            selectable
            items={tableItems}
            selectedItems={selectedItems}
            onChangeSelectedItems={setSelectedItems}
          />
        </Stack>

        <Stack>
          <Button
            variant="contained"
            onClick={() =>
              onSelect(selectedItems.map((item) => item.originalId))
            }
          >
            ブースを追加する
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

type PrizeDailyEditCompleteProps = {
  arcadeCd: string | undefined
  data: FormValues & { prizeName: string }
}

const PrizeDailyEditComplete: React.FC<PrizeDailyEditCompleteProps> = ({
  arcadeCd,
  data: { plans, prizeCd, prizeName, boothCategory, setting },
}) => {
  return (
    <Stack gap={2}>
      <Stack>
        <AlertCaptionCard
          label="下記の通り保存しました"
          rightLinkText="一覧へ戻る"
          rightLinkTo={`/arcades/${arcadeCd}/prizes/plans/daily`}
        />
      </Stack>
      <Stack gap={3}>
        <Stack gap={2}>
          <Typography variant="h2">変更前項目</Typography>
          <Stack>
            <PrizeDailyBulkInputPlansTable items={plans} />
          </Stack>
        </Stack>
        <Stack gap={2}>
          <Typography variant="h2">変更内容</Typography>
          <Card sx={{ px: 3, py: 2 }}>
            <Stack gap={2}>
              <PrizeDailyPlanEditResult
                prizeCd={prizeCd}
                prizeName={prizeName}
                // 一括入力時はブース区分と設定が空の
                {...omitEmpty({ boothCategory, setting })}
              />
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </Stack>
  )
}
