import { useState } from "react"

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import LogoutIcon from "@mui/icons-material/Logout"
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined"
import MenuIcon from "@mui/icons-material/Menu"
import {
  AppBar,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { useNavigate } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"

import { AccountMenu } from "src/components/molecules/AccountMenu"
import { useAuth } from "src/hooks/useAuth"
import { useBreakpoints } from "src/hooks/useBreakpoints"
import { meState, snackbarErrorMessageState } from "src/recoil"

const NavBarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
}))

interface NavBarProps {
  onSidebarOpen: () => void
}

export const NavBar: React.FC<NavBarProps> = ({
  onSidebarOpen,
}: NavBarProps) => {
  const navigate = useNavigate()
  const { isPc } = useBreakpoints()
  const { logout } = useAuth()
  const me = useRecoilValue(meState)
  const user = me?.user
  const [isLoggingOut, setIsLoggingOut] = useState(false)
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)

  const onLogout = async () => {
    setIsLoggingOut(true)
    try {
      await logout()
      navigate("/login")
    } catch {
      setErrorMessage("ログアウトに失敗しました")
    } finally {
      setIsLoggingOut(false)
    }
  }

  return (
    <NavBarRoot
      sx={{
        left: {
          lg: 280,
        },
        width: {
          lg: "calc(100% - 280px)",
        },
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          minHeight: 64,
          left: 0,
          px: 2,
        }}
      >
        <IconButton
          onClick={onSidebarOpen}
          sx={(theme) => ({
            display: {
              xs: "inline-flex",
              lg: "none",
            },
            color: theme.palette.gray[40],
          })}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <IconButton
          onClick={() => navigate(-1)}
          sx={(theme) => ({
            display: {
              xs: "inline-flex",
              lg: "none",
            },
            color: theme.palette.gray[40],
          })}
        >
          <ArrowBackIosNewIcon fontSize="small" color="primary" />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />

        <AccountMenu
          userName={user?.name}
          avatarOnly={!isPc}
          disabled={isLoggingOut}
        >
          <MenuItem onClick={() => navigate("/my-page")}>
            <ListItemIcon>
              <ManageAccountsOutlinedIcon />
            </ListItemIcon>
            <ListItemText>マイページ</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => onLogout()}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>ログアウト</ListItemText>
          </MenuItem>
        </AccountMenu>
      </Toolbar>
    </NavBarRoot>
  )
}
