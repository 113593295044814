/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */

/**
 * 公開 or 非公開
 */
export type PutNoticeRequestStatusEnum =
  (typeof PutNoticeRequestStatusEnum)[keyof typeof PutNoticeRequestStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PutNoticeRequestStatusEnum = {
  Public: "public",
  Private: "private",
} as const
