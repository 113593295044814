import { useState, useMemo, useCallback } from "react"

import { LoadingButton } from "@mui/lab"
import { Button, Card, Stack } from "@mui/material"
import { Link as RouterLink, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import {
  PrizeInventoryHistory,
  PrizeShelfStock,
  PrizeOnBoothStock,
  PrizeBoothStock,
  PrizePlacementStatus,
  PrizeBoothShelf,
  PrizeOnBoothShelf,
  PrizeShelf,
  PrizePlacementStatusExecutionStatusEnum,
} from "src/api/models"
import { putPrizePlacementStatuses } from "src/api/prize-placement-statuses"
import emptyIcon from "src/assets/icon_empty.png"
import finishedIcon from "src/assets/icon_finished.png"
import { DeleteModal } from "src/components/organisms/DeleteModal"
import {
  filterInventoryPrizeExecuteDetailsMenuItems,
  inventoryExecuteDetailsSearchParamsState,
} from "src/components/organisms/prizes/InventoryPrizeExecuteDetailsFilter"
import {
  InventoryPrizeExecuteDetailsMenu,
  InventoryPrizeExecuteDetailsMenuItem,
} from "src/components/organisms/prizes/InventoryPrizeExecuteDetailsMenu"
import { InventoryPrizeExecuteModal } from "src/components/organisms/prizes/InventoryPrizeExecuteModal"
import { SetEmptyStatusModal } from "src/components/organisms/SetEmptyStatusModal"
import {
  getPlacementTypeLabel,
  PlacementType,
  convertPrizePlacementTypeToURLPath,
} from "src/domains/prizes/placementStatusRepository"
import { useDeleteInventoryStocks } from "src/hooks/useDeleteInventoryStocks"
import { useLoading } from "src/hooks/useLoading"
import { getToday } from "src/utils"

interface InventoryPrizeExecuteDetailsProps {
  placementType: PlacementType
  storageId?: Storage["id"]
  placementId?:
    | PrizeShelf["id"]
    | PrizeBoothShelf["id"]
    | PrizeOnBoothShelf["id"]
  placementStatus?: PrizePlacementStatus
  placementStocks?: (PrizeShelfStock | PrizeOnBoothStock | PrizeBoothStock)[]
  histories?: PrizeInventoryHistory[]
  menuItems?: InventoryPrizeExecuteDetailsMenuItem[]
  onFinish?: () => void
}

export const InventoryPrizeExecuteDetails: React.FC<
  InventoryPrizeExecuteDetailsProps
> = ({
  placementType,
  storageId,
  placementId,
  placementStatus,
  placementStocks,
  menuItems = [],
  histories,
  onFinish = () => undefined,
}: InventoryPrizeExecuteDetailsProps) => {
  const { arcadeCd } = useParams()

  const [showExecuteModal, setShowExecuteModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showEmptyStatusModal, setShowEmptyStatusModal] = useState(false)

  const searchParams = useRecoilValue(inventoryExecuteDetailsSearchParamsState)
  const filteredMenuItems = useMemo(
    () => filterInventoryPrizeExecuteDetailsMenuItems(menuItems, searchParams),
    [menuItems, searchParams],
  )
  const [selectedMenuItem, setSelectedMenuItem] =
    useState<InventoryPrizeExecuteDetailsMenuItem>()

  const openExecuteModal = useCallback(
    (menuItem: InventoryPrizeExecuteDetailsMenuItem) => {
      setSelectedMenuItem(menuItem)
      setShowExecuteModal(true)
    },
    [],
  )
  const openDeleteModal = useCallback(
    (menuItem: InventoryPrizeExecuteDetailsMenuItem) => {
      setSelectedMenuItem(menuItem)
      setShowDeleteModal(true)
    },
    [],
  )

  const selectedPlacementStock = useMemo(
    () =>
      placementStocks?.find(
        (placementStock) =>
          placementStock.prizeCd === selectedMenuItem?.prize.prizeCd,
      ),
    [selectedMenuItem, placementStocks],
  )

  const isEmptyStatusAlreadySet =
    placementStatus?.executionStatus ===
    PrizePlacementStatusExecutionStatusEnum.Empty
  const isEmptySettable =
    placementStocks?.length === 0 && !isEmptyStatusAlreadySet
  const showAllExecuted =
    menuItems.length > 0 &&
    filteredMenuItems.length === 0 &&
    !menuItems.find((item) => !item.executed)

  const { onDeleteInventoryStocks } = useDeleteInventoryStocks()
  const onDeleteSubmit = async ({
    placementStockId,
  }: InventoryPrizeExecuteDetailsMenuItem) => {
    if (arcadeCd && placementStockId) {
      await onDeleteInventoryStocks({
        arcadeCd,
        placementStockId,
        placement: placementType,
      })
      onFinish()
      setShowDeleteModal(false)
    }
  }

  const prizeAddUrl = useMemo(() => {
    const url = new URL(
      `/arcades/${arcadeCd}/inventory/prizes/execute/${convertPrizePlacementTypeToURLPath(
        placementType,
      )}/add`,
      window.location.origin,
    )
    if (!placementId) return url.toString()

    if (storageId) {
      url.searchParams.append("defaultStorageId", storageId.toString())
    }
    url.searchParams.append("defaultPlacementId", placementId.toString())

    return url.toString()
  }, [arcadeCd, placementType, placementId, storageId])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitPromises = useLoading(setIsSubmitting).loadPromises
  const setEmptyStatus = () => {
    arcadeCd &&
      placementId &&
      submitPromises([
        {
          subject: "「空」の登録",
          showSuccessMessage: true,
          promise: async () => {
            await putPrizePlacementStatuses(arcadeCd, placementType, {
              placementId,
              date: getToday(),
              executionStatus: PrizePlacementStatusExecutionStatusEnum.Empty,
            })
            onFinish()
            setShowEmptyStatusModal(false)
          },
        },
      ])
  }

  return (
    <>
      {selectedMenuItem && selectedPlacementStock && histories && (
        <InventoryPrizeExecuteModal
          placementType={placementType}
          placementStock={selectedPlacementStock}
          histories={histories}
          showModal={showExecuteModal}
          onClose={() => setShowExecuteModal(false)}
          onFinish={() => onFinish()}
          prize={selectedMenuItem.prize}
        />
      )}

      <DeleteModal
        showModal={showDeleteModal}
        onSubmit={() => selectedMenuItem && onDeleteSubmit(selectedMenuItem)}
        onClose={() => setShowDeleteModal(false)}
        isSubmitting={false}
        message={`本当に${getPlacementTypeLabel(
          placementType,
        )}から削除しますか？`}
      />

      <SetEmptyStatusModal
        showModal={showEmptyStatusModal}
        onSubmit={() => setEmptyStatus()}
        onClose={() => setShowEmptyStatusModal(false)}
        isSubmitting={isSubmitting}
      />

      <Stack gap={2}>
        <Stack>
          {isEmptyStatusAlreadySet && (
            <Card
              sx={{
                p: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {getPlacementTypeLabel(placementType)}景品
              <img src={emptyIcon} alt="空" style={{ width: 32, height: 32 }} />
              で登録済です
            </Card>
          )}

          {showAllExecuted && (
            <Card
              sx={{
                p: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              すべての景品を実査
              <img
                src={finishedIcon}
                alt="済"
                style={{ width: 32, height: 32 }}
              />
              です
            </Card>
          )}

          <InventoryPrizeExecuteDetailsMenu
            openExecuteModal={openExecuteModal}
            openDeleteModal={openDeleteModal}
            menuItems={filteredMenuItems}
          />
        </Stack>

        <Button
          variant="outlined"
          sx={{ background: "white" }}
          fullWidth
          component={RouterLink}
          to={prizeAddUrl}
        >
          景品を検索して{getPlacementTypeLabel(placementType)}に登録
        </Button>

        {placementStatus && (
          <LoadingButton
            variant="outlined"
            sx={{ background: "white" }}
            fullWidth
            disabled={!isEmptySettable}
            loading={isSubmitting}
            onClick={() => setShowEmptyStatusModal(true)}
          >
            {getPlacementTypeLabel(placementType)}景品「空」で登録
          </LoadingButton>
        )}
      </Stack>
    </>
  )
}
