import { ZoomIn } from "@mui/icons-material"
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material"

export type PrintMode = "all" | "select"

type Props = {
  printMode: PrintMode
  printSettingNames?: string[]
  selectedPrintSettingNames?: string[]
  onChangePrintMode: (printMode: PrintMode) => void
  onSelectPrintSetting: (name: string, checked: boolean) => void
  onPreview?: (name: string) => void
}

export const FloorMapPrintSettingList: React.FC<Props> = ({
  printMode,
  printSettingNames = [],
  selectedPrintSettingNames = [],
  onChangePrintMode,
  onSelectPrintSetting,
  onPreview,
}) => {
  return (
    <Paper sx={{ p: 2 }}>
      <Stack gap={2}>
        <Typography>
          印刷する範囲の名称を選択してください。複数選択した場合は、選択した枚数分印刷されます。
        </Typography>
        <Stack direction="row" alignItems="center" gap={2}>
          <ButtonGroup>
            <Button
              disableRipple
              variant={printMode === "all" ? "contained" : "outlined"}
              onClick={() => onChangePrintMode("all")}
            >
              すべて
            </Button>
            <Button
              disableRipple
              variant={printMode === "select" ? "contained" : "outlined"}
              onClick={() => onChangePrintMode("select")}
            >
              選択
            </Button>
          </ButtonGroup>
          {printMode === "all"
            ? printSettingNames.length
            : selectedPrintSettingNames.length}
          件選択中
        </Stack>
        <FormGroup sx={{ gap: 1 }}>
          {printSettingNames.map((name) => {
            return (
              <FormControlLabel
                key={name}
                // TODO: [GN-3103] 保存済みのフロアマップ印刷範囲のプレビュー
                label={
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Typography variant="body1" color="textPrimary">
                      {name}
                    </Typography>
                    {onPreview && (
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => onPreview(name)}
                      >
                        <ZoomIn fontSize="small" />
                      </IconButton>
                    )}
                  </Stack>
                }
                disabled={printMode === "all"}
                checked={
                  printMode === "all" ||
                  selectedPrintSettingNames.includes(name)
                }
                onChange={(_e, checked) => onSelectPrintSetting(name, checked)}
                control={
                  <Checkbox
                    disableRipple={false}
                    sx={{
                      height: 40,
                      width: 40,
                    }}
                  />
                }
                sx={{ ml: 0, gap: 0.5 }}
              />
            )
          })}
        </FormGroup>
      </Stack>
    </Paper>
  )
}
