import React from "react"

import { Typography, Card, Stack, Link } from "@mui/material"

import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { getJpDateLabel } from "src/utils"

const noticeList = [
  {
    id: 1,
    date: "2024-01-04T00:00:00",
    title: "新機能リリースのお知らせ",
    status: "public",
    pdfLink: "/path/to/pdf",
  },
  {
    id: 2,
    date: "2024-01-05T00:00:00",
    title: "新機能リリースのお知らせ",
    status: "public",
    pdfLink: "/path/to/pdf",
  },
  {
    id: 3,
    date: "2024-01-06T00:00:00",
    title: "新機能リリースのお知らせ",
    status: "public",
    pdfLink: "/path/to/pdf",
  },
]

export const UserNoticeList: React.FC = () => {
  return (
    <MainContentLayout
      title="お知らせ一覧"
      renderContent={() => <UserNoticeListInner />}
    />
  )
}

const UserNoticeListInner: React.FC = () => {
  return (
    <Stack gap={2}>
      <Card>
        {noticeList.map((notice) => (
          <Stack
            key={notice.id}
            sx={{ p: 2 }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <Typography variant="h3">
                {getJpDateLabel(notice.date)}
              </Typography>

              <Link component={"a"} href={notice.pdfLink} underline="none">
                <Typography variant="body1">{notice.title}</Typography>
              </Link>
            </Stack>
          </Stack>
        ))}
      </Card>
    </Stack>
  )
}
