import { Stack, Typography } from "@mui/material"

export const PrizeDailyPlanEditResult: React.FC<{
  prizeCd: string
  prizeName: string
  boothCategory?: string
  setting?: string
}> = ({ prizeCd, prizeName, boothCategory, setting }) => {
  // ブース区分と設定は省略される可能性がある
  return (
    <Stack gap={2}>
      {boothCategory != null && (
        <Stack gap={1}>
          <Typography variant="body2" color="gray.50">
            ブース区分
          </Typography>
          <Typography component="p" variant="h3">
            {boothCategory || "-"}
          </Typography>
        </Stack>
      )}

      <Stack gap={1}>
        <Typography variant="body2" color="gray.50">
          景品CD
        </Typography>
        <Typography component="p" variant="h3">
          {prizeCd || "-"}
        </Typography>
      </Stack>
      <Stack gap={1}>
        <Typography variant="body2" color="gray.50">
          景品名
        </Typography>
        <Typography component="p" variant="h3">
          {prizeName || "-"}
        </Typography>
      </Stack>
      {setting != null && (
        <Stack gap={1}>
          <Typography variant="body2" color="gray.50">
            設定
          </Typography>
          <Typography component="p" variant="h3">
            {setting || "-"}
          </Typography>
        </Stack>
      )}
    </Stack>
  )
}
