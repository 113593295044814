import { useRecoilValue } from "recoil"

import { Arcade, UserRole } from "src/api/models"
import { currentArcadeState, meState } from "src/recoil"

export const useUserRole = () => {
  const me = useRecoilValue(meState)
  const userRole = me?.userRole || UserRole.Unknown
  const currentArcade = useRecoilValue(currentArcadeState)

  const isAdmin = userRole === UserRole.Admin
  const isDepartmentLeader = userRole === UserRole.DepartmentLeader
  const isAreaLeader = userRole === UserRole.AreaLeader
  const isPrizeProducer = userRole === UserRole.PrizeProducer
  const isStoreLeader = userRole === UserRole.StoreLeader
  const isFloorManager = userRole === UserRole.FloorManager
  const isChief = userRole === UserRole.Chief
  const isStaff = userRole === UserRole.Staff

  const belongTo = (arcadeCd: Arcade["arcadeCd"]) =>
    !!me?.userArcades?.some((myArcade) => myArcade.arcadeCd === arcadeCd)
  const belongToOrAdmin = (arcadeCd?: Arcade["arcadeCd"]) =>
    isAdmin || (arcadeCd && belongTo(arcadeCd))

  const isAboveChief =
    isAdmin ||
    isDepartmentLeader ||
    isAreaLeader ||
    isPrizeProducer ||
    isStoreLeader ||
    isFloorManager ||
    isChief

  const isEditableUser = isAdmin

  const isEditableUserInArcadeByArcadeCd = (arcadeCd: Arcade["arcadeCd"]) =>
    isAdmin ||
    (isAreaLeader && belongTo(arcadeCd)) ||
    (isPrizeProducer && belongTo(arcadeCd)) ||
    (isStoreLeader && belongTo(arcadeCd))

  const isEditableUserRoleInArcadeByArcadeCd = (arcadeCd: Arcade["arcadeCd"]) =>
    isAdmin ||
    (isDepartmentLeader && belongTo(arcadeCd)) ||
    (isAreaLeader && belongTo(arcadeCd)) ||
    (isPrizeProducer && belongTo(arcadeCd)) ||
    (isStoreLeader && belongTo(arcadeCd))

  const isEditableArcadeStaff =
    isAdmin ||
    isAreaLeader ||
    isPrizeProducer ||
    isStoreLeader ||
    isFloorManager ||
    isChief

  // NOTE: not used
  const isEditableArcadeStaffByArcadeCd = (arcadeCd: Arcade["arcadeCd"]) =>
    isAdmin ||
    (isAreaLeader && belongTo(arcadeCd)) ||
    (isPrizeProducer && belongTo(arcadeCd)) ||
    (isStoreLeader && belongTo(arcadeCd)) ||
    (isFloorManager && belongTo(arcadeCd)) ||
    (isChief && belongTo(arcadeCd))

  const isViewableArcadeSales = (arcadeCd?: Arcade["arcadeCd"]) =>
    isAboveChief || !!(isStaff && arcadeCd && belongTo(arcadeCd))
  const isViewableCurrentArcadeSales = isViewableArcadeSales(
    currentArcade?.arcadeCd,
  )

  const isEditableArcadeInventorySkip = isAdmin || isAreaLeader

  const isViewableLastExecutionPeriods =
    isAdmin ||
    isDepartmentLeader ||
    isAreaLeader ||
    isPrizeProducer ||
    isStoreLeader

  const isViewableManagementMenu = isAboveChief
  const isViewableArcadeSearch = isAboveChief
  const isEditablePlacementAvailability = isAboveChief
  const isEditableAmMachine = isAboveChief
  const isEditableMachine = isAboveChief
  const isEditableMeterReadSetting = isAboveChief
  const isAvailablePlacementStatusHistoriesCsv = isAboveChief
  const isAvailableInventoryOutput = isAboveChief
  const isAvailablePrizePayoutCsvDownload = isAboveChief
  const isAvailableInventoryExecuteHistoriesCsv = (
    arcadeCd?: Arcade["arcadeCd"],
  ) => isAboveChief && belongToOrAdmin(arcadeCd)

  const isAvailableInventoryLock =
    isAdmin ||
    isDepartmentLeader ||
    isAreaLeader ||
    isPrizeProducer ||
    isStoreLeader ||
    isFloorManager

  const isAvailableInventoryExecutionPeriodEnd =
    isAdmin || isAreaLeader || isStoreLeader

  const isAvailableApplyInventoryDifference =
    isAdmin || isAreaLeader || isStoreLeader || isFloorManager || isChief

  const isAvailableApproveInventoryDifference = isAdmin || isAreaLeader

  /**
   * POSITIVEから自動連携されるユーザーかをチェックする
   * GiGO NAVI上で作成・削除・ユーザー名の編集はできない
   */
  const checkIsAutoSetupUser = (userRole: UserRole) =>
    userRole === UserRole.FloorManager ||
    userRole === UserRole.Chief ||
    userRole === UserRole.Staff

  return {
    meUserRole: userRole,
    isAdmin,
    isDepartmentLeader,
    isAreaLeader,
    isPrizeProducer,
    isStoreLeader,
    isFloorManager,
    isChief,
    isStaff,
    checkIsAutoSetupUser,
    isEditableUser,
    isEditableUserInArcadeByArcadeCd,
    isEditableUserRoleInArcadeByArcadeCd,
    isEditableArcadeStaff,
    isEditableArcadeStaffByArcadeCd,
    isViewableManagementMenu,
    isViewableArcadeSales,
    isViewableCurrentArcadeSales,
    isViewableLastExecutionPeriods,
    isViewableArcadeSearch,
    isEditablePlacementAvailability,
    isAvailablePlacementStatusHistoriesCsv,
    isAvailableInventoryOutput,
    isAvailableInventoryLock,
    isAvailableInventoryExecutionPeriodEnd,
    isAvailableInventoryExecuteHistoriesCsv,
    isEditableMeterReadSetting,
    isEditableMachine,
    isEditableAmMachine,
    isEditableArcadeInventorySkip,
    isAvailableApplyInventoryDifference,
    isAvailableApproveInventoryDifference,
    isAvailablePrizePayoutCsvDownload,
  }
}
